import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Overview from './screens/Overview'
import ProofingPage from './screens/ProofingPage'

const ProofingRoutes = () => (
  <Routes>
    <Route path="/" element={<Overview />} />
    <Route path="/:id" element={<ProofingPage />} />
  </Routes>
)

export default ProofingRoutes
