import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box,
  Button,
  Checkbox,
  FormField,
  ModalContext,
  SidebarModal,
  Text,
  UserProfileImage,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faUser } from '@fortawesome/pro-light-svg-icons'

import { useCampaignFormV2 } from '@hooks/useInviteesV2'

const defaultState = {
  additionalInvitees: '',
  creating: false,
  lockedInvitees: [],
  selectableInvitees: [],
}

const CampaignInviteesModal = (props) => {
  const {
    callbacks, modalKey, showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const [state, setState] = useSetState(defaultState)
  const {
    additionalInvitees, creating, lockedInvitees, selectableInvitees,
  } = state

  const campaignPayload = digObject(modalData, modalKey, {})
  const {
    callbacks: {
      assignAdditionalInvitees: additionalCreateFn,
      assignInvitees: inviteesCreateFn,
      updateCampaign: updateFn,
    },
    campaign,
  } = campaignPayload

  const campaignFormPayload = useCampaignFormV2(campaign)

  const {
    entityState,
    entityState: {
      invitees,
      property,
    },
  } = campaignFormPayload

  useEffect(() => {
    setState({
      additionalInvitees: property.additionalInvitees,
      lockedInvitees: invitees.filter(invitee => invitee.locked),
      selectableInvitees: invitees.filter(invitee => !invitee.locked),
    })
  }, [entityState])

  const updateInvitee = (invitee) => {
    const tempInvitees = [...selectableInvitees]
    const tempInvitee = {
      ...tempInvitees[tempInvitees.indexOf(invitee)],
      selected: !invitee.selected,
    }
    tempInvitees[tempInvitees.indexOf(invitee)] = tempInvitee

    setState({ selectableInvitees: [...tempInvitees] })
  }

  const saveInvitees = () => {
    const {
      callbacks: { assignAdditionalInvitees, assignInvitees, updateCampaign },
    } = props

    const campaignInvitee = {
      additionalInvitees,
      campaignId: campaign.id,
      invitees: selectableInvitees,
    }

    setState({ creating: true })

    assignAdditionalInvitees(campaignInvitee, additionalCreateFn)
    assignInvitees(campaignInvitee, inviteesCreateFn)

    entityState.invitees = [...lockedInvitees, ...selectableInvitees]
    entityState.property.additionalInvitees = additionalInvitees

    updateCampaign(entityState, updateFn)
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Campaign" titleSecondLine="Invitees" />
      <SidebarModal.Content>
        <Box flexDirection="column" lineHeight="1.3">
          {lockedInvitees && (
            <Box flexDirection="column">
              <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="large">
                Sales Agent
              </Text>
              {lockedInvitees.map(invitee => (
                <Box
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                  marginBottom="large"
                >
                  <Box flexDirection="column" marginRight="medium" width="auto">
                    <UserProfileImage imageUrl="">
                      <FontAwesomeIcon icon={faUser} />
                    </UserProfileImage>
                  </Box>
                  <Box flexDirection="column">
                    <Text fontSize="small" marginBottom="small">
                      {`${invitee.user.firstName} ${invitee.user.lastName}`}
                    </Text>
                    <Text color="bodyFontLightColor" fontSize="xsmall">
                      {invitee.user.email}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {selectableInvitees && (
            <Box flexDirection="column">
              <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="large">
                Booking Contacts
              </Text>
              {selectableInvitees.map(invitee => (
                <Box
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                  marginBottom="large"
                >
                  <Box flexDirection="column" justifyContent="flex-start" width="auto">
                    <Checkbox checked={invitee.selected} onClick={() => updateInvitee(invitee)} />
                  </Box>
                  <Box flexDirection="column" marginRight="medium" width="auto">
                    <UserProfileImage imageUrl="">
                      <FontAwesomeIcon icon={faUser} />
                    </UserProfileImage>
                  </Box>
                  <Box flexDirection="column">
                    <Text fontSize="small" marginBottom="small">
                      {`${invitee.user.firstName} ${invitee.user.lastName}`}
                    </Text>
                    <Text color="bodyFontLightColor" fontSize="xsmall" style={{ wordBreak: 'break-word' }}>
                      {invitee.user.email}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <FormField label="Add Recipients">
            <input
              onKeyDown={(e) => { if (e.key === ' ') e.preventDefault() }}
              onChange={e => setState({ additionalInvitees: e.target.value })}
              placeholder="e.g. user1@example.com;user2@example.com"
              type="text"
              value={additionalInvitees}
            />
          </FormField>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            Please make sure multiple email addresses are separated by semicolon
          </Text>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryEdit"
          icon={<FontAwesomeIcon icon={faCheck} />}
          loading={creating}
          onClick={saveInvitees}
          size="medium"
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CampaignInviteesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CampaignInviteesModal.defaultProps = {
  modalKey: 'CampaignInviteesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CampaignInviteesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
