/* eslint-disable consistent-return */
import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPhone, faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Text, DashboardModule,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useInviteesV2 from '@hooks/useInviteesV2'

const CampaignDetails = () => {
  const { campaign } = useContext(PageContext)
  const { campaignAgents, property } = campaign

  const { callbacks: { launchCampaignInviteesModal } } = useInviteesV2({ id: campaign.id })

  return (
    <DashboardModule title="Campaign Details">
      <Box flexDirection="column" lineHeight="1.3" padding="large">
        <Box flexDirection="row" marginBottom="medium">
          <Box
            alignItems="center"
            color="bodyFontLightColor"
            justifyContent="center"
            marginRight="large"
            width="auto"
          >
            <FontAwesomeIcon icon={faHome} />
          </Box>
          <Box flexDirection="column" marginBottom="medium">
            <Text fontSize="small" paddingBottom="small">
              {campaign.name}
            </Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              {property?.state}
            </Text>
          </Box>
        </Box>
        <Box flexDirection="row">
          <Box
            alignItems="center"
            color="bodyFontLightColor"
            justifyContent="center"
            marginRight="large"
            width="auto"
          >
            <FontAwesomeIcon icon={faPhone} />
          </Box>
          {campaignAgents?.map(campaignAgent => (
            <Box flexDirection="column">
              <Text fontSize="small" marginBottom="small">
                {`${campaignAgent.user.firstName} ${campaignAgent.user.lastName}`}
              </Text>
              {campaignAgent.mobile
              && (
                <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small">
                  {campaignAgent.mobile}
                </Text>
              )}
              <Text color="bodyFontLightColor" fontSize="xsmall" style={{ wordBreak: 'break-word' }}>
                {campaignAgent.user.email}
              </Text>
            </Box>
          ))}
          <Box
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            padding="small"
            width="auto"
          >
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={launchCampaignInviteesModal}
              size="medium"
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardModule>
  )
}

export default CampaignDetails
