import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useCurrentUserV2 = () => {
  useReduxAction('currentUserV2', 'loadCurrentUserV2', {}, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.currentUserV2)
  const {
    loaded,
    loading,
    result: currentUserV2,
  } = entityReducer

  return {
    currentUserV2,
    loaded,
    loading,
  }
}

export default useCurrentUserV2
