import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'
import PageContext from '@contexts/pageContext'
import {
  DashboardModule, ListItem, Box, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-light-svg-icons'

const deleteListItem = (listItem, deleteFn, type) => {
  swal.fire({
    title: `Remove ${type}`,
    html: `Are you sure you want to remove ${listItem.file.originalName}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#e2001a',
    showClass: 'slide-from-top',
  }).then(({ value }) => {
    if (value){
      deleteFn(listItem).then(({ success, errors }) => {
        if (!success) toast.warning(errors[0])
      })
    }
  })
}

const RecentlyUploadedList = (props) => {
  const { fileType, list, callbacks } = props
  const { removeRecentlyDeleted, deleteFn } = callbacks
  const { timeZone } = useContext(PageContext)

  function removeListItem(listItem){
    deleteListItem(listItem, deleteFn, fileType)
    removeRecentlyDeleted(listItem.id)
  }

  return (
    <DashboardModule title="Recently Uploaded Items">
      <Box flexDirection="column">
        {list.map(listItem => (
          <ListItem
            boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
            flexDirection="row"
            disableHover
            key={listItem.id}
          >
            <Box flexDirection="column" paddingRight="large">
              <Text marginBottom="small" fontSize="small">
                {listItem.file.originalName}
              </Text>
              <Text color="bodyFontLightColor" marginBottom="small" fontSize="xsmall">
                {DateTime.fromISO(listItem.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
              </Text>
              <Text color="bodyFontLightColor" marginBottom="small" fontSize="xsmall">
                {`By: ${listItem.file.createdBy.firstName} ${listItem.file.createdBy.lastName}}`}
              </Text>
            </Box>
            <FontAwesomeIcon icon={faTrash} onClick={() => removeListItem(listItem)} style={{ cursor: 'pointer' }} />
          </ListItem>
      ))}
      </Box>
    </DashboardModule>
    )
}

RecentlyUploadedList.propTypes = {
  list: PropTypes.array,
  callbacks: PropTypes.object,
  fileType: PropTypes.string.isRequired,
}

export default RecentlyUploadedList
