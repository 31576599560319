/* eslint-disable max-len */
import { useContext } from 'react'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import * as assetActions from '@redux/modules/asset'

const deleteAsset = (resourceParam, dispatch) => {
  const { deleteAsset: deleteFn } = assetActions

  return dispatch(deleteFn(resourceParam))
}

const loadAsset = (resourceParam, options, dispatch) => {
  const { loadAsset: loadFn } = assetActions
  return dispatch(loadFn(resourceParam, options))
}

const launchAssetUploadModal = (dispatch, showAssetUploadModal) => new Promise((resolve, reject) => {
  if (showAssetUploadModal){
    const payload = {
      callbacks: {
      },
    }

    showAssetUploadModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showAssetUploadModal not defined in PageContext callbacks'))
})

const useUploadAsset = () => {
  const { callbacks } = useContext(PageContext)
  const { showAssetUploadModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      launchAssetUploadModal: () => launchAssetUploadModal(dispatch, showAssetUploadModal),
      deleteAsset: assetParam => deleteAsset(assetParam, dispatch),
      loadAsset: (assetParam, options) => loadAsset(assetParam, options, dispatch),
    },
  }
}

export default useUploadAsset
