import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/shareLink/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/shareLink/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/shareLink/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/shareLink/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/shareLink/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/shareLink/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function validateShareLinkToken(token, version, options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/${version}/shares/request?id=${token}`, options, { skipAuthentication: true })
    .then(({ data }) => {
      const normalizedJson = normalize(data, Schemas.SHARE_LINK)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(fetchFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export function createShareLinkWorkflowChanges(id, workflow, options = {}){
  const config = {
    method: 'POST',
    data: workflow,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/v1/shares/workflow/${id}`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedIds: action.loadedIds || state.loadedIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
