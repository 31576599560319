/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getQueryParamsFromHash, parsePermittedQueryParams,
} from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, Columns, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import { groupBy } from '@functions/groupBy'
import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJob from '@hooks/useServiceJob'
import useServiceJobUsers from '@hooks/useServiceJobUsers'

import AssetMarker from './components/AssetMarker'
import EditorTab from './components/EditorTab'
import CopyTemplate from './components/CopyTemplate'
import ToolBar from './components/ToolBar'
import QCTab from './components/QCTab'

const copyContent = [
  {
    id: 1,
    name: 'McGrath Website 2020',
    templates: [{
      id: 1,
      label: 'Website Heading',
      body: 'sample',
      type: 'input',
      suggested: 60,
      comments: [],
    },
    {
      id: 2,
      label: 'Body Copy',
      body: '',
      type: 'textarea',
      suggested: 300,
      comments: [{
        id: 1,
        user: 'Phil Brook',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '11 November 2020, 10:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        id: 2,
        user: 'Ameera Chiong',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '12 November 2020, 09:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        id: 3,
        user: 'Ameera Chiong',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '13 November 2020, 09:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }],
    },
    {
      id: 3,
      label: 'Bullet 1',
      body: '',
      type: 'input',
      suggested: 60,
      comments: [],
    },
    {
      id: 4,
      label: 'Bullet 2',
      body: '',
      type: 'input',
      suggested: 60,
      comments: [],
    }],
  },
  {
    id: 2,
    name: 'McGrath Classic 4 Page Brochure 2021',
    templates: [{
      id: 1,
      label: 'Introduction',
      body: 'brochure intro sample',
      type: 'input',
      suggested: 60,
      comments: [{
        id: 1,
        user: 'Ameera Chiong',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '12 November 2020, 09:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        id: 2,
        user: 'Phil Brook',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '11 November 2020, 10:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }],
    },
    {
      id: 2,
      label: 'Body Copy',
      body: '',
      type: 'textarea',
      suggested: 60,
      comments: [{
        id: 1,
        user: 'Ameera Chiong',
        userImage: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
        role: 'Quality Controller',
        commentDate: '12 November 2020, 09:00 AM',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }],
    }],
  },
]

const defaultState = {
  assignedUserRole: null,
  groupedAssetComments: [],
  markers: [],
  selectedAsset: {},
  showAllMarkers: false,
  showEditor: true,
  showQc: false,
  updatedAsset: false,
}

const AssetScreen = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    assignedUserRole, groupedAssetComments, markers, selectedAsset, showAllMarkers, showEditor, showQc, updatedAsset,
  } = state

  const { id: serviceJobId } = useParams()
  const { currentUser } = useCurrentUser()

  const { assetId } = parsePermittedQueryParams(getQueryParamsFromHash(), ['assetId'])

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, serviceGroups } = entities

  useReduxAction('serviceJobs', 'getServiceJob', new URLSearchParams([
    ['includes', 'service'],
    ['includes', 'campaign'],
    ['includes', 'client'],
    ['includes', 'details'],
    ['includes', 'histories'],
    ['includes', 'users'],
  ]), [serviceJobId], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && !loadedIds.includes(serviceJobId)
    },
  })

  useReduxAction('assets', 'loadAssets', new URLSearchParams([
    ['serviceJobs', serviceJobId],
    ['includes', 'comments'],
    ['includes', 'file'],
  ]), [serviceJobId, updatedAsset])

  const { serviceJob } = useServiceJob({ id: serviceJobId })
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)

  useEffect(() => {
    setState({ assignedUserRole: filteredServiceJobUsers?.find(x => x.user.id === currentUser.id) })
  }, [serviceJobId, filteredServiceJobUsers])

  useEffect(() => {
    setState({ selectedAsset: assets[updatedAsset ? selectedAsset?.id : assetId] })
    if (updatedAsset) setState({ updatedAsset: false })
  }, [assets, serviceJobId, assetId])

  useEffect(() => {
    if (selectedAsset && Object.keys(selectedAsset)?.length > 0){
      const marker = selectedAsset?.comments.reduce((filtered, comment) => {
        if (Object.keys(comment.details)?.length > 0){
          filtered.push({
            ...comment,
            left: JSON.parse(comment.details[0]?.value).x,
            top: JSON.parse(comment.details[0]?.value).y,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty details array */
      setState({ markers: [...marker] })
    }
  }, [selectedAsset])

  useEffect(() => {
    setState({ groupedAssetComments: groupBy(markers, 'status') })
  }, [markers])

  const componentMapping = {
    AssetMarker,
    CopyTemplate,
    DefaultView: () => (
      <Box
        alignItems="center"
        display="grid"
        height={[150, 250, 650]}
        justifyContent="center"
        variant="white"
      >
        <Text fontSize="small" fontWeight="600">No Available View</Text>
      </Box>
    ),
  }

  const getAssetComponent = () => {
    switch (serviceGroups[serviceJob?.serviceGroupId]?.id){
      case 1: case 2:
        return ({
          component: componentMapping.AssetMarker,
          componentProps: {
            contents: Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id),
            markers,
            showAllMarkers,
            setState,
          },
        })
      case 3:
        return ({
          component: componentMapping.CopyTemplate,
          componentProps: {
            contents: copyContent,
          },
        })
      default: return ({ component: componentMapping.DefaultView })
    }
  }

  const updateAsset = () => {
    setState({ updatedAsset: !updatedAsset })
  }

  const showAll = () => {
    setState({ showAllMarkers: !showAllMarkers })
  }

  const { component: Component, componentProps } = getAssetComponent()

  const pageContext = {
    assignedUserRole,
    callbacks: {
      toggleUpdatedAsset: () => updateAsset(),
    },
    currentUser,
    groupedAssetComments,
    selectedAsset,
    serviceJob,
    serviceJobId: parseInt(serviceJobId, 10),
  }

  return (
    <PageContext.Provider value={pageContext}>
      <Box display="flex" flexDirection="column" minWidth="100%" backgroundColor="white">
        <Columns flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Box flexDirection="column" padding="large" paddingRight={[16, 16, 0]}>
              <Box
                flexDirection="row"
                marginRight="auto"
                onClick={() => { window.location.hash = `/service-jobs/${serviceJobId}` }}
                style={{ cursor: 'pointer' }}
                width="auto"
              >
                <Box paddingRight="medium" marginRight="auto" justifyContent="flexEnd" width="auto">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Box>
                <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
                  Go Back
                </Text>
              </Box>
              <Component componentProps={componentProps} />
            </Box>
          </Columns.Main>
          <Columns.Sidebar>
            <Box borderRadius="0 !important" flexDirection="column" lineHeight="1.3" variant="white">
              <Box alignItems="center" display="flex" flexDirection="row" width="100%">
                <Box
                  padding="large"
                  justifyContent="center"
                  borderBottom={showEditor ? 'solid 3px #E95B35' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setState({ showEditor: true, showQc: false })}
                >
                  <Text fontSize="small" color={showEditor ? '#E95B35' : 'bodyFontColor'}>
                    Editor
                  </Text>
                </Box>
                <Box
                  padding="large"
                  justifyContent="center"
                  borderBottom={showQc ? 'solid 3px #E95B35' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setState({ showEditor: false, showQc: true })}
                >
                  <Text fontSize="small" color={showQc ? '#E95B35' : 'bodyFontColor'}>
                    QC
                  </Text>
                </Box>
              </Box>
              {showEditor && <EditorTab />}
              {showQc && <QCTab />}
            </Box>
          </Columns.Sidebar>
        </Columns>
        <ToolBar
          callbacks={{ toggleShowAllMarkers: () => showAll() }}
          showAllMarkers={showAllMarkers}
          showMarkerToggle={['Photography', 'Floorplan'].includes(serviceGroups[serviceJob?.serviceGroupId]?.name)}
          showSaveCopy={serviceGroups[serviceJob?.serviceGroupId]?.name === 'Copywriting'}
        />
      </Box>
    </PageContext.Provider>
  )
}

export default AssetScreen
