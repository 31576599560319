import React from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box,
  Text,
  ListItem,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const defaultState = {
  expanded: false,
}

const Summary = (props) => {
  const { summary } = props

  const [state, setState] = useSetState(defaultState)
  const { expanded } = state

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      onClick={() => setState({ expanded: !expanded })}
      style={{ cursor: 'pointer', textAlign: 'center' }}
    >
      <Box flexDirection="row">
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="bold"
            fontAlign="center"
            marginBottom="medium"
          >
            TOTAL {summary.name.toUpperCase()} TODAY
          </Text>
          <Text fontSize="large" fontWeight="bold">
            {summary.jobsToday}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {summary.incomplete} INCOMPLETE
          </Text>
        </Box>
        <Box
          color="bodyFontLightColor"
          width="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>

      {expanded && (
        <Box
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          lineHeight="1.4"
        >
          <Box flexDirection="column" alignItems="center" width="30%" margin="medium">
            <CircularProgressbar
              counterClockwise
              text={`${summary.percentComplete}%`}
              value={summary.percentComplete}
              strokeWidth={5}
              styles={buildStyles({
                textColor: '#404041',
                pathColor: '#02b7e2',
              })}
            />
          </Box>
          <Box flexDirection="row">
            <Box
              variant="white"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              padding="medium"
              margin="small"
              height="80px"
            >
              <Text fontSize="large" fontWeight="bold">
                {summary.manpower}
              </Text>
              <Text fontSize="xsmall">AVAILABLE MANPOWER</Text>
            </Box>
            <Box
              variant="white"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              padding="medium"
              margin="small"
              height="80px"
            >
              <Text fontSize="large" fontWeight="bold">
                {summary.overdue}
              </Text>
              <Text fontSize="xsmall">OVERDUE</Text>
            </Box>
            <Box
              variant="white"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              padding="medium"
              margin="small"
              height="80px"
            >
              <Text fontSize="large" fontWeight="bold">
                {summary.urgent}
              </Text>
              <Text fontSize="xsmall">URGENT</Text>
            </Box>
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

Summary.propTypes = {
  summary: PropTypes.object,
}

export default Summary
