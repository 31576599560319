import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  border, color, layout, position, space, typography, variant,
} from 'styled-system'

const StyledCell = styled.td`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${position}
  ${variant({
    variants: {
      header: {
        borderBottom: '1px solid',
        borderColor: '#ececec',
        color: '#8c8c8e',
        fontSize: '12px',
        letterSpacing: '1px',
        padding: '18px',
        whiteSpace: 'nowrap',
      },
      body: {
        borderBottom: '1px solid',
        borderColor: '#ececec',
        color: '#404041',
        fontSize: '14px',
        padding: '18px',
      },
    },
  })}

  &:after {
    position: absolute;
    display: ${props => (props.position === 'sticky' ? 'block' : 'none')};
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
    content: '';
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: -1px;
    width: 30px;
    transform: translateX(100%);
  }
`

const Cell = (props) => {
  const {
    children, variant: cellVariant, ...cellProps
  } = props

  return (
    <StyledCell backgroundColor="white" variant={cellVariant} {...cellProps}>
      {children}
    </StyledCell>
  )
}

Cell.propTypes = {
  cellProps: PropTypes.object,
  children: PropTypes.node,
  variant: PropTypes.string,
}

export default Cell
