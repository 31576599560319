/* eslint-disable max-len */
import React from 'react'
import { DateTime } from 'luxon'

import {
  Box,
  Button,
  Link,
  Text,
  Checkbox,
  UserProfileImage,
} from '@campaignhub/suit-theme'

import { faBellOn } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const generateServiceJobColumns = (activeTab) => {
  const jobColumns = [
    {
      dataKey: '1',
      render: function noRefCheck(){
        return <Checkbox />
      },
      title: '',
    },
    {
      dataKey: '2',
      render: function noRefCheck(){
        return (
          <UserProfileImage 
          imageUrl="https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80" 
          height="35px"
          width="35px"
          />
        )
      },
      title: '',
    },
    {
      dataKey: 'campaign',
      render: function noRefCheck(campaign){
        return campaign.name
      },
      title: 'Address',
    },
    {
      dataKey: 'client',
      render: function noRefCheck(client){
        return client.name
      },
      title: 'Agency',
    },
    {
      dataKey: 'service',
      render: function noRefCheck(service){
        return service.name
      },
      title: 'Product',
    },
    {
      dataKey: '3',
      render: function noRefCheck(){
        return (
          <Box
            flexDirection="column"
            textAlign="right"
            lineHeight="1.4"
          >
            <Text>Ameera Chiong</Text>
            <Text>Full Timer</Text>
          </Box>
        )
      },
      title: 'Provider',
    },
    // ToDo: show when management page is available
    // {
    //   dataKey: 'studioDeadline',
    //   render: function noRefCheck(studioDeadline){
    //     const now = DateTime.now()
    //     const deadline = DateTime.fromISO(studioDeadline)
    //     const pastDeadline = now > deadline

    //     return (
    //       <Box
    //         flexDirection={pastDeadline ? 'row' : 'column'}
    //         textAlign="right"
    //         lineHeight="1.3"
    //         style={{ color: pastDeadline ? 'red' : 'bodyFontLightColor' }}
    //       >
    //         {pastDeadline && (
    //           <Box flexDirection="column">
    //             <FontAwesomeIcon icon={faBellOn} />
    //           </Box>
    //         )}
    //         <Box flexShrink="0" flexDirection="column" marginLeft="medium" width="auto">
    //           <p>{deadline.toFormat('y-LL-dd')}</p>
    //           <p>{deadline.toFormat('hh:mm a ccc')}</p>
    //           <p>{deadline.toRelativeCalendar()}</p>
    //         </Box>
    //       </Box>
    //     )
    //   },
    //   title: 'Studio Deadline',
    // },
  ]

  if (activeTab.key === 'jobs'){
    jobColumns.push({
      dataKey: 'clientDeadline',
      render: function noRefCheck(clientDeadline){
        const now = DateTime.now()
        const deadline = DateTime.fromISO(clientDeadline)
        const pastDeadline = now > deadline

        return (
          <Box
            flexDirection={pastDeadline ? 'row' : 'column'}
            textAlign="right"
            lineHeight="1.4"
            style={{
              color: pastDeadline ? '#B62121' : undefined
            }}
          >
            {pastDeadline && (
              <Box flexDirection="column" alignItems="flex-end" paddingTop="22px">
                <FontAwesomeIcon icon={faBellOn} />
              </Box>
            )}
            <Box flexShrink="0" flexDirection="column" marginLeft="medium" width="auto">
              <p>{deadline.toFormat('y-LL-dd')}</p>
              <p>{deadline.toFormat('hh:mm a ccc')}</p>
              <p>{deadline.toRelativeCalendar()}</p>
            </Box>
          </Box>
        )
      },
      title: 'Client Deadline',
    })
  }

  if (activeTab.key !== 'jobs'){
    jobColumns.push(
      {
        dataKey: 'editor',
        render: function noRefCheck(editor, jobs){
          const serviceUsers = jobs.users.filter(job => job.serviceJobUserRoleId === 1)

          return (
            <Box
              flexDirection="column"
              textAlign="right"
              lineHeight="1.4"
              alignItems="center"
            >
              <Box flexDirection="row" alignItems="center">
                <Box flexDirection="column">
                  <FontAwesomeIcon icon={faCircle} color={!serviceUsers.length ? 'green' : 'red'} />
                </Box>
                <Box flexShrink="0" flexDirection="column" marginLeft="medium" width="auto">
                  {!serviceUsers.length ? <Text color="green">Available</Text>
                    : serviceUsers.map(serviceUser => (
                      <Box flexDirection="column" paddingBottom="small">
                        <Text color="red" marginRight="small">{serviceUser.user.firstName}</Text>
                        <Text color="red">{serviceUser.user.lastName}</Text>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )
        },
        title: 'Editor Name',
      },
      {
        dataKey: 'users',
        render: function noRefCheck(users){
          const serviceUsers = users.filter(job => job.serviceJobUserRoleId === (activeTab.key === 'qc' ? 2 : 3))

          return (
            <Box
              flexDirection="column"
              textAlign="right"
              lineHeight="1.4"
              alignItems="center"
            >
              <Box flexDirection="row" alignItems="center">
                <Box flexDirection="column">
                  <FontAwesomeIcon icon={faCircle} color={!serviceUsers.length ? 'green' : 'red'} />
                </Box>
                <Box flexShrink="0" flexDirection="column" marginLeft="medium" width="auto">
                  {!serviceUsers.length ? <Text color="green">Available</Text>
                    : serviceUsers.map(serviceUser => (
                      <Box flexDirection="column" paddingBottom="small">
                        <Text color="red" marginRight="small">{serviceUser.user.firstName}</Text>
                        <Text color="red">{serviceUser.user.lastName}</Text>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )
        },
        title: activeTab.key === 'qc' ? 'QC Name' : 'Rejection Name',
      },
    )
}

  jobColumns.push({
    dataKey: 'status',
    render: function noRefCheck(status, item){
      return (
        <Link href={`#/service-jobs/${item.id}`} disabledUnderline>
          <Button
            buttonStyle={status > 5 ? 'primaryDestroy' : 'primaryEdit'}
            size="medium"
            width="small"
            height="40px"
          >
            {status.description}
          </Button>
        </Link>
      )
    },
    title: 'Status',
  })

  return jobColumns
}

export default generateServiceJobColumns
