import React from 'react'

import { MainContent } from '@campaignhub/suit-theme'


import useMainNavigation from '@hooks/useMainNavigation'


import LoggedInUser from '@components/TopBar/components/LoggedInUser'
import PlayPauseTime from '@components/TopBar/components/PlayPauseTime'
import Search from '@components/TopBar/components/Search'
import TimeTracker from '@components/TopBar/components/TimeTracker'
import TopBar from '@components/TopBar'

import MainNavigation from '@sections/Admin/components/MainNavigation'


import DashboardRoutes from './routes'

const Dashboard = (props) => {
  const { prop } = props

  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        timeTrackerComponent={<TimeTracker />}
        playPauseTimeComponent={<PlayPauseTime />}
        searchComponent={<Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <DashboardRoutes />
      </MainContent>
    </>
  )
}

export default Dashboard
