import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock, faUserUnlock } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faPause, faPlus } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, StatusBadge, Text, UserProfileImage,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'
import useServiceJobProviders from '@hooks/useServiceJobProviders'

import Schedule from '@sections/Client/components/Bookings/Schedule'
import Note from '@sections/Client/components/Bookings/Note'

import Icons from '@components/Icons'
import NotificationBadge from '@components/NotificationBadge'

const defaultState = {
  bookingNote: '',
  showNote: false,
}

const BookingDetails = (props) => {
  const {
    bookableSlots, callbacks: { toggleFilterSlots, toggleGetCalendarDates }, campaignServiceJobs,
  } = useContext(PageContext)

  const { serviceJob, setStateCampaignBooking } = props

  const [state, setState] = useSetState(defaultState)
  const { bookingNote, showNote } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroupsV2 } = entities

  useEffect(() => {
    setState({
      bookingNote: serviceJob.details?.find(x => x.entityFieldType.name === 'SupplierNote')?.value || '',
    })
  }, [serviceJob])

  const { callbacks: { selectProvider } } = useServiceJobProviders(serviceJob)

  const updateHighlighting = () => {
    const cleanJobs = campaignServiceJobs.filter(x => x.serviceGroupId === serviceJob.serviceGroupId).map((bookJob) => {
      const job = bookJob
      job.highlighting = false

      return job
    })

    serviceJob.highlighting = true

    setStateCampaignBooking({ campaignServiceJobs: [...cleanJobs] })
    toggleFilterSlots(bookableSlots)
  }

  const serviceUser = serviceJob.users?.find(x => x).user
  const selectedProvider = serviceJob.selectedUsers?.find(x => x)
  const notBooked = ['pending', 'selectedBook', 'selectedStandby'].includes(serviceJob.bookingType)
  const oneProvider = campaignServiceJobs.some(x => x.order.id === serviceJob.order.id
    && serviceJob.order.status === 0 && x.serviceId === serviceJob.serviceId && x.id !== serviceJob.id)

  return (
    <Box flexDirection="column">
      <Box flexDirection="row" marginBottom="medium">
        <Box
          display="block"
          alignItems="center"
          color="bodyFontLightColor"
          justifyContent="center"
          marginRight="large"
          marginTop="3px"
          width="auto"
        >
          <Icons name={serviceGroupsV2[serviceJob.serviceGroupId]?.name} />
        </Box>
        <Box flexDirection="column" lineHeight="1.3">
          <Text fontSize="small" marginBottom="small" marginRight="small">{serviceJob.name}</Text>
          {serviceJob.companions && <Text fontSize="xsmall" marginTop="small" marginBottom="small">Companion:</Text>}
          {serviceJob.companions && serviceJob.companions.map(companion => (
            <Text
              fontSize="small"
              marginBottom="small"
              title={companion.name}
              variant="ellipsis"
              width={250}
            >
              {companion.name}
            </Text>
          ))}
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {serviceUser ? `${serviceUser.firstName} ${serviceUser.lastName}`
              : selectedProvider ? `${selectedProvider.firstName} ${selectedProvider.lastName}`
              : 'Any Provider'}
          </Text>
          <Box flexDirection="column" marginTop="small" lineHeight="1">
            {notBooked && <StatusBadge boxProps={{ width: '100px' }} text="Pending" color="#FFC107" />}
            {serviceJob.bookingType === 'booked' && <StatusBadge boxProps={{ width: '100px' }} text="Confirmed" color="#28A745" />}
            {serviceJob.bookingType === 'standby' && <StatusBadge boxProps={{ width: '100px' }} text="Standby" color="orange" />}
          </Box>
        </Box>
        <NotificationBadge
          badge={(
            <FontAwesomeIcon
              color="white"
              icon={selectedProvider || serviceJob.bookingType === 'booked' ? faCheck : notBooked ? faPlus : faPause}
              size="xs"
            />
          )}
          badgeProps={{
            minWidth: '16px',
            minHeight: '16px',
            right: '-4px',
            top: '28px',
          }}
          onClick={selectProvider}
          parent={(
            <UserProfileImage imageUrl={serviceUser ? serviceUser?.imageUrl : selectedProvider?.imageUrl} width={45}>
              <FontAwesomeIcon icon={notBooked ? faUserUnlock : faUserLock} />
            </UserProfileImage>
            )}
          showBadge={![4, 18].includes(serviceJob.serviceId)}
          color={serviceJob.bookingType === 'pending' ? 'lightGrey' : notBooked ? 'blue' : serviceJob.bookingType === 'booked' ? 'green' : 'orange'}
          disabled={!notBooked || oneProvider || [4, 18].includes(serviceJob.serviceId)}
          disableNote={[4, 18].includes(serviceJob.serviceId) ? 'Cannot choose providers for Copywriting Onsite jobs' : ''}
        />
      </Box>
      <Schedule bookingState={setState} serviceJob={serviceJob} showNote={showNote} updateHighlighting={updateHighlighting} getCalendarDates={toggleGetCalendarDates} />
      {showNote && (
        <Box marginBottom="large">
          <Note bookingState={setState} note={bookingNote} serviceJobId={serviceJob.id} />
        </Box>
      )}
    </Box>
  )
}

BookingDetails.propTypes = {
  serviceJob: PropTypes.object,
  setStateCampaignBooking: PropTypes.func,
}

export default BookingDetails
