import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text, UserProfileImage } from '@campaignhub/suit-theme'

const IconProfile = (props) => {
  const {
    imageUrl, name, position, contactNo, email,
  } = props

  return (
    <Box flexDirection="row" alignItems="center">
      <UserProfileImage imageUrl={imageUrl} />
      <Box flexDirection="column" paddingLeft="large">
        <Text fontSize="small">
          {name}
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {position}
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {contactNo}
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {email}
        </Text>
      </Box>
    </Box>
  )
}

IconProfile.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  contactNo: PropTypes.string,
  email: PropTypes.string,
}

export default IconProfile
