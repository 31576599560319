import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, FormField, Link, StatusBadge, Text,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { getEntityByName } from '@functions/getEntityByName'
import { getServiceStatusColor } from '@functions/getServiceStatusColor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import Comment from '@components/Comment'
import Icons from '@components/Icons'

import useServiceJob from '@hooks/useServiceJob'

const assignServiceJobStatus = (serviceJobParam, createFn, setState, addedNotes, selectedAsset, currentJobAssets, toggleUpdateCurrentAssets) => {
  createFn(serviceJobParam, {}, false).then(({ errors, success }) => {
    if (!success && errors){
      return toast.warning(errors[0])
    }

    const tempAssets = currentJobAssets
    const tempNotes = addedNotes

    tempNotes.push({
      comment: serviceJobParam.assetComment,
      commenter: serviceJobParam.assetCommenter,
      createdAt: DateTime.now().toISO(),
      sort: addedNotes.length,
    })

    const selected = tempAssets.find(x => x.id === selectedAsset.id)
    selected.serviceJobStatusId = 160

    toggleUpdateCurrentAssets(tempAssets)

    return setState({ addedNotes: tempNotes, rejectionNote: '' })
  })
}

const defaultState = {
  addedComments: [],
  rejectionNote: '',
  comments: [],
}

const ClientComments = () => {
  const [state, setState] = useSetState(defaultState)
  const { addedComments, comments, rejectionNote } = state

  const {
    callbacks, currentAssets, currentUser, selectedServiceJob, selectedAsset,
  } = useContext(PageContext)

  const { toggleUpdateCurrentAssets } = callbacks
  const { firstName, lastName, timeZone } = currentUser

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    assetComments, assetCommentTypes, serviceGroups, serviceJobStatuses,
  } = entities

  const {
    callbacks: {
      assignServiceJobStatus: createFn,
    },
    updating,
    loading,
  } = useServiceJob({ id: selectedServiceJob?.id })

  useEffect(() => {
    const notes = Object.values(assetComments).reduce((filtered, comment) => {
      if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id && comment.assetId === selectedAsset?.id){
        filtered.push(comment)
      }
      return filtered
    }, [])

    setState({ comments: notes || [] })
  }, [assetComments, selectedAsset])

  const updateStatusModal = (statusId) => {
    const serviceJobParam = {
      id: selectedServiceJob.id,
      serviceJobStatusId: statusId,
      workflowComment: 'Change Requested from CH',
      assets: [selectedAsset.id],
      assetComment: rejectionNote,
      assetCommenter: `${firstName} ${lastName}`,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'ClientComment')?.id,
    }

    swal.fire({
      title: 'Request Changes',
      html: 'This action will submit your requested changes to our post-production team.'
      + '<br/>Do you still wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        assignServiceJobStatus(serviceJobParam, createFn, setState, addedComments, selectedAsset, currentAssets, toggleUpdateCurrentAssets)
      }
    })
  }

  return (
    <Box flexDirection="column">
      {selectedServiceJob?.id ? (
        <Box flexDirection="column" marginBottom="medium">
          <Box flexDirection="row" justifyContent="space-between" paddingBottom="small">
            <Box flexDirection="row" alignItems="center">
              <Box color="bodyFontLightColor" marginRight="medium" width="auto">
                <Icons name={serviceGroups[selectedServiceJob?.serviceGroupId]?.name.replace('-', '')} />
              </Box>
              <Text fontSize="small" fontWeight="500">
                {selectedAsset?.file?.originalName || selectedServiceJob?.name}
              </Text>
            </Box>
            <Box color="bodyFontLightColor" marginRight="medium" width="auto">
              <Link href={selectedServiceJob?.serviceGroupId !== 3 ? selectedAsset?.assetUrl : currentAssets.find(x => x.file)?.assetUrl}>
                <FontAwesomeIcon icon={faDownload} />
              </Link>
            </Box>
          </Box>
          {selectedAsset ? (
            <StatusBadge
              color={getServiceStatusColor(serviceJobStatuses[selectedAsset?.serviceJobStatusId]?.name)}
              text={serviceJobStatuses[selectedAsset?.serviceJobStatusId]?.description}
            />
          ) : null}
          {comments.length > 0 ? (
            <Text fontSize="xsmall" color="bodyFontLightColor" paddingTop="large">
              Requested Changes
            </Text>
          ) : null}
        </Box>
      ) : null}
      {comments.length > 0 ? (
        <Box
          flexDirection="column"
          maxHeight="25vh"
          overflow="auto"
          marginBottom="medium"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          paddingX="large"
          paddingTop="large"
        >
          {addedComments.sort((a, b) => (a.sort > b.sort ? -1 : 1)).map(comment => (
            <Comment
              commentBody={comment.comment}
              commentTime={DateTime.fromISO(comment.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
              fullName={comment.commenter}
              hideImage
            />
          ))}
          {comments.sort((a, b) => (a.id > b.id ? -1 : 1)).map((comment) => {
            const commenter = comment.details.find(x => x.entityFieldType.name === 'Commenter').value

            return (
              <Comment
                commentBody={comment.comment}
                commentTime={DateTime.fromISO(comment.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
                fullName={commenter ? JSON.parse(commenter).value : ''}
                hideImage
              />
            )
          })}
        </Box>
      ) : null}
      <FormField>
        <textarea
          name="noteContent"
          onChange={e => setState({ rejectionNote: e.target.value })}
          placeholder="Type your change requests here..."
          style={{ height: 80, resize: 'none', width: '100%' }}
          value={rejectionNote}
        />
        <Button
          buttonStyle="ghostDestroy"
          loading={updating || loading}
          marginTop="medium"
          onClick={() => updateStatusModal(getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id)}
          size="medium"
          width="100%"
          disabled={!rejectionNote.trim().length}
        >
          Request Change
        </Button>
      </FormField>
    </Box>
  )
}

export default ClientComments
