import React from 'react'

import {
  Box,
  Button,
  Checkbox,
  DashboardModule,
  ListItem,
  Text
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faTh } from '@fortawesome/pro-light-svg-icons'

const ProductDetails = () => {
  return (
    <DashboardModule
      title="Product Details"
      flexDirection="column"
    >
      <Box flexDirection="column" lineHeight="1.3">
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large"
          }}
          flexDirection="row"
          style={{ cursor: "pointer" }}
        >
          <Box flexDirection="column" paddingRight="medium">
            <Text>
              Property Photography
            </Text>
            <Text color="bodyFontLightColor" fontSize="small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Text>
          </Box>
          <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
            <Button
              size="medium"
              buttonStyle="primaryEdit"
              icon={<FontAwesomeIcon icon={faTh} />}
            >
              Related Services
            </Button>
          </Box>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
            alignItems: "center"
          }}
          flexDirection="row"
          style={{ cursor: "pointer" }}
        >
          <Box flexDirection="row">
            <Checkbox onClick={function noRefCheck() { }} />
            <Text>
              5 Standard Images
            </Text>
          </Box>
          <Box flexDirection="row" width="auto" alignItems="center">
            <Text>
              $150.00
            </Text>
            <Box flexDirection="column" paddingLeft="medium">
              <FontAwesomeIcon icon={faAngleRight} />
            </Box>
          </Box>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="row"
          style={{ cursor: "pointer" }}
        >
          <Box flexDirection="row">
            <Checkbox onClick={function noRefCheck() { }} />
            <Text>
              1 Day to Dusk Images
            </Text>
          </Box>
          <Box flexDirection="row" width="auto" alignItems="center">
            <Text>
              $50.00
            </Text>
            <Box flexDirection="column" paddingLeft="medium">
              <FontAwesomeIcon icon={faAngleRight} />
            </Box>
          </Box>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          style={{ cursor: "pointer" }}
        >
          <Text>
            Client Proofing Notes
          </Text>
          <Text color="bodyFontLightColor" fontSize="small">
            Curabitur et augue dolor. Fusce quis luctus nisl, eget pellentesque elit.
          </Text>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          style={{ cursor: "pointer" }}
        >
          <Text>
            Production Notes
          </Text>
          <Text color="bodyFontLightColor" fontSize="small">
            Donec sagittis vel lacus non pellentesque.
          </Text>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          style={{ cursor: "pointer" }}
        >
          <Text>
            Supplier Notes
          </Text>
          <Text color="bodyFontLightColor" fontSize="small">
            Curabitur rutrum, est eget commodo luctus, enim dui faucibus elit, in efficitur purus dui ut lectus.
          </Text>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          style={{ cursor: "pointer" }}
        >
          <Text>
            Studio Notes
          </Text>
          <Text color="bodyFontLightColor" fontSize="small">
            Proin eu augue mattis, egestas eros a, maximus lacus.
          </Text>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

export default ProductDetails