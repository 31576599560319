import React from 'react'

import {
  Box,
  Button,
  FormField,
  InputSearch,
  ListItem,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive } from '@fortawesome/pro-light-svg-icons'

const Tools = () => {
  return (
    <ListItem
      boxProps={{
        borderBottom: "1px solid",
        padding: "large",
      }}
      flexDirection="column"
      disableHover
    >
      <Box flexDirection="column" paddingBottom="large">
        <InputSearch />
      </Box>     
      <FormField direction="column">
        <Box flexDirection="column">
          <Box flexDirection="column" paddingBottom="large">
            <select>
              <option>Department</option>
              <option>2</option>
              <option>3</option>
            </select>
          </Box>
          <Box flexDirection="column" paddingBottom="large">
            <select>
              <option>Service Type</option>
              <option>2</option>
              <option>3</option>
            </select>
          </Box>
          <Box flexDirection="column" paddingBottom="large">
            <select>
              <option>Employement Type</option>
              <option>2</option>
              <option>3</option>
            </select>
          </Box>
          <Box flexDirection="column" paddingBottom="large">
            <select>
              <option>Access Level</option>
              <option>2</option>
              <option>3</option>
            </select>
          </Box>
        </Box>
      </FormField>
      <Box flexDirection="row">
        <Box flexDirection="column" marginRight="small">
          <Button buttonStyle="secondary" size="medium">
            Archive User
          </Button>
        </Box>
        <Box flexDirection="column" width="auto" height="auto">
          <Button buttonStyle="secondary" size="medium">
            <FontAwesomeIcon icon={faArchive} />
          </Button>
        </Box>
      </Box>
    </ListItem>
  )
}

export default Tools