import React from 'react'
import { useSelector } from 'react-redux'

import {
  Box, DashboardModule, ListItem, LoadingBubbles, Text,
} from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'

const CampaignBookings = () => {
  useReduxAction('bookingOverview', 'loadBookingsOverviewV2', {})

  const entityReducer = useSelector(reduxState => reduxState.bookingOverview)
  const { result: bookingOverview, loading } = entityReducer

  return (
    <DashboardModule title="Bookings">
      {loading && <LoadingBubbles loading={loading} color="rgba(0, 0, 0, 0.15)" style={{ padding: '16px' }} /> }
      {!loading && (
      <Box flexDirection="column" lineHeight="1.3">
        {Object.values(bookingOverview)?.map(booking => (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
              as: 'a',
            }}
            flexDirection="row"
            showAngleIcon
            href={`#/campaigns/${booking.campaignId}/bookings`}
          >
            <Box flexDirection="column" justifyContent="center">
              <Text fontSize="small">{booking.name}</Text>
            </Box>
            <Box
              flexDirection="row"
              justifyContent="center"
              width="auto"
            >
              <Text fontSize="xsmall" color="bodyFontLightColor">Book</Text>
            </Box>
          </ListItem>
        ))}
        {bookingOverview.length <= 0 && (
          <Box flexDirection="column" padding="large" alignItems="center" justifyContent="center">
            <Text fontSize="small">No bookings found</Text>
          </Box>
        )}
      </Box>
    )}
    </DashboardModule>
  )
}

export default CampaignBookings
