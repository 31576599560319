import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BookingImageUrl from './assets/booking.svg'

const Status = (props) => {
  const {
    hideImage,
    icon,
    iconColor,
    imageSize = 250,
    message,
    moduleTitle,
    title,
  } = props

  return (
    <DashboardModule title={moduleTitle || 'Booking Status'}>
      <Box flexDirection={['column-reverse', 'row']} lineHeight="1.3" padding="large">
        {!hideImage && (
          <Box
            alignItems="center"
            justifyContent="center"
            marginRight={[0, 'large']}
            width={['100%', imageSize]}
          >
            <img alt="Booking" src={BookingImageUrl} style={{ maxWidth: '100%' }} />
          </Box>
        )}
        <Box flexDirection="column" justifyContent="center" marginBottom={['large', 0]}>
          {title && (
          <Box flexDirection="row" marginBottom="large">
            {icon && (
              <Box color="lightGrey" marginRight="medium" width="auto" alignItems="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Box>
            )}
            <Text fontSize="small" fontWeight="600">
              {title}
            </Text>
          </Box>
          )}
          {message}
        </Box>
      </Box>
    </DashboardModule>
  )
}

Status.propTypes = {
  hideImage: PropTypes.bool,
  icon: PropTypes.object,
  iconColor: PropTypes.string,
  imageSize: PropTypes.number,
  message: PropTypes.node,
  moduleTitle: PropTypes.string,
  title: PropTypes.string,
}

export default Status
