import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, FormField, SidebarModal, FileUploadZone, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { getUppyOptions, getComponentCallbacks } from '@functions/uploadModal'

import useUppyUpload from '@hooks/useUppyUpload'

import RecentlyUploadedList from './components/RecentlyUploadedList'

const defaultState = {
  allowedFileTypes: ['image/jpeg', 'image/jpg'],
  selectedFileType: 'image',
  recentlyUploaded: [],
  lastDeletedId: 0,
  lastUploadedBatch: [],
}

const fileTypes = (fileType) => {
  const availableFileTypes = {
    image: ['image/jpeg', 'image/jpg'],
    pdf: ['application/pdf'],
  }

  return availableFileTypes[fileType] || {}
}

const insertRecentlyUploadedBatch = (newResourceList, list, setState) => {
  setState({ lastUploadedBatch: [] })
  setState({ recentlyUploaded: list.concat(newResourceList) })
}

const removeRecentlyDeleted = (id, list, setState) => {
  setState({ lastDeletedId: 0 })
  setState({ recentlyUploaded: list.filter(x => x.id !== id) })
}

const UploadModal = (props) => {
  const {
    callbacks, modalKey, showModal, title, titleSecondLine, serviceJobOptions, type, displayAllowedFileTypes,
  } = props

  const { deleteFn, loadFn } = callbacks

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources, assets } = entities

  const [state, setState] = useSetState(defaultState)
  const {
    allowedFileTypes, recentlyUploaded, lastDeletedId, lastUploadedBatch, selectedFileType,
  } = state

  useEffect(() => {
    if (lastDeletedId > 0){
      removeRecentlyDeleted(lastDeletedId, recentlyUploaded, setState)
    }
  }, [resources, assets])

  useEffect(() => {
    if (lastUploadedBatch.length > 0){
      insertRecentlyUploadedBatch(lastUploadedBatch, recentlyUploaded, setState)
    }
  }, [lastUploadedBatch])

  const uppyCallbacks = { ...getComponentCallbacks('uppy', setState), loadFn }
  const uppyOptions = getUppyOptions(type, allowedFileTypes, serviceJobOptions, setState, uppyCallbacks)
  const uppy = useUppyUpload(uppyOptions)

  const changeFileType = (event) => {
    setState({ allowedFileTypes: fileTypes(event.target.value) })
    setState({ selectedFileType: event.target.value })
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title={title} titleSecondLine={titleSecondLine} />

      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="large">
          {displayAllowedFileTypes && (
            <FormField direction="column" label="Choose Type" marginTop="large" value={selectedFileType}>
              <select onChange={changeFileType}>
                <option value="image">Images</option>
                <option value="pdf">PDF</option>
              </select>
            </FormField>
          )}
          {uppy
            && (
            <FormField direction="column" marginTop="large">
              <FileUploadZone
                uppy={uppy}
                subTitle={`Max Size is ${uppyOptions.maxTotalFileSize / 100000} mb`}
              />
            </FormField>
          )}
          {!uppy && (
            <FormField direction="column" marginTop="large">
              <Text marginBottom="small" fontSize="xsmall">
                Could not load uppy service
              </Text>
            </FormField>
          )}
        </Box>
        {recentlyUploaded.length > 0 && (
        <RecentlyUploadedList
          list={recentlyUploaded}
          callbacks={{ ...getComponentCallbacks('recentlyuploadedlist', setState), deleteFn }}
          fileType={type}
        />
        )}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

UploadModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  serviceJobOptions: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  displayAllowedFileTypes: PropTypes.bool.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  titleSecondLine: PropTypes.string,
}

UploadModal.defaultProps = {
  modalKey: 'UploadModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <UploadModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
