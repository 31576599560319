/* eslint-disable max-len */
import { useContext } from 'react'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import * as resourceActions from '@redux/modules/resource'

const deleteResource = (resourceParam, dispatch) => {
  const { deleteResource: deleteFn } = resourceActions

  return dispatch(deleteFn(resourceParam))
}

const loadResource = (resourceParam, options, dispatch) => {
  const { loadResource: loadFn } = resourceActions
  return dispatch(loadFn(resourceParam, options))
}

const launchResourceUploadModal = (dispatch, showResourceUploadModal) => new Promise((resolve, reject) => {
  if (showResourceUploadModal){
    const payload = {
      callbacks: {
      },
    }

    showResourceUploadModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showResourceUploadModal not defined in PageContext callbacks'))
})

const useUploadResource = () => {
  const { callbacks } = useContext(PageContext)
  const { showResourceUploadModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      launchResourceUploadModal: () => launchResourceUploadModal(dispatch, showResourceUploadModal),
      deleteResource: resourceParam => deleteResource(resourceParam, dispatch),
      loadResource: (resourceParam, options) => loadResource(resourceParam, options, dispatch),
    },
  }
}

export default useUploadResource
