/* eslint-disable import/prefer-default-export */
import { DateTime } from 'luxon'

export function getTimeIntervals(interval, startTime, endTime, timeZone){
  const currentDate = DateTime.now().setZone(timeZone)
  const start = currentDate.set({
    hour: startTime, minute: 0, second: 0, millisecond: 0,
  })
  const end = currentDate.set({
    hour: endTime, minute: 30, second: 0, millisecond: 0,
  })

  const result = []
  let current = DateTime.fromISO(start, { zone: timeZone })

  while (current < end){
    result.push(current)
    current = current.plus({ minute: interval })
  }

  return result
}
