export function filterToDate (filterDate, filterType) {   
  let today = new Date()
  let baseDate = new Date(today)
  
  let dateFilters = filterDate.split(' ')
  dateFilters.map(dateFilter => {
    let offset = parseInt(dateFilter.slice(0, -1))
    let type = dateFilter.slice(dateFilter.length - 1)

    if (type === 'd')
      baseDate.setDate(today.getDate() + (filterType === 'start' ? offset : offset - 1))

    if (type === 'm') {
      baseDate.setMonth(today.getMonth() + (filterType === 'start' ? offset : offset - 1))

      let lastDate = new Date(baseDate.getYear(), baseDate.getMonth() + 1, 0)
      baseDate.setDate(filterType === 'start' ? 1 : lastDate.getDate())
    }
  })

  return baseDate
}