/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import PageContext from '@contexts/pageContext'

import {
  Box, Button, Columns, LoadingModule, ModalContext, PageHeader, Text,
} from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useCampaignV2 from '@hooks/useCampaignV2'
import useCurrentDate from '@hooks/useCurrentDate'
import useServiceJobsV2 from '@hooks/useServiceJobsV2'
import useNumericParams from '@hooks/useNumericParams'

import CampaignInviteesModal from '@modals/CampaignInviteesModal'
import SelectProviderModal from '@modals/SelectProviderModal'
import Status from '@sections/Client/components/Bookings/Status'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'
import weeks from '@functions/weeks'
import Bookings from './components/Bookings'
import Calendar from './components/Calendar'
import CampaignDetails from './components/CampaignDetails'
import DuskTimes from './components/DuskTimes'

const assignAdditionalInvitees = (campaignInvitee, createFn) => {
  createFn(campaignInvitee).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const assignInvitees = (campaignInvitee, createFn, setState) => {
  createFn(campaignInvitee).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])

    setState({ showCampaignInviteesModal: false })
  })
}

const updateCampaign = (campaign, updateFn) => {
  updateFn(campaign).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CampaignInviteesModal: {
      closeModal: () => setState({ showCampaignInviteesModal: false }),
      assignAdditionalInvitees: (campaignInvitee, createFn) => assignAdditionalInvitees(campaignInvitee, createFn),
      assignInvitees: (campaignInvitee, createFn) => assignInvitees(campaignInvitee, createFn, setState),
      updateCampaign: (campaign, updateFn) => updateCampaign(campaign, updateFn),
    },
    SelectProviderModal: {
      closeModal: () => setState({ showSelectProviderModal: false }),
    },
  }
  return componentCallbacks[component] || {}
}

const Refresh = (props) => {
  const {
    campaignId, campaignServiceJobs, endOffset, orderId, startOffset,
  } = props

  const includes = ['booking', 'campaign', 'companions', 'details', 'order', 'selectedUsers', 'users']

  useReduxAction('serviceJobs', 'loadServiceJobsV2', {
    campaigns: campaignId,
    ...orderId && { orderId },
    includes: includes.join(','),
  }, [campaignId, campaignServiceJobs])

  useReduxAction('availableSlot', 'loadAvailableSlotsV2', {
    filterStart: `${startOffset}d`,
    filterEnd: `${endOffset}d`,
    campaigns: campaignId,
  }, [campaignServiceJobs, endOffset, startOffset, campaignId])

  return null
}

const defaultState = {
  bookableSlots: [],
  calendarSettings: {
    endTime: 22,
    slotLength: 50,
    startTime: 8,
  },
  calendarDates: [],
  refresh: false,
  selectedWeek: { id: 0, name: 'This Week' },
  startOffset: null,
  endOffset: null,
  showCampaignInviteesModal: false,
  showSelectProviderModal: false,
}

const CampaignBooking = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    bookableSlots, calendarDates, calendarSettings, refresh, selectedWeek, showCampaignInviteesModal, showSelectProviderModal, startOffset, endOffset,
  } = state

  const { id: campaignId } = useNumericParams()

  const { orderId, redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'orderId',
    'redirect',
  ])

  const { defaultOffset, firstCalendarDay } = useCurrentDate()

  const includes = ['area', 'campaignAgents', 'client', 'invitees', 'property', 'duskTimes']
  useReduxAction('campaigns', 'loadCampaignV2', {
    includes: includes.join(','),
  }, [campaignId], {
    dispatchAction: (action, requestOptions) => action(campaignId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return campaignId && !loadedIds.includes(campaignId)
    },
  })

  const {
    campaign,
    loading: campaignLoading,
  } = useCampaignV2({ id: campaignId })

  const {
    campaignServiceJobs,
    loading,
  } = useServiceJobsV2({ campaignId })

  const entityReducer = useSelector(reduxState => reduxState.availableSlot)
  const { result: availableSlots } = entityReducer

  const getCalendarDates = useCallback(({ currentPage = 0 }) => {
    const days = 5
    const newCalendarDates = []

    let day = firstCalendarDay().plus({ days: currentPage * 7 })
    const start = defaultOffset + (currentPage * 7)
    const end = start + 6
    while (newCalendarDates.length < days){
      if (day.weekday < 6) newCalendarDates.push(day)
      day = day.plus({ days: 1 })
    }

    setState({
      calendarDates: newCalendarDates,
      campaignServiceJobs,
      endOffset: end,
      refresh: true,
      startOffset: start,
    })
  }, [])

  useEffect(() => {
    getCalendarDates({})
  }, [])

  const filterSlots = useCallback((currentSlots) => {
    setState({ campaignServiceJobs })

    if (Array.isArray(availableSlots)){
      const highlightingJobs = campaignServiceJobs.filter(x => x.highlighting)
      const filtered = availableSlots.filter(x => highlightingJobs.find(y => y.serviceId === x.serviceId
        && (y.selectedUsers?.length ? y.selectedUsers[0].username === x.user.username : true)))

      if (JSON.stringify(filtered) === JSON.stringify(currentSlots)) return

      setState({
        bookableSlots: filtered,
      })
    }
  }, [availableSlots, campaignServiceJobs])

  useEffect(() => {
    filterSlots()
  }, [availableSlots, campaignServiceJobs])

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const updateSelectedWeek = (currentPage) => {
    getCalendarDates({ currentPage })

    const selected = weeks.find(x => x.id === parseInt(currentPage, 10))
    setState({ selectedWeek: selected })
  }

  const pageContext = {
    bookableSlots,
    calendarDates,
    calendarSettings,
    callbacks: {
      showSelectProviderModal: (payload) => {
        setModalData('SelectProviderModal', payload)
        setState({ showSelectProviderModal: true })
      },
      showCampaignInviteesModal: (payload) => {
        setModalData('CampaignInviteesModal', payload)
        setState({ showCampaignInviteesModal: true })
      },
      toggleFilterSlots: () => filterSlots(),
      toggleGetCalendarDates: calendarProps => getCalendarDates(calendarProps),
      toggleUpdateSelectedWeek: currentPage => updateSelectedWeek(currentPage),
    },
    campaign,
    campaignServiceJobs,
    selectedWeek,
  }

  const goBack = () => {
    if (redirect){
      window.location.href = redirect
      return
    }

    window.location.hash = '/overviews/bookings'
  }

  const selectedOrderId = campaignServiceJobs.find(x => x.highlighting)?.order.id || orderId
  const selectedConfirmed = campaignServiceJobs.find(x => x.order.id === selectedOrderId)?.order.status === 10
  const complete = !campaignServiceJobs.some(x => (selectedConfirmed ? x.order.status === 10 : x.order.id === selectedOrderId) && !x.booking?.id)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          actionContent={redirect ? (
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
              onClick={() => goBack()}
              size="medium"
            >
              Back
            </Button>
          ) : null}
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title={campaign.name || 'Campaign Bookings'}
        />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              {loading ? (
                <LoadingModule loading={loading} />
              ) : complete ? (
                <Status
                  icon={faCheckCircle}
                  iconColor="rgba(67,172,106)"
                  message={(
                    <Box flexDirection="column" fontSize="small">
                      <Text color="bodyFontLightColor" marginBottom="medium">Your booking request has been sent to our operations team.</Text>
                      <Text color="bodyFontLightColor" marginBottom="medium">
                        All <span style={{ color: '#43AC6A', fontWeight: '600' }}>Confirmed</span> bookings will receive a calendar invite
                        shortly. <span style={{ color: '#E78D2D', fontWeight: '600' }}>Standby</span> bookings need to be confirmed by
                        operations and they will reach out to you once your request is confirmed.
                      </Text>
                      <Text color="bodyFontLightColor">
                        Standard cancellation terms apply. Please call 1300 429 457 and select option 1 if you need to make a cancellation outside of business hours.
                      </Text>
                    </Box>
                  )}
                  title="Great news!"
                />
              ) : (
                <Status
                  message={(
                    <Box flexDirection="column" fontSize="small">
                      <Text color="bodyFontLightColor" marginBottom="small">
                        If your preferred day/time is greyed out, please feel free to request a standby booking. To do this, please hover over the grey slots and click the plus button.
                      </Text>
                      <Text color="bodyFontLightColor">
                        Note - nothing is guaranteed, but our CSO team will do their best to open up that slot for you. For more information please contact - support@contenthouse.io
                      </Text>
                    </Box>
                  )}
                  moduleTitle="Standby Note"
                  imageSize={100}
                />
              )}
              {!loading && <Calendar /> }
            </Columns.Main>

            <Columns.Sidebar>
              <LoadingModule loading={loading} />
              {!loading && campaignServiceJobs.length > 0 && (
                <>
                  <Bookings setState={setState} />
                  <DuskTimes />
                </>
              )}
              <LoadingModule loading={campaignLoading} />
              {!campaignLoading && (
                <CampaignDetails />
              )}
            </Columns.Sidebar>
          </Columns>
          {refresh && (
            <Refresh
              campaignId={campaignId}
              campaignServiceJobs={campaignServiceJobs}
              endOffset={endOffset}
              orderId={!selectedConfirmed ? selectedOrderId : orderId}
              startOffset={startOffset}
            />
          )}
        </Box>
        <SelectProviderModal
          availableSlots={availableSlots}
          bookableSlots={bookableSlots}
          callbacks={callbacks('SelectProviderModal', props, setState)}
          filterSlots={filterSlots}
          showModal={showSelectProviderModal}
        />
        <CampaignInviteesModal
          callbacks={callbacks('CampaignInviteesModal', props, setState)}
          showModal={showCampaignInviteesModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default CampaignBooking
