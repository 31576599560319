import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as serviceJobNoteActions from '@redux/modules/serviceJobNote'

const createServiceJobNote = (serviceJobNoteParam, dispatch, requestOptions) => {
  const { createServiceJobNote: createFn } = serviceJobNoteActions

  return dispatch(createFn(serviceJobNoteParam, requestOptions))
 }

const updateServiceJobNote = (serviceJobNoteParam, dispatch, requestOptions) => {
  const { updateServiceJobNote: updateFn } = serviceJobNoteActions

  return dispatch(updateFn(serviceJobNoteParam, requestOptions))
}

const useServiceJobNotes = (initServiceJobNote = {}) => {
  const { entity: serviceJobNote } = useLatestEntity(initServiceJobNote, 'serviceJobNotes')

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      createServiceJobNote: (serviceJobNoteParam, requestOptions) => createServiceJobNote(serviceJobNoteParam, dispatch, requestOptions),
      updateServiceJobNote: (serviceJobNoteParam, requestOptions) => updateServiceJobNote(serviceJobNoteParam, dispatch, requestOptions),
    },
    serviceJobNote,
  }
}

export default useServiceJobNotes
