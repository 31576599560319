import React from 'react'

import { Button } from '@campaignhub/suit-theme'

import useUser from '@hooks/useUser'

const Status = (props) => {
  const { user } = props

  const { callbacks: { launchEditUserModal } } = useUser(user)

  return (
    <Button buttonStyle='primaryCreate' size='medium' onClick={launchEditUserModal}>
      Available
    </Button>
  )
}

export default Status