/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import {
  Box,
  Button,
  DashboardModule,
  FormField,
  ListItem,
  LoadingModule,
  Tag,
  Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'
import useWorkflow from '@hooks/useWorkflow'
import { getDeadline } from '@functions/getDeadline'
import { getEntityByName } from '@functions/getEntityByName'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faBellOn } from '@fortawesome/pro-light-svg-icons'

const loadWorkflow = (workflow, loadFn, setState) => {
  loadFn(workflow).then(({ success, data }) => {
    if (!success && data){
      toast.warning(data[0])
      return
    }

    setState({ workflow: data })
  })
}

const defaultState = {
  isWorkflowCommentValid: true,
  statusId: 0,
  tagExists: false,
  tags: ['tag 1', 'tag 2'],
  tagValue: '',
  totalImages: 0,
  workflow: {},
  workflowComment: '',
}

const JobDetails = () => {
  const {
    callbacks: { assignServiceJobStatus },
    serviceJob,
    workflowUpdated,
  } = useContext(PageContext)
  const { status } = serviceJob

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const [state, setState] = useSetState(defaultState)
  const {
    isWorkflowCommentValid, statusId, tagExists, tags, tagValue, totalImages, workflow, workflowComment,
  } = state

  const { currentUser: { timeZone } } = useCurrentUser()

  const serviceJobPayload = useServiceJob()
  const {
    callbacks: { assignServiceJobStatus: createFn },
  } = serviceJobPayload

  useEffect(() => {
    setState({
      totalImages: serviceJob.details?.find(x => x.entityFieldType.name === 'TotalImages')?.value || 0,
      statusId: serviceJob.serviceJobStatusId,
    })
  }, [serviceJob, workflowUpdated])

  const workflowPayload = useWorkflow()
  const {
    callbacks: { loadWorkflow: loadFn },
    loaded,
    loading,
  } = workflowPayload

  useEffect(() => {
    loadWorkflow({ id: 1 }, loadFn, setState)
  }, [loaded])

  const saveServiceJobState = () => {
    setState({ isWorkflowCommentValid: workflowComment.length > 0 })

    if (workflowComment.length > 0){
      const serviceJobParam = {
        id: serviceJob.id,
        serviceJobStatusId: statusId,
        workflowComment,
      }
      assignServiceJobStatus(serviceJobParam, createFn)
    }
  }

  const archiveServiceJob = (archive) => {
    const { histories } = serviceJob
    const reversedHistories = histories.sort((x, y) => y.id - x.id)

    const previousStatus = reversedHistories.find(x => (
      x.serviceJobStatusId !== serviceJob.serviceJobStatusId && x.serviceJobStatusId !== getEntityByName(serviceJobStatuses, 'ContentArchived')?.id
    ))

    const statusBeforeArchived = previousStatus ? previousStatus.serviceJobStatusId : getEntityByName(serviceJobStatuses, 'ContentOrdered')?.id

    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: archive ? getEntityByName(serviceJobStatuses, 'ContentArchived')?.id : statusBeforeArchived,
      workflowComment: `${archive ? 'Archived' : 'Unarchived'} on service job page`,
    }

    swal.fire({
      title: `${archive ? 'Archive' : 'Unarchive'}`,
      html: `This action will ${archive ? 'archive' : 'unarchive'} this job.`
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        assignServiceJobStatus(serviceJobParam, createFn)
      }
    })
  }

  const selectTag = (tag) => {
    setState({ tagValue: tag })
  }

  const toggleTag = (tag) => {
    setState({
      tags: tagExists ? tags.filter(x => x !== tagValue) : [...tags, tag],
      tagExists: !tagExists,
    })
  }

  useEffect(() => {
    setState({ tagExists: tags.find(tag => tag === tagValue) })
  }, [tagValue])

  return (
    <>
      <LoadingModule loading={loading} />
      {!loading
        && (
        <DashboardModule title="Job Details">
          <Box flexDirection="column">
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              style={{ cursor: 'pointer' }}
              disableHover
            >
              {/* ToDo: show when management page is available
              <Box flexDirection="column" paddingBottom="large">
                <Box flexDirection="row" paddingBottom="small">
                  <Text fontSize="small" paddingRight="small">
                    Studio Deadline
                  </Text>
                  <FontAwesomeIcon icon={faBellOn} color="#B62121" />
                </Box>
                <Button backgroundColor="#FA6F6F" color="white" border={0} size="small">
                  {`${DateTime.fromISO(serviceJob.studioDeadline, { zone: timeZone }).toFormat('hh:mm a')}, ${
                  DateTime.fromISO(serviceJob.studioDeadline, { zone: timeZone }).toFormat('dd LLLL y')}`}
                </Button>
              </Box> */}
              <Box flexDirection="column" paddingBottom="large">
                <Box flexDirection="row" paddingBottom="small">
                  <Text fontSize="small" paddingRight="small">
                    Client Deadline
                  </Text>
                  <FontAwesomeIcon icon={faBellOn} color="red" />
                </Box>
                <Button backgroundColor="#FA6F6F" color="white" border={0} size="small">
                  {`${DateTime.fromISO(serviceJob.clientDeadline, { zone: timeZone }).toFormat('hh:mm a')}, ${
                  DateTime.fromISO(serviceJob.clientDeadline, { zone: timeZone }).toFormat('dd LLLL y')}`}
                </Button>
                <Button backgroundColor="#FA6F6F" color="white" border={0} size="small" marginTop="small">
                  {getDeadline(serviceJob?.clientDeadline, timeZone)}
                </Button>
              </Box>
              <Box flexDirection="column" paddingBottom="large">
                <Text fontSize="small" paddingBottom="small">
                  Total Images
                </Text>
                <Tag boxProps={{ fontSize: 'xsmall', width: 'fit-content' }}>
                  {totalImages ? JSON.parse(totalImages).value : totalImages}
                </Tag>
              </Box>
              <Box flexDirection="column">
                <Text fontSize="small" paddingBottom="small">
                  Tags
                </Text>
                <FormField boxProps={{ marginBottom: 'large' }} direction="column">
                  <Box>
                    <input value={tagValue} onChange={e => setState({ tagValue: e.target.value })} type="text" />
                    <Button
                      buttonStyle={tagExists ? 'secondaryDestroy' : 'secondaryCreate'}
                      onClick={() => toggleTag(tagValue)}
                      marginLeft="small"
                      size="small"
                      width="auto"
                      disabled={!tagValue}
                    >
                      {tagExists ? 'Remove' : 'Add'}
                    </Button>
                  </Box>
                  <Box marginTop="medium">
                    {tags.map(tag => (
                      <Tag boxProps={{ fontSize: 'xsmall', onClick: () => selectTag(tag) }} key={tag}>
                        {tag}
                      </Tag>
                    ))}
                  </Box>
                </FormField>
              </Box>
              <Box flexDirection="column">
                <Text fontSize="small" paddingBottom="small">
                  Status
                </Text>
                <Box flexDirection="column">
                  <FormField>
                    <select onChange={e => setState({ statusId: e.target.value })} value={statusId}>
                      {workflow.serviceJobStatuses?.map(serviceJobStatus => (
                        <option option key={serviceJobStatus.id} value={serviceJobStatus.id} selected={status?.id === serviceJobStatus.id}>
                          {serviceJobStatus.description}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </Box>
                <Box flexDirection="column" paddingTop="small">
                  <FormField errorMessage={!isWorkflowCommentValid ? 'Required' : ''}>
                    <textarea
                      type="text"
                      placeholder="Reason required for change"
                      onChange={ev => (setState({ workflowComment: ev.target.value.trim() }))}
                    />
                  </FormField>
                </Box>
              </Box>
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <Button buttonStyle="secondary" size="medium" onClick={saveServiceJobState}>
                Change Status
              </Button>
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <Box flexDirection="row">
                <Box flexDirection="column" marginRight="small">
                  <Button
                    buttonStyle="secondary"
                    disabled={serviceJob.serviceJobStatusId === getEntityByName(serviceJobStatuses, 'ContentArchived')?.id}
                    size="medium"
                    onClick={() => archiveServiceJob(1)}
                  >
                    Archive
                  </Button>
                </Box>
                <Box flexDirection="column" width="auto">
                  <Button
                    buttonStyle="secondary"
                    disabled={serviceJob.serviceJobStatusId !== getEntityByName(serviceJobStatuses, 'ContentArchived')?.id}
                    size="medium"
                    onClick={() => archiveServiceJob(0)}
                  >
                    <span><FontAwesomeIcon icon={faArchive} /></span>
                  </Button>
                </Box>
              </Box>
            </ListItem>
          </Box>
        </DashboardModule>
      )}
    </>
  )
}

export default JobDetails
