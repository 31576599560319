import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { getEntityByName } from '@functions/getEntityByName'
import Comment from '@components/Comment'

const defaultState = {
  comments: [],
}

const ProofingNotes = () => {
  const [state, setState] = useSetState(defaultState)
  const { comments } = state

  const { currentUser: { timeZone }, selectedAsset } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetComments, assetCommentTypes } = entities

  useEffect(() => {
    const notes = Object.values(assetComments).reduce((filtered, comment) => {
      if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ProofingNote')?.id && comment.assetId === selectedAsset?.id){
        filtered.push(comment)
      }
      return filtered
    }, [])

    setState({ comments: notes || [] })
  }, [assetComments, selectedAsset])

  if (!comments.length > 0) return null

  return (
    <Box flexDirection="column" borderTop="1px dashed" borderColor="lineColor" marginTop="large">
      <Text fontSize="xsmall" color="bodyFontLightColor" marginTop="large" marginBottom="medium">
        Proofing Notes
      </Text>
      {comments.length > 0 ? (
        <Box
          flexDirection="column"
          maxHeight="25vh"
          overflow="auto"
          marginBottom="medium"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          paddingX="large"
          paddingTop="large"
        >
          {comments.sort((a, b) => (a.id > b.id ? -1 : 1)).map((comment) => {
            const commenter = comment.details.find(x => x.entityFieldType.name === 'Commenter').value

            return (
              <Comment
                commentBody={comment.comment}
                commentTime={DateTime.fromISO(comment.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
                fullName={commenter ? JSON.parse(commenter).value : ''}
                hideImage
              />
            )
          })}
        </Box>
      ) : (
        <Box flexDirection="column">
          <Text fontSize="xsmall">No Proofing Notes</Text>
        </Box>
      )}
    </Box>
  )
}

export default ProofingNotes
