import { useContext } from 'react'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

const selectProvider = (serviceJob, dispatch, showSelectProviderModal) => new Promise((resolve, reject) => {
  if (showSelectProviderModal){
    const payload = {
      serviceJob,
    }

    showSelectProviderModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showSelectProviderModal not defined in PageContext callbacks'))
})

const useServiceJobProviders = (serviceJob) => {
  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showSelectProviderModal } = callbacks || {}

  return {
    serviceJob,
    callbacks: {
      selectProvider: () => selectProvider(serviceJob, dispatch, showSelectProviderModal),
    },
  }
}

export default useServiceJobProviders
