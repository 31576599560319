import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
 Box, Button, Checkbox, FormField, Text,
} from '@campaignhub/suit-theme'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faCheckCircle, faPencil } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'
import InputComponent from './components/InputComponent'
import TextAreaComponent from './components/TextAreaComponent'

const defaultState = {
  showFilter: false,
}

const CopyTemplate = (props) => {
  const { componentProps: { contents } } = props

  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const componentMapping = {
    input: InputComponent,
    textarea: TextAreaComponent,
    DefaultView: () => (
      <Text fontSize="small" color="bodyFontLightColor" marginBottom="small">
        Input type does not exist.
      </Text>
    ),
  }

  return (
    <>
      <Box alignItems="center" flexDirection="row" marginBottom="large">
        <Text fontSize="medium" fontWeight="500">
          Assets
        </Text>
        <Box marginLeft="auto" justifyContent="flexEnd" width="auto">
          <FormField>
            <Box className={custom.root} ref={contentEl}>
              <Box flexDirection="column">
                <Button
                  buttonStyle="secondary"
                  size="medium"
                  onClick={() => setState({ showFilter: !showFilter })}
                  className={custom.btnFilter}
                >
                  Select Template
                  <Text paddingLeft="small"><FontAwesomeIcon icon={faAngleDown} /></Text>
                </Button>
              </Box>
              {showFilter && (
                <Box className={custom.popup} minWidth="250px" right="0">
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <Checkbox checked color="blue" iconChecked={faCheckCircle} />
                    <Text fontSize="small" marginLeft="medium">McGrath Website 2020</Text>
                  </Box>
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <Checkbox color="blue" iconChecked={faCheckCircle} />
                    <Text fontSize="small" marginLeft="medium">McGrath Classic 4 Page Brochure 2021</Text>
                  </Box>
                </Box>
              )}
            </Box>
          </FormField>
        </Box>
        <Box marginLeft="medium" width="auto">
          <Button buttonStyle="secondaryUtility" size="medium">
            <Text><FontAwesomeIcon icon={faPencil} /></Text>
          </Button>
        </Box>
      </Box>
      <Box flexDirection="column">
        {contents.map(content => (
          <>
            <Text fontSize="medium" fontWeight="500" marginBottom="large">
              {content.name}
            </Text>
            {content.templates.map((template) => {
            const Component = componentMapping[template.type] || componentMapping.DefaultView
            return <Component copyProps={template} />
          })}
          </>
      ))}
      </Box>
    </>
  )
}

CopyTemplate.propTypes = {
  componentProps: PropTypes.object,
}

export default CopyTemplate
