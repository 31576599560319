import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, Text,
} from '@campaignhub/suit-theme'

const TimeSlots = React.memo((props) => {
  const { displayedTime, slotLength, timeZone } = props

  return (
    <Box
      backgroundColor="white"
      gridArea="timeslots-container"
      left="0"
      position="sticky"
      zIndex="4"
    >
      <Box flexDirection="column" alignItems="center">
        {displayedTime.map(time => (
          <Box
            alignItems="center"
            height={`${slotLength}px`}
            justifyContent="center"
            style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
            textAlign="center"
          >
            <Text fontSize="small">{DateTime.fromISO(time, { zone: timeZone }).toFormat('hh:mm a')}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
})

TimeSlots.propTypes = {
  displayedTime: PropTypes.array,
  slotLength: PropTypes.number,
  timeZone: PropTypes.string,
}

export default TimeSlots
