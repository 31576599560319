import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  DashboardModule,
  ListItem,
  Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH, faArchive } from '@fortawesome/pro-light-svg-icons'
import Icons from '@components/Icons'

import DateFilter from './components//DateFilter'
import ClientFilter from './components/ClientFilter'
import StudioUserFilter from './components/StudioUserFilter';
import ServiceUserFilter from './components/ServiceUserFilter'
 
const Tools = (props) => {
  const { setJobState, jobState } = props
  const { expandedFilter } = jobState
 
  return (
    <DashboardModule boxProps={{ marginBottom: "large" }} title="Tools">
      <Box flexDirection="column">
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          disableHover
        >
          <Button
            buttonStyle="secondary"
            size="medium"
            height="40px"
            icon={<FontAwesomeIcon icon={faSlidersH} />}
            onClick={() => setJobState({ expandedFilter: !expandedFilter })}
          >
            Manage Filters
          </Button>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
          }}
          flexDirection="column"
          disableHover
        >
          <Box flexDirection="column" paddingBottom="large">
            <Toggle
              onClick={function noRefCheck() { }}
              toggleOffText="All Jobs"
              toggleOn
              toggleOnText="Archived Jobs Only"
            />
          </Box>
          <Box flexDirection="column" paddingBottom="large">
            <Toggle
              onClick={function noRefCheck() { }}
              toggleOffText="Show Approved Jobs"
              toggleOn
              toggleOnText="Hide Approved Jobs"
            />
          </Box> 
          <DateFilter setJobState={setJobState} jobState={jobState} />
          <ClientFilter setJobState={setJobState} jobState={jobState} />
          <StudioUserFilter setJobState={setJobState} jobState={jobState} />
          <ServiceUserFilter setJobState={setJobState} jobState={jobState} />
          <Box flexDirection="row">
            <Box flexDirection="column" marginRight="small">
              <Button buttonStyle="secondary" size="medium" height="40px">
                Archive
              </Button>
            </Box>
            <Box flexDirection="column" width="auto">
              <Button buttonStyle="secondary" size="medium" height="40px" icon={<Icons name="Unarchive"/>}>
                {/* <span><FontAwesomeIcon icon={faArchive} /></span> */}
              </Button>
            </Box>
          </Box>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

Tools.propTypes = {
  setJobState: PropTypes.object,
  jobState: PropTypes.bool,
}

export default Tools