import React, { useContext } from 'react'

import {
  Box, Button, DashboardModule, ListItem,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useServiceJobUser from '@hooks/useServiceJobUser'
import useServiceJobUsers from '@hooks/useServiceJobUsers'

import 'react-circular-progressbar/dist/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import AssignedUser from './components/AssignedUser'

const AssignedUsers = () => {
  const { serviceJob } = useContext(PageContext)
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)

  const initServiceJobUser = { id: null, serviceJobId: serviceJob.id }
  const serviceJobUserPayload = useServiceJobUser(initServiceJobUser)
  const {
    callbacks: {
      launchChangeServiceJobUserModal,
    },
  } = serviceJobUserPayload

  return (
    <DashboardModule title="Assigned Users">
      <Box flexDirection="column">
        {filteredServiceJobUsers.map(serviceJobUser => (
          <AssignedUser key={serviceJobUser.id} serviceJobUser={serviceJobUser} />
        ))}
        {filteredServiceJobUsers.length > 3 && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Button buttonStyle="secondary" size="medium" icon={<FontAwesomeIcon icon={faPlus} />}>
              Load More
            </Button>
          </ListItem>
        )}
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <Button buttonStyle="secondary" size="medium" onClick={launchChangeServiceJobUserModal}>
            Assign User
          </Button>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

export default AssignedUsers
