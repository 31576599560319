import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Users from './screens/Users'

const SystemManagerRoutes = () => (
  <Routes>
    <Route path='/users/*' element={<Users />} />
  </Routes>
)

export default SystemManagerRoutes