import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'
import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

import { Box, Text, ListItem } from '@campaignhub/suit-theme'

import useFilter from '@hooks/useFilter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faPlus
} from '@fortawesome/pro-light-svg-icons'

import View from './components/View'

const defaultState = {
  customViewState: [],
  selectedCustomView: null
}

const CustomView = (props) => {
  const { setJobState } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { filters } = entities

  const options = new URLSearchParams([
    ["entityType", "serviceJob"]
  ])

  useReduxAction('filters', 'loadFilters', options)

  const [state, setState] = useSetState(defaultState)
  const { customViewState, selectedCustomView } = state

  const initFilter = { id: null }
  const { callbacks: { launchCreateServiceJobViewModal } } = useFilter(initFilter)

  useEffect(() => {
    setState({
      customViewState: Object.values(filters).map(filter => ({
        ...filter,
        isChecked: selectedCustomView === filter.id
      }))
    })
  }, [filters, selectedCustomView])

  const updateCustomView = (checked, view) => {
    if (checked) {
      setJobState({
        clientFilter: view.clients,
        dateFilter: {
          filterStart: view.filterStart,
          filterEnd: view.filterEnd
        },
        serviceFilter: view.services,
        statusFilter: view.serviceJobStatuses,
        userFilter: view.users
      })
    }
    else {
      setJobState({
        clientFilter: [],
        dateFilter: {
          filterStart: '',
          filterEnd: ''
        },
        serviceFilter: [],
        statusFilter: [],
        userFilter: []
      })
    }

    setState({ selectedCustomView: checked ? view.id : null })
  }

  return (
    <ListItem
      boxProps={{
        padding: "medium",
      }}
      flexDirection="column"
      disableHover
      style={{ cursor: "pointer" }}
    >
      <Box flexDirection="row">
        <Box
          color="bodyFontLightColor"
          marginLeft="auto"
          width="auto"
          marginRight="medium"
        >
          <FontAwesomeIcon icon={faEye} />
        </Box>
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="medium"
            fontAlign="center"
            marginBottom="large"
          >
            Views
          </Text>
        </Box>
        <Box
          color="bodyFontLightColor"
          marginLeft="medium"
          width="auto"
          marginRight="auto"
          onClick={launchCreateServiceJobViewModal}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Box>
      </Box>
      <Box flexDirection="column" alignItems="center" lineHeight="1.5">
        {customViewState.map((view) =>
          <View key={view.id} view={view} updateCustomView={updateCustomView} />
        )}
      </Box>
    </ListItem>
  )
}

CustomView.propTypes = {
  setJobState: PropTypes.func,
}

export default CustomView
