/* eslint-disable max-len */
import React from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box,
  Button,
  Columns,
  ModalContext,
  Text,
} from '@campaignhub/suit-theme'

import useServiceJobUser from '@hooks/useServiceJobUser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBorderAll } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import CreateServiceJobViewModal from '@modals/CreateServiceJobViewModal'
import EditServiceJobViewModal from '@modals/EditServiceJobViewModal'
import Services from './components/Services'
import Tools from './components/Tools'
import Statistics from './components/Statistics'
import Filters from './components/Filters'

const createFilter = (filterParams, createFn, setState) => {
  createFn(filterParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateServiceJobViewModal: false })
  })
}

const updateFilter = (filter, updateFn, setState) => {
  updateFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
  })
}

const deleteFilter = (filter, deleteFn, setState) => {
  deleteFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
    toast.success('View Deleted')
  })
}

const assignServiceJobUser = (serviceJobUserParams, jobAssigned, createFn) => {
  if (jobAssigned){
    toast.success('Assigned Job')
    window.location.href = `#/service-jobs/${jobAssigned}`
    return
  }

  if (serviceJobUserParams){
    createFn(serviceJobUserParams).then(({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warning(errors[0])
        return
      }

      if (success){
        toast.success('Job Taken')
        window.location.href = redirectUrl
      }
    })
  }
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CreateServiceJobViewModal: {
      closeModal: () => setState({ showCreateServiceJobViewModal: false }),
      createFilter: (filterParams, createFn) => createFilter(filterParams, createFn, setState),
    },
    EditServiceJobViewModal: {
      closeModal: () => setState({ showEditServiceJobViewModal: false }),
      deleteFilter: (filter, deleteFn) => deleteFilter(filter, deleteFn, setState),
      updateFilter: (filter, updateFn) => updateFilter(filter, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  activeTabBarItemKey: {
    key: 'jobs',
    filterStatuses: [],
    takeStatuses: [],
    takeRole: [],
  },
  clientFilter: [],
  dateFilter: {
    filterEnd: '',
    filterStart: '',
  },
  expandedFilter: false,
  expandedTools: true,
  serviceFilter: [],
  jobAssigned: null,
  setTotal: 0,
  showCreateServiceJobViewModal: false,
  showEditServiceJobViewModal: false,
  statusFilter: [],
  userFilter: [],
  userToAssign: null,
  assigned: false,
}

const ServiceJobs = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    activeTabBarItemKey, clientFilter, dateFilter, expandedFilter, expandedTools, jobAssigned, serviceFilter, setTotal, showCreateServiceJobViewModal, showEditServiceJobViewModal, statusFilter, userFilter, userToAssign,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = {
    callbacks: {
      showCreateServiceJobViewModal: (payload) => {
        setModalData('CreateServiceJobViewModal', payload)
        setState({ showCreateServiceJobViewModal: true })
      },
      showEditServiceJobViewModal: (payload) => {
        setModalData('EditServiceJobViewModal', payload)
        setState({ showEditServiceJobViewModal: true })
      },
    },
  }

  const serviceJobUserPayload = useServiceJobUser()
  const {
    callbacks: { assignServiceJobUser: createFn },
  } = serviceJobUserPayload

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        {expandedFilter && <Filters setJobState={setState} jobState={state} />}
        <Box paddingX="large" paddingTop={[112, 25]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main width="100">
              <Box
                flexDirection="row"
                paddingBottom="medium"
                alignItems="center"
              >
                <Text fontSize="medium">
                  {setTotal}{setTotal > 1 ? ' Jobs' : ' Job'}
                </Text>
                <Box alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
                  {activeTabBarItemKey.key !== 'jobs' && (jobAssigned || userToAssign !== null)
                    && (
                    <Button
                      buttonStyle="secondaryUtility"
                      size="medium"
                      onClick={() => assignServiceJobUser(userToAssign, jobAssigned, createFn)}
                      width="small"
                      height="40px"
                    >
                      Take a {activeTabBarItemKey.key === 'qc' ? 'QC' : 'Rejection'} Job
                    </Button>
                  )}
                </Box>
                <Box alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
                  <Button
                    buttonStyle="secondaryUtility"
                    size="medium"
                    onClick={() => setState({ expandedTools: !expandedTools })}
                    width="small"
                  >
                    <span><FontAwesomeIcon icon={faBorderAll} /></span>
                  </Button>
                </Box>
              </Box>
              <Services setJobState={setState} jobState={state} />
            </Columns.Main>
            {expandedTools
              && (
              <Columns.Sidebar>
                <Tools setJobState={setState} jobState={state} />
                <Statistics />
              </Columns.Sidebar>
            )}
          </Columns>
        </Box>
        <CreateServiceJobViewModal
          callbacks={callbacks('CreateServiceJobViewModal', props, setState)}
          showModal={showCreateServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />

        <EditServiceJobViewModal
          callbacks={callbacks('EditServiceJobViewModal', props, setState)}
          showModal={showEditServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ServiceJobs
