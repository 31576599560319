import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import * as bookingActions from '@redux/modules/booking'
import * as serviceJobActions from '@redux/modules/serviceJob'

const watchEntityKeys = ['serviceJobs']

const updateServiceJob = (serviceJob, dispatch) => {
  const { updateServiceJob: updateFn } = serviceJobActions

  return dispatch(updateFn(serviceJob))
}

const createBookings = (bookings, dispatch, requestOptions) => {
  const { createBookings: createFn } = bookingActions
  return dispatch(createFn(bookings, requestOptions))
}

const deleteBooking = (booking, dispatch, requestOptions) => {
  const { deleteBooking: deleteFn } = bookingActions
  return dispatch(deleteFn(booking, requestOptions))
}

const useServiceJobsV2 = (initServiceJob = {}) => {
  const { campaignId } = initServiceJob || {}
  const {
    updatedEntities: { serviceJobs: serviceJobsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobs } = entities

  const campaignServiceJobs = useMemo(() => {
    const filtered = Object.values(serviceJobs).filter(x => x.campaign.id === campaignId).sort((a, b) => a.serviceGroupId - b.serviceGroupId || a.id - b.id)

    return filtered
  }, [serviceJobsUpdatedAt, campaignId])

  const { loading } = useSelector(reduxState => reduxState.serviceJobs)

  return {
    callbacks: {
      createBookings: (bookings, requestOptions) => createBookings(bookings, dispatch, requestOptions),
      deleteBooking: (bookings, requestOptions) => deleteBooking(bookings, dispatch, requestOptions),
      updateServiceJob: serviceJob => updateServiceJob(serviceJob, dispatch),
    },
    campaignServiceJobs,
    loading,
    serviceJobs,
  }
}

export default useServiceJobsV2
