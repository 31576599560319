import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useServiceJobUserRoles from '@hooks/useServiceJobUserRoles'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useUsers from '@hooks/useUsers'
import { useServiceJobUserForm } from '@hooks/useServiceJobUser'

const assignOrUpdateServiceJobUser = (entityState, props, state, createFn, updateFn) => {
  const { callbacks: { assignServiceJobUser, updateServiceJobUser } } = props
  const { serviceUserRoleId, userId } = state

  entityState.serviceJobUserRoleId = serviceUserRoleId
  entityState.userId = userId

  if (entityState.id) return updateServiceJobUser(entityState, updateFn)

  assignServiceJobUser(entityState, createFn)
}

const defaultState = {
  isRoleIdValid: true,
  isUserIdValid: true,
  serviceUserRoleId: 0,
  userId: 0
}

const ChangeJobAssignmentModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { isRoleIdValid, isUserIdValid, serviceUserRoleId, userId } = state

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const serviceJobUserPayload = digObject(modalData, `${modalKey}`, {})
  const {
    serviceJobUser,
    callbacks: {
      assignServiceJobUser: createFn,
      updateServiceJobUser: updateFn
    },
  } = serviceJobUserPayload

  const serviceJobUseFormPayload = useServiceJobUserForm(serviceJobUser)

  const { creating, updating } = useSelector(reduxState => reduxState.serviceJobUsers)

  const {
    entityState,
    entityState: {
      user,
      serviceJobUserRole
    },
    errors,
    handlers,
    saveEnabled,
  } = serviceJobUseFormPayload

  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJobUser.serviceJobId)
  const { serviceJobUserRoles } = useServiceJobUserRoles()
  const { users } = useUsers()

  useEffect(() => {
    setState({
      serviceUserRoleId: serviceJobUserRole.id,
      userId: user.id
    })
  }, [entityState])

  const validateServiceJobUser = () => {
    setState({
      isRoleIdValid: serviceUserRoleId > 0,
      isUserIdValid: userId > 0
    })

    if (isRoleIdValid && isUserIdValid) {
      const duplicate = filteredServiceJobUsers.find(x => x.serviceJobUserRole.id == serviceUserRoleId && x.user.id == userId && (x.id != serviceJobUser.id && x.id > 0))

      if (duplicate)
        return toast.warning("User has already been assigned to the role")

      assignOrUpdateServiceJobUser(entityState, props, state, createFn, updateFn)
    }
  }

  const isEditMode = !!serviceJobUser.id

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={isEditMode ? 'Change Assignment' : 'Assign User'}
      />
      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField direction="column" label="User" marginTop="large" errorMessage={isUserIdValid ? '' : 'Required'}>
            <select
              name="userId"
              value={userId}
              {...handlers}
              data-validate-field-on="change"
              disabled={isEditMode}
              onChange={(e) => setState({ userId: e.target.value })}
            >
              <option value={0}>Please Select...</option>
              {Object.values(users).map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </select>
          </FormField>
          <FormField direction="column" label="Role" marginTop="large" errorMessage={isRoleIdValid ? '' : 'Required'}>
            <select
              name="serviceJobUserRoleId"
              value={serviceUserRoleId}
              {...handlers}
              data-validate-field-on="change"
              onChange={(e) => setState({ serviceUserRoleId: e.target.value })}
            >
              <option value={0}>Please Select...</option>
              {Object.values(serviceJobUserRoles).map((serviceJobUserRole) => (
                <option key={serviceJobUserRole.id} value={serviceJobUserRole.id}>
                  {serviceJobUserRole.description}
                </option>
              ))}
            </select>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => validateServiceJobUser()}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal >
  )
}

ChangeJobAssignmentModal.defaultProps = {
  modalKey: 'ChangeJobAssignmentModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ChangeJobAssignmentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
