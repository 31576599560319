import { useSelector } from 'react-redux'

import { useLatestEntity } from '@campaignhub/react-hooks'

const useCampaignV2 = (initCampaign = {}) => {
  const { entity: campaign } = useLatestEntity(initCampaign, 'campaigns')

  const { loading, loaded } = useSelector(reduxState => reduxState.campaigns)

  return {
    campaign,
    loading,
    loaded,
  }
}

export default useCampaignV2
