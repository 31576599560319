import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Overview from './screens/Overview'

const DashboardRoutes = () => (
  <Routes>
    <Route path="/*" element={<Overview />} />
  </Routes>
)

export default DashboardRoutes
