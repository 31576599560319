const state = {
  id: null,
  type: '',
  name: '',
  client: {},
  area: {},
  property: {},
  campaignAgents: [],
  invitees: [],
}

export const requiredFields = []

export default state
