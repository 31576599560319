/* eslint-disable max-len */
import { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'
import useCurrentUser from '@hooks/useCurrentUser'
import api from '@functions/api'
import axios from 'axios'

const defaultState = {
  error: false,
  hasMore: false,
  jobs: [],
  loading: true,
  totalJobs: 0,
}

const useInfiniteJobScroll = (pageNumber, jobState, setJobState) => {
  const {
    activeTabBarItemKey, clientFilter, dateFilter, serviceFilter, statusFilter, userFilter,
  } = jobState

  const [state, setState] = useSetState(defaultState)
  const {
    error, hasMore, jobs, loading, totalJobs,
  } = state

  const { currentUser } = useCurrentUser()

  const options = new URLSearchParams([
    ['includes', 'campaign'],
    ['includes', 'client'],
    ['includes', 'service'],
    ['includes', 'status'],
    ['includes', 'users'],
    ['pageNumber', pageNumber],
    ['pageSize', 20],
  ])

  const takeAServiceJob = (activeTab, serviceJobs) => {
    setJobState({ jobAssigned: null })

    if (activeTab.key === 'jobs' || serviceJobs.length === 0) return

    const serviceJobUserRoleId = activeTab.key === 'qc' ? 2 : 3
    let takeJob = serviceJobs.find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id)
      && serviceJob.users.find(serviceJobUser => serviceJobUser.serviceJobUserRoleId === serviceJobUserRoleId && serviceJobUser.user.id === currentUser.id))

    if (!takeJob){
      takeJob = serviceJobs.find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id))

      setJobState({
        userToAssign: {
          serviceJobId: takeJob.id,
          userId: currentUser.id,
          serviceJobUserRoleId,
        },
      })
      return
    }

    if (takeJob) setJobState({ jobAssigned: takeJob.id })
  }

  useEffect(() => {
    setState({
      error: false,
      loading: true,
    })

    options.append('filterStart', dateFilter.filterStart)
    options.append('filterEnd', dateFilter.filterEnd)

    clientFilter.map(client => options.append('clients', client))

    serviceFilter.map(service => options.append('services', service))

    const serviceJobStatuses = activeTabBarItemKey.filterStatuses.length ? activeTabBarItemKey.filterStatuses : statusFilter
    serviceJobStatuses.map(status => options.append('serviceJobStatuses', status))

    userFilter.map(user => options.append('users', user))

    if (activeTabBarItemKey.key === 'rejection'){
      // ToDo: change to rejection in API before changing this
      options.append('includes', 'revisionDate')
      options.append('orderBy', 'revisionDate')
    }

    options.append('orderBy', 'studioDeadline')

    let cancel
    api('/v1/service-jobs', options, {
      cancelToken: new axios.CancelToken(((c) => { cancel = c })),
    }).then((res) => {
      const additionalInfo = JSON.parse(res.headers['x-additional-info'])
      setState({ totalJobs: additionalInfo.TotalCount })

      if (pageNumber > 1){
        setState({
          jobs: [...jobs, ...res.data.map(job => job)],
        })
      } else {
        setState({ jobs: res.data })
        takeAServiceJob(activeTabBarItemKey, res.data)
      }

      setState({
        hasMore: res.data.length > 0,
        loading: false,
      })
    }).catch((e) => {
      if (axios.isCancel(e)) return
      setState({
        error: true,
      })
    })
    return () => cancel()
  }, [activeTabBarItemKey, clientFilter, dateFilter, pageNumber, serviceFilter, statusFilter, userFilter])

  return {
    loading, error, jobs, hasMore, totalJobs,
  }
}

export default useInfiniteJobScroll
