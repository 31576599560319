import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

const NotificationBadge = (props) => {
  const {
    badge, badgeProps, color, onClick, disabled, disableNote, parent, showBadge, style,
  } = props

  return (
    <Box
      onClick={disabled ? null : onClick}
      position="relative"
      style={style || { cursor: disabled ? 'cursor' : 'pointer' }}
      width="auto"
      title={disabled ? disableNote : null}
    >
      {parent}

      {showBadge && (
        <Box
          alignItems="center"
          backgroundColor={color || 'blue'}
          borderRadius="1rem"
          justifyContent="center"
          padding="3px"
          position="absolute"
          width="auto"
          {...badgeProps}
        >
          {badge}
        </Box>
      )}
    </Box>
  )
}

NotificationBadge.propTypes = {
  badge: PropTypes.node.isRequired,
  badgeProps: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  disableNote: PropTypes.string,
  onClick: PropTypes.func,
  parent: PropTypes.node.isRequired,
  showBadge: PropTypes.bool,
  style: PropTypes.object,
}

export default NotificationBadge
