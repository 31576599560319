import React from 'react'

import {
  Box,
  Columns,
  DashboardModule,
  PageHeader,
  Text
} from '@campaignhub/suit-theme'

import Tools from './components/Tools'
import UserList from './components/UserList'

const Users = () => {
  return (
    <>
      <PageHeader
        activeTabBarItemKey='users'
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title='User Management'
      />

      <Box paddingX='large' paddingTop={[112, 105]}>
        <Columns
          boxProps={{ marginTop: 'large' }}
          flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <UserList />
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title='Tools'>
              <Tools />
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Users
