import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Heading, Image, Text,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from '@components/assets/logo-mark.svg'

const InvalidLink = (props) => {
  const { heading, content } = props

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '435px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />
        <Heading size="xlarge" weight="strong" textProps={{ color: 'bodyFontLightColor', paddingY: 'medium' }}>
          {heading}
        </Heading>
        <Text color="bodyFontLightColor" textAlign="center" fontSize="small" lineHeight="1.3">
          {content}
        </Text>
      </Box>
    </Box>
  )
}

InvalidLink.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default InvalidLink
