import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import Icons from '@components/Icons'

const DraggableMedia = React.forwardRef((props, ref) => {
  const {
    callbacks: { toggleItem },
    content,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    index,
    selected,
  } = props

  const { file } = content

  const { isDragging } = dragSnapshot || {}

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <Box marginBottom="large">
      <ListItem
        boxProps={{
          alignItems: 'center',
          border: '1px solid',
          borderColor: 'lineColor',
          borderRadius: 5,
          padding: 'large',
        }}
        forceBottomBorder
        forwardProps={{
          ...draggableProps,
          ...dragHandleProps,
        }}
        isDragging={isDragging}
        key={content.id}
        ref={ref}
      >
        <Checkbox checked={selected} onClick={() => toggleItem(content.id)} />
        <Link href={`${content.resourceUrl}?view=true`} target="_blank">
          <Image
            borderRadius={5}
            boxProps={{ backgroundColor: 'hoverLightGrey', marginRight: 'large' }}
            height={60}
            url={content.resourcePreviewUrl}
            width={90}
          >
            <Box height="100%" position="relative">
              {!content.resourcePreviewUrl && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={30} width="100%" />
                </Box>
              )}
              <Box
                alignItems="center"
                backgroundColor="bodyFontColor"
                borderRadius={5}
                color="white"
                fontSize="xsmall"
                height="20px"
                justifyContent="center"
                left={0}
                position="absolute"
                bottom={0}
                width="20px"
              >
                {index + 1}
              </Box>
            </Box>
          </Image>
        </Link>

        <Box justifyContent="flex-start" flexDirection="column" minWidth="0" paddingRight="medium" width="auto">
          <Text fontSize="small" variant="ellipsis">
            {file.originalName}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {file.fileType.name} | Date Uploaded: {DateTime.fromISO(file.createdAt).toFormat('LL/dd/yyyy')}
          </Text>
        </Box>
      </ListItem>
    </Box>
  )
})

DraggableMedia.propTypes = {
  callbacks: PropTypes.object,
  content: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default DraggableMedia
