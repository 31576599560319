import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useSetState } from '@campaignhub/react-hooks'
import {
 Box, Columns, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useNumericParams from '@hooks/useNumericParams'

import Status from '@sections/Client/components/Bookings/Status'

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

const defaultState = {
  campaignNotFound: false,
}

const CalendarRedirect = () => {
  const [state, setState] = useSetState(defaultState)
  const { campaignNotFound } = state

  const { id: campaignId } = useNumericParams()
  const { platform } = useParams()

  const entityReducer = useSelector(reduxState => reduxState.realhubCampaigns)
  const {
    result: realhubCampaigns, errors, loaded, loading,
  } = entityReducer

  useReduxAction('realhubCampaigns', 'loadRealhubCampaign', {}, [campaignId], {
    dispatchAction: (action, requestOptions) => action(campaignId, requestOptions),
    shouldPerformFn: () => !loaded && !loading && !errors.length,
  })

  useEffect(() => {
    if (platform.toLowerCase() === 'realhub' && !errors.length){
      if (realhubCampaigns.id === campaignId){
        window.location.hash = `/campaigns/${realhubCampaigns.chCampaignId}/bookings`
      }
    } else {
      setState({ campaignNotFound: true })
    }
  }, [campaignId, realhubCampaigns, errors])

  return (
    <Box paddingX="large">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <Box flexDirection="column">
            {!campaignNotFound && <LoadingModule loading={campaignNotFound} />}
            {campaignNotFound && (
            <Status
              icon={faTimesCircle}
              iconColor="red"
              message={(
                <Box flexDirection="column" fontSize="small">
                  <Text color="bodyFontLightColor">
                    Bookings for this campaign cannot be found.
                  </Text>
                </Box>
              )}
              title="Campaign not found!"
            />
          )}
          </Box>
        </Columns.Main>
      </Columns>
    </Box>
  )
}

export default CalendarRedirect
