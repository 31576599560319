import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Text } from '@campaignhub/suit-theme'

import Icons from '@components/Icons'

const Header = React.memo((props) => {
  const { calendarServiceGroups, dates } = props

  return (
    <Grid
      backgroundColor="white"
      gridArea="header"
      position="sticky"
      top="0"
      zIndex="5"
    >
      <Grid
        gridColumnGap="large"
        gridTemplateColumns={`repeat(${dates.length}, minmax(${calendarServiceGroups.length > 3 ? calendarServiceGroups.length * 50 : 180}px, 1fr))`}
      >
        {dates.map(date => (
          <Grid textAlign="center">
            <Text
              fontSize="small"
              padding="large"
              style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
            >
              {date.toFormat('ccc LLL dd, y').toUpperCase()}
            </Text>
            <Grid gridTemplateColumns={`repeat(${calendarServiceGroups.length}, 1fr)`}>
              {calendarServiceGroups?.map(serviceGroup => (
                <Grid
                  justifyContent="center"
                  padding="13px"
                  style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                  textAlign="center"
                >
                  <Icons name={serviceGroup.name} size={20} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
})

Header.propTypes = {
  calendarServiceGroups: PropTypes.array,
  dates: PropTypes.array,
}

export default Header
