import React from 'react'
import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import Admin from '.'
import Dashboard from './packs/Dashboard'
import SystemManager from './packs/SystemManager'

const AdminRoutes = () => (
  <Router>
    <Admin>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/systemManager/*" element={<SystemManager />} />
      </Routes>
    </Admin>
  </Router>
)

export default AdminRoutes
