import React from 'react'
import { useSelector } from 'react-redux'

import {
  getQueryParamsFromHash, parsePermittedQueryParams,
} from '@campaignhub/javascript-utils'
import {
  Box, Button, Columns, DashboardModule, ListItem, LoadingModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft, faCalendar, faFileCheck,
} from '@fortawesome/pro-light-svg-icons'
import blankState from '@components/assets/blankState.svg'
import useReduxAction from '@hooks/useReduxAction'

const Overview = () => {
  useReduxAction('proofingOverview', 'loadProofingsOverviewV2', {})

  const entityReducer = useSelector(reduxState => reduxState.proofingOverview)
  const { result: proofingOverview, loading: overviewLoading } = entityReducer
  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'redirect',
  ])

  const goBack = () => {
    if (redirect){
      window.location.href = redirect
      return
    }

    window.location.hash = ''
  }

  return (
    <>
      <PageHeader
        actionContent={redirect ? (
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
            onClick={() => goBack()}
            size="medium"
          >
            Back
          </Button>
        ) : null}
        activeTabBarItemKey="proofing"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
        {
          href: '#/overviews/bookings',
          icon: faCalendar,
          key: 'bookings',
          title: 'BOOKINGS',
        },
        {
          href: '#/overviews/proofing',
          icon: faFileCheck,
          key: 'proofing',
          title: 'PROOFING',
        },
      ]}
        title="Overview"
        invertColors
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!overviewLoading ? (
              <DashboardModule title="Proofing">
                <Box flexDirection="column" lineHeight="1.3">
                  {Object.values(proofingOverview).map(x => (
                    <ListItem
                      boxProps={{
                        borderBottom: '1px solid',
                        padding: 'large',
                        as: 'a',
                      }}
                      flexDirection="row"
                      showAngleIcon
                      href={`#/overviews/proofing/${x.campaignId}`}
                    >
                      <Box flexDirection="column" justifyContent="center">
                        <Text fontSize="small">{x.name}</Text>
                      </Box>
                      <Box
                        flexDirection="row"
                        justifyContent="center"
                        width="auto"
                      >
                        <Text fontSize="xsmall" color="bodyFontLightColor">Proof</Text>
                      </Box>
                    </ListItem>
                  ))}
                  {!overviewLoading && Object.values(proofingOverview).length <= 0 ? (
                    <DashboardModule.BlankState imageUrl={blankState}>
                      <DashboardModule.BlankState.Title>No Campaigns for Proofing</DashboardModule.BlankState.Title>
                      <DashboardModule.BlankState.Paragraph>
                        All jobs ready for proofing should show here.
                      </DashboardModule.BlankState.Paragraph>
                    </DashboardModule.BlankState>
                ) : null}
                </Box>
              </DashboardModule>
            ) : null}
            <LoadingModule loading={overviewLoading} />
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

export default Overview
