const state = {
  id: null,
  permissionLevel: 0,
  firstName: '',
  lastName: '',
  userRoleId: 0,
  timeZone: '',
  workShift: '',
  services: []
}

export const requiredFields = [
  { key: 'userRoleId' }
]

export default state