import React from 'react'
import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import Production from '.'
import Dashboard from './packs/Dashboard'

const ProductionRoutes = () => (
  <Router>
    <Production>
      <Routes>
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </Production>
  </Router>
)

export default ProductionRoutes
