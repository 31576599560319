import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { ThemeProvider } from '@emotion/react'
import { useAuth0 } from '@auth0/auth0-react'
import { HelmetProvider } from 'react-helmet-async'

import { theme } from '@campaignhub/suit-theme'

import { getShareLinkTokenFromUrl } from '@functions/url'

import '@campaignhub/suit-theme/dist/styles.css'
import '@styles/reset.css'

import AuthController from '@sections/Auth/AuthController'

import createRootReducer from '@redux/rootReducer'
import lastModifiedMiddleware from '@redux/middleware/lastModified'
import { setAccessToken } from '@functions/accessToken'

const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware => getDefaultMiddleware({ immutableCheck: false }).concat(lastModifiedMiddleware),
})

const urlParams = new URLSearchParams(window.location.search)

function App(){
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0()

  const shareLinkToken = getShareLinkTokenFromUrl(window.location.pathname)

  useEffect(() => {
    // If these are present in the queryString, it is intended to login as that user but it hasn't
    // redirected after Auth0Provider handler, possibly already logged in as a different user. We logout
    // to make sure we are logged in as the correct user and trigger handleRedirect as intended.
    if (urlParams.get('username') && urlParams.get('otpToken')){
      logout({ returnTo: window.location.origin })
    }

    if (isAuthenticated && !shareLinkToken){
      setAccessToken(getAccessTokenSilently)
    }
  }, [getAccessTokenSilently, isAuthenticated, shareLinkToken])

  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthController shareLinkToken={shareLinkToken} />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  )
}

export default App
