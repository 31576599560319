/* eslint-disable prefer-destructuring */
import { useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import * as shareLinkActions from '@redux/modules/shareLink'

const checkTokenValid = async (shareLinkToken, setState, dispatch) => {
  const { validateShareLinkToken } = shareLinkActions

  let version = 'v2'
  let token = shareLinkToken

  const matches = token.match(/([v||V][1||2])\/([\w\d_-]+)\/?/)
  if (matches){
    version = matches[1]
    token = matches[2]
  }

  try {
    const { data } = await dispatch(validateShareLinkToken(token, version))

    setState({
      tokenChecked: true,
      tokenKey: token,
      isValid: digObject(data, 'valid', false),
      shareType: digObject(data, 'shareType', false),
      shareLink: digObject(data, 'entity', {}),
    })
  } catch (error){
    setState({
      tokenChecked: true,
      isValid: false,
    })
  }
}

const defaultState = {
  shareLink: {},
  shareType: null,
  isValid: false,
  tokenChecked: false,
}

function useValidShareLink(token){
  const [state, setState] = useSetState(defaultState)
  const {
    isValid, shareLink, shareType, tokenChecked, tokenKey,
  } = state

  const dispatch = useThunkDispatch()

  useEffect(() => {
    checkTokenValid(token, setState, dispatch)
  }, [token])

  return {
    isValid,
    shareLink,
    shareType,
    tokenChecked,
    tokenKey,
  }
}

export default useValidShareLink
