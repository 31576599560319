import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/serviceJob/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/serviceJob/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/serviceJob/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/serviceJob/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/serviceJob/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/serviceJob/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function loadServiceJobs(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().serviceJobs.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/v1/service-jobs/', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadServiceJobsV2(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().serviceJobs.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/v2/service-jobs/', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadServiceJob(serviceJobId, options, dispatch, loadedIds){
  if (loadedIds) dispatch(fetchRequest({ loadedIds }))
  else dispatch(fetchRequest())

  const promise = api(`/v1/service-jobs/${serviceJobId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
}

export function getServiceJob(serviceJobId, options){
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.serviceJobs.loadedIds]

    if (serviceJobId && !loadedIds.includes(serviceJobId)){
      loadedIds.push(serviceJobId)
    }

    return loadServiceJob(serviceJobId, options, dispatch, loadedIds)
  }
}

export function loadServiceJobCounts(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/service-jobs/counts', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.serviceGroups, Schemas.SERVICE_GROUP_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        const normalizedJson2 = normalize(data.serviceJobStatuses, Schemas.SERVICE_JOB_STATUS_ARRAY)
        dispatch(updateEntities(normalizedJson2))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function assignServiceJobStatus(serviceJob, options, serviceJobOptions, reloadServiceJob){
  const config = {
    method: 'POST',
    data: serviceJob,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v1/service-jobs/${serviceJob.id}/workflow`, options, config)
      .then(() => {
        dispatch(updateSuccess())
        if (reloadServiceJob){
          return loadServiceJob(serviceJob.id, serviceJobOptions, dispatch)
        }
        return { success: true, serviceJob }
      })
      .catch((data) => {
        dispatch(updateFailure())

        return { success: false, data }
      })
    return promise
  }
}

export function updateServiceJob(serviceJob){
  return (dispatch) => {
    const normalizedJson = normalize(serviceJob, Schemas.SERVICE_JOB)
    dispatch(updateEntities(normalizedJson))
    dispatch(updateSuccess())

    return { success: true }
  }
}

export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: action.payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
