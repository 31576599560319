import React from 'react'
import useCurrentUser from '@hooks/useCurrentUser'

import userTypes from '@functions/userTypes'
import AdminRoutes from '@sections/Admin/routes'
import ProductionRoutes from '@sections/Production/routes'
import ClientRoutes from '@sections/Client/routes'
import DefaultRoutes from '@sections/Default/routes'
import Login from '@sections/Auth/Login'

const AuthorizationController = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser){
    return <Login />
  }

  if (currentUser.userRole.userTypeId === userTypes.find(x => x.name === 'BackOffice').id){
    return <AdminRoutes />
  }

  if (currentUser.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id){
    return <ProductionRoutes />
  }

  if (currentUser.userRole.userTypeId === userTypes.find(x => x.name === 'Client').id){
    return <ClientRoutes />
  }

  return <DefaultRoutes />
}

export default AuthorizationController
