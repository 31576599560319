import React from 'react'
import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

import {
  Box,
  DashboardModule
} from '@campaignhub/suit-theme'

import Summary from './components/Summary'

const Statistics = () => {
  const entities = useSelector(reduxState => reduxState.entities)
  const { statistics } = entities

  useReduxAction('statistics', 'loadStatistics', {})

  return (
    <DashboardModule title="Statistics">
      <Box flexDirection="column">
        {Object.values(statistics).map((summary) => (
          <Summary key={summary.id} summary={summary} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default Statistics