import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, Text,
} from '@campaignhub/suit-theme'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'
import CopyComment from '../CopyComment'

const defaultState = {
  commentOnChange: '',
  contentOnChange: '',
  showComments: false,
}

const TextAreaComponent = (props) => {
  const { copyProps } = props

  const [state, setState] = useSetState(defaultState)
  const { commentOnChange, contentOnChange, showComments } = state

  useEffect(() => {
    setState({ contentOnChange: copyProps?.body })
  }, [copyProps])

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showComments })

  useEffect(() => {
    if (isClickedOutside && showComments){
      setState({ showComments: !showComments })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField label={copyProps.label} boxProps={{ marginBottom: 'medium' }}>
      <Box flexDirection="column" position="relative" marginBottom="small">
        <textarea
          onChange={e => setState({ contentOnChange: e.target.value })}
          style={{ height: 120, paddingRight: '33px', resize: 'vertical' }}
          type="text"
          value={contentOnChange}
        />
        <Box className={custom.root} ref={contentEl} position="absolute" top="3px" right="3px" width="auto">
          <Box padding="medium" marginBottom="auto" onClick={() => setState({ showComments: !showComments })}>
            {copyProps.comments.length > 0 && (
              <Text marginRight="small" color="red" fontSize="small" width="auto">
                {copyProps.comments.length}
              </Text>
            )}
            <Box position="relative" style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCommentAltLines} />
              {copyProps.comments.length > 0 && (
                <Box
                  alignItems="center"
                  backgroundColor="red"
                  borderRadius="1rem"
                  justifyContent="center"
                  padding="4px"
                  position="absolute"
                  width="auto"
                  right={-1}
                  top={-1}
                />
              )}
            </Box>
          </Box>
          {showComments && (
            <Box className={custom.popup} width="350px" right="0" zIndex="4!important">
              <Box flexDirection="column" lineHeight="1.5" maxHeight="280px" overflowY="auto">
                {copyProps.comments.map(comment => (
                  <CopyComment comment={comment} />
                ))}
              </Box>
              <Box flexDirection="column" disableHover>
                <FormField boxProps={{ marginBottom: 'medium', marginTop: 'medium' }}>
                  <textarea
                    onChange={e => setState({ commentOnChange: e.target.value })}
                    placeholder="Enter message here..."
                    style={{ height: 80, resize: 'vertical' }}
                    type="text"
                    value={commentOnChange}
                  />
                </FormField>
                <Box alignItems="end" flexDirection="row">
                  <Button
                    buttonStyle="secondaryUtility"
                    marginLeft="auto"
                    size="medium"
                    width="auto"
                    onClick={() => setState({ showComments: !showComments })}
                  >
                    Cancel
                  </Button>
                  <Button
                    buttonStyle="primaryCreate"
                    marginLeft="medium"
                    size="medium"
                    width="auto"
                  >
                    Save As
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box flexDirection="column" alignItems="flexEnd">
        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium" marginLeft="auto">
          Actual {contentOnChange.length}, Suggested {copyProps.suggested}
        </Text>
      </Box>
    </FormField>
  )
}

TextAreaComponent.propTypes = {
  copyProps: PropTypes.object,
}

export default TextAreaComponent
