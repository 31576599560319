import React, { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'
import PropTypes from 'prop-types'

import {
  Box, SortableList,
} from '@campaignhub/suit-theme'

import { toggleArray } from '@campaignhub/javascript-utils'

import DraggableMedia from './components/DraggableMedia'

const defaultState = {
  currentlySelectedIds: [],
}

const ListView = (props) => {
  const { callbacks: { toggleUpdateSelectedIds }, contents, selectedIds } = props

  const [state, setState] = useSetState(defaultState)
  const { currentlySelectedIds } = state

  const selectContent = (content) => {
    setState({
      currentlySelectedIds: toggleArray((selectedIds.includes(content.id)) ? selectedIds
      : selectedIds.filter(id => id !== content.id), content.id),
    })
  }

  useEffect(() => {
    toggleUpdateSelectedIds(currentlySelectedIds)
  }, [currentlySelectedIds])

  return (
    <SortableList
      callbacks={{ onDragEnd: (fromIndex, toIndex) => console.log(fromIndex, toIndex) }}
      droppableId="ResourcesListView"
    >
      <Box flexDirection="column">
        <SortableList.Dropzone droppableId="sortedImageIds">
          <Box flexDirection="column">
            {contents.map((content, index) => {
              const selected = selectedIds.includes(content.id)

              return (
                <SortableList.Item draggableId={`Image-${content.id}`} key={content.id} index={index}>
                  <DraggableMedia
                    callbacks={{ toggleItem: () => selectContent(content) }}
                    content={content}
                    index={index}
                    key={content.id}
                    selected={selected}
                  />
                </SortableList.Item>
              )
            })}
          </Box>
        </SortableList.Dropzone>
      </Box>
    </SortableList>
  )
}

ListView.propTypes = {
  callbacks: PropTypes.object,
  contents: PropTypes.array,
  selectedIds: PropTypes.array,
}

export default ListView
