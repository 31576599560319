import React from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { MainContent, ModalContext } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/TopBar/components/LoggedInUser'
import Search from '@components/TopBar/components/Search'
import TimeTracker from '@components/TopBar/components/TimeTracker'
import TopBar from '@components/TopBar'

import MainNavigation from '@sections/Admin/components/MainNavigation'

import PageContext from '@contexts/pageContext'
import EditUserModal from '@modals/EditUserModal'
import SystemManagerRoutes from './routes'

const assignServicesToUser = (userParams, createFn, setState) => {
  createFn(userParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showEditUserModal: false })
  })
}

const updateUser = (userParams, updateFn, setState) => {
  updateFn(userParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showEditUserModal: false })
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false }),
      assignServicesToUser: (userParams, createFn) => assignServicesToUser(userParams, createFn, setState),
      updateUser: (userParams, updateFn) => updateUser(userParams, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showEditUserModal: false,
}

const SystemManager = (props) => {
  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const [state, setState] = useSetState(defaultState)
  const { showEditUserModal } = state

  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const pageContext = {
    callbacks: {
      showEditUserModal: (payload) => {
        setModalData('EditUserModal', payload)
        setState({ showEditUserModal: true })
      },
    },
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <TopBar
          callbacks={{ toggleNavigation }}
          loggedInUserComponent={<LoggedInUser />}
          timeTrackerComponent={<TimeTracker />}
          searchComponent={<Search callbacks={{ search: null }} />}
        />

        <MainNavigation />

        <MainContent offset={{ top: TopBar.topBarHeight }}>
          <SystemManagerRoutes />
        </MainContent>

        <EditUserModal
          callbacks={callbacks('EditUserModal', props, setState)}
          showModal={showEditUserModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default SystemManager
