/* eslint-disable max-len */
import React, { useContext } from 'react'
import PageContext from '@contexts/pageContext'

import {
  Box,
  Button,
  DashboardModule,
  ListItem,
  Text,
} from '@campaignhub/suit-theme'

import IconProfile from '@components/IconProfile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh } from '@fortawesome/pro-light-svg-icons'

const listingDetails = {
  agencyDescription: 'Agency description goes here',
  agentDetails: [
    {
      imageUrl: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
      name: 'Glennis Schindler',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
      name: 'Peter Motalli',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80',
      name: 'Yannes Yung',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
  ],
}

const ListingDetails = () => {
  const { serviceJob: { client } } = useContext(PageContext)

  return (
    <DashboardModule title="Listing Details">
      <Box flexDirection="column" lineHeight="1.3">
        <ListItem
          boxProps={{
            padding: 'large',
            paddingBottom: 'small',
          }}
          flexDirection="column"
          style={{ cursor: 'pointer' }}
        >
          <Box flexDirection="row">
            <Box flexDirection="column" paddingRight="medium">
              <Text fontSize="small">
                {client?.name}
              </Text>
              <Text color="bodyFontLightColor" fontSize="xsmall">
                {listingDetails.agencyDescription}
              </Text>
            </Box>
            <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
              <Button
                size="medium"
                buttonStyle="primaryEdit"
                icon={<FontAwesomeIcon icon={faTh} />}
              >
                Style Guidelines
              </Button>
            </Box>
          </Box>
        </ListItem>
        {listingDetails.agentDetails.map(agent => (
          <ListItem
            boxProps={{
              padding: 'large',
              paddingTop: 'medium',
            }}
            flexDirection="column"
            key={agent.name} // replace with id
            style={{ cursor: 'pointer' }}
          >
            <IconProfile
              imageUrl={agent.imageUrl}
              name={agent.name}
              contactNo={agent.contactNo}
              email={agent.email}
            />
          </ListItem>
        ))}
      </Box>
    </DashboardModule>
  )
}

export default ListingDetails
