import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUser } from '@fortawesome/pro-light-svg-icons'

import {
 Box, Image, Link, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

const Comment = (props) => {
  const {
    boxProps,
    callbacks,
    comment,
    commentBody,
    commentImages,
    commentLinks,
    commentTime,
    fullName,
    hideImage,
    imageUrl,
    isDeletable,
    isPublic,
    subHeading,
  } = props

  const { deleteCallback } = callbacks || {}

  return (
    <Box
      borderBottom="1px dashed"
      borderColor="lineColor"
      paddingBottom="large"
      marginBottom="large"
      {...boxProps}
    >
      {!hideImage && (
        <UserProfileImage imageUrl={imageUrl} boxProps={{ marginRight: 'large' }}>
          <FontAwesomeIcon icon={faUser} />
        </UserProfileImage>
      )}

      <Box flexDirection="column" paddingRight="medium">
        <Box flexDirection="row">
          <Box flexDirection="column">
            <Text fontSize="xsmall" fontWeight={500}>{fullName}</Text>
            {subHeading && (
              <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="small">
                {subHeading}
              </Text>
            )}
            {isPublic && (
              <Text color="blue" fontSize="xsmall" marginTop="medium">
                Public Comment
              </Text>
            )}
            <Text fontSize="xsmall" marginY="small" lineHeight="1.3">
              {commentBody}
            </Text>
          </Box>
          {isDeletable && (
            <Box marginLeft="auto" width="auto">
              <Link
                greyLink
                onClick={() => deleteCallback(comment)}
                textProps={{ marginLeft: 'medium' }}
                width="fit-content"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Link>
            </Box>
          )}
        </Box>
        {commentImages?.map(image => (
          <Box
            flexDirection="column"
            marginBottom="medium"
            border="1px solid"
            borderColor="lineColor"
            borderRadius={5}
          >
            <Link href={image} target="_blank">
              <Image backgroundPosition="center center" width="100%" height={180} url={image} />
            </Link>
          </Box>
        ))}
        {commentLinks?.map(link => (
          <Link
            disabledUnderline
            href={link}
            target="_blank"
            textProps={{
              fontSize: 'xsmall',
              variant: 'ellipsis',
              width: 280,
            }}
          >
            {link}
          </Link>
        ))}
        <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="small">
          {commentTime}
        </Text>
      </Box>
    </Box>
  )
}

Comment.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    deleteCallback: PropTypes.func,
  }),
  comment: PropTypes.string,
  commentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  commentImages: PropTypes.array,
  commentLinks: PropTypes.array,
  commentTime: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  hideImage: PropTypes.bool,
  image: PropTypes.object,
  imageUrl: PropTypes.string,
  isDeletable: PropTypes.bool,
  isPublic: PropTypes.bool,
  subHeading: PropTypes.string,
}

Comment.defaultProps = {
  hideImage: false,
  isDeletable: false,
}

export default Comment
