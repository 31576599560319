import useUserRoles from '@hooks/useUserRoles'
import useServiceGroups from '@hooks/useServiceGroups'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'

const Production = (props) => {
  const { children } = props

  useUserRoles({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })

  return children
}

export default Production
