import { useState, useRef } from 'react'
import { useSetState } from '@campaignhub/react-hooks'

const defaultState = {
	isActive: false,
	isPaused: false
}

const useTimer = (initialState = 0) => {
	const [timer, setTimer] = useState(initialState)
	const [state, setState] = useSetState(defaultState)
	const { isActive, isPaused } = state
	const countRef = useRef(null)

	const handleStart = () => {
		setState({
			isActive: true,
			isPaused: true
		})
		countRef.current = setInterval(() => {
			setTimer((timer) => timer + 1)
		}, 1000)
	}

	const handlePause = () => {
		clearInterval(countRef.current)
		setState({ isPaused: false })
	}

	const handleStop = () => {
		clearInterval(countRef.current)
		setState({isPaused : false})
		setTimer(0);
	}
	
	const handleResume = () => {
		setState({ isPaused: true })
		countRef.current = setInterval(() => {
			setTimer((timer) => timer + 1)
		}, 1000)
	}

	return { timer,setTimer, handleStart, handlePause, handleResume, handleStop }

}

export default useTimer