import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'

import Assets from './components/Assets'
import Resources from './components/Resources'
import ServiceGroupControls from '../ServiceGroupControls'

const ServiceGroupContent = (props) => {
  const { activeTabBarItemKey, callbacks, selectedIds } = props

  const { serviceJob, workflowUpdated } = useContext(PageContext)

  useReduxAction('assets', 'loadAssets', new URLSearchParams([
    ['serviceJobs', serviceJob.id],
    ['includes', 'comments'],
    ['includes', 'file'],
  ]), [serviceJob.id, workflowUpdated])

  useReduxAction('resources', 'loadResources', new URLSearchParams([
    ['serviceJobs', serviceJob.id],
    ['includes', 'file'],
  ]), [serviceJob])

  const componentMapping = { Assets, Resources }

  const Component = componentMapping[activeTabBarItemKey === 'resources' ? 'Resources' : 'Assets']

  return (
    <>
      <Component
        activeTabBarItemKey={activeTabBarItemKey}
        callbacks={callbacks}
        selectedIds={selectedIds}
      />
      <ServiceGroupControls activeTabBarItemKey={activeTabBarItemKey} selectedIds={selectedIds} />
    </>
  )
}

ServiceGroupContent.propTypes = {
  activeTabBarItemKey: PropTypes.string,
  callbacks: PropTypes.object,
  selectedIds: PropTypes.array,
}

export default ServiceGroupContent
