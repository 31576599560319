import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Text, ListItem } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  statusState: []
}

const ServiceJobStatuses = (props) => {
  const { setJobState, statusFilter } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const [state, setState] = useSetState(defaultState)
  const { expanded, statusState } = state

  useEffect(() => {
    setState({
      statusState: Object.values(serviceJobStatuses).map((status) => ({
        ...status,
        isChecked: statusFilter.includes(status.id)
      }))
    })
  }, [serviceJobStatuses, statusFilter])

  const updateFilterState = (checked, statusId) => {
    setState({
      statusState:
        statusState.map((data) => {
          if (statusId == data.id) {
            data.isChecked = checked
            if (checked)
              setJobState({ statusFilter: [...statusFilter, statusId] })
            else {
              setJobState({ statusFilter: statusFilter.filter(i => i !== data.id) })
            }
          }
          return data
        }
        )
    })
  }

  return (
    <ListItem
      boxProps={{
        padding: "medium",
      }}
      flexDirection="column"
      disableHover
      style={{ cursor: "pointer" }}
    >
      <Box flexDirection="row" onClick={() => setState({ expanded: !expanded })}>
        <Box
          color="bodyFontLightColor"
          marginLeft="auto"
          width="auto"
          marginRight="medium"
        >
          <Icons name='Status' />
        </Box>
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="medium"
            fontAlign="center"
            marginBottom="medium"
          >
            Status
          </Text>
        </Box>
        <Box
          color="bodyFontLightColor"
          marginLeft="medium"
          width="auto"
          marginRight="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {statusState.map(status => (
        expanded && (
          <Box key={status.id} flexDirection="column" alignItems="center" lineHeight="1.4">
            <Box flexDirection="row">
              <Box
                color="bodyFontLightColor"
                marginLeft="auto"
                width="auto"
                marginRight="medium"
              >
                <input
                  onChange={e => updateFilterState(e.target.checked, status.id)}
                  type="checkbox"
                  checked={status.isChecked}
                  className={styles.checkbox}
                />
              </Box>
              <Box flexDirection="column">
                <Text
                  color="bodyFontLightColor"
                  fontSize="small"
                  marginBottom="medium"
                  marginRight="medium"
                >
                  {status.description}
                </Text>
              </Box>
              <Box flexDirection="column" width="auto" >
                <StatusBadge color='gray' text={status.jobCount ?? 0} />
              </Box>
            </Box>
          </Box>
        )
      ))}
    </ListItem>
  )
}

ServiceJobStatuses.propTypes = {
  setJobState: PropTypes.func,
  statusFilter: PropTypes.array
}

export default ServiceJobStatuses
