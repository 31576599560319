import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import {
 Box, DashboardModule,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import Group from './components/Group'

const defaultState = {
  selectedOrderId: null,
}

const Bookings = (props) => {
  const {
    bookableSlots, callbacks: { toggleFilterSlots }, campaignServiceJobs,
  } = useContext(PageContext)

  const { setState: setStateCampaignBooking } = props

  const [state, setState] = useSetState(defaultState)
  const { selectedOrderId } = state

  const confirmed = campaignServiceJobs?.filter(x => x.order.status === 10)
  const quotes = [...new Set(campaignServiceJobs?.filter(x => x.order.status !== 10).map(x => JSON.stringify(x.order)))].map(x => JSON.parse(x)).sort((a, b) => a.id - b.id)

  const selectOrder = (orderId) => {
    setState({ selectedOrderId: orderId })

    const selectedConfirmed = campaignServiceJobs.find(x => x.order.id === orderId)?.order.status === 10

    const highlightJobs = campaignServiceJobs.map((highlightJob) => {
      const job = highlightJob
      job.highlighting = !campaignServiceJobs.some(x => x.highlighting && x.serviceGroupId === job.serviceGroupId && x.order.id === orderId)
        && (job.order.id === orderId || (selectedConfirmed && job.order.status === 10))

      return job
    })

    setStateCampaignBooking({ campaignServiceJobs: highlightJobs })
    toggleFilterSlots(bookableSlots)
  }

  useEffect(() => {
    setState({ selectedOrderId: campaignServiceJobs.find(x => x.highlighting)?.order.id })
  }, [])

  return (
    <DashboardModule title="Bookings">
      <Box flexDirection="column">
        {confirmed.length > 0 && (
          <Group
            callbacks={{ toggleItem: () => selectOrder(confirmed[0].order.id) }}
            selected={confirmed.some(x => x.order.id === selectedOrderId)}
            serviceJobs={confirmed}
            setStateCampaignBooking={setStateCampaignBooking}
          />
        )}
        {quotes.map(order => (
          <Group
            callbacks={{ toggleItem: () => selectOrder(order.id) }}
            order={order}
            selected={selectedOrderId === order.id}
            serviceJobs={campaignServiceJobs.filter(x => x.order?.id === order.id)}
            setStateCampaignBooking={setStateCampaignBooking}
          />
        ))}
      </Box>
    </DashboardModule>
  )
}

Bookings.propTypes = {
  setState: PropTypes.func,
}

export default Bookings
