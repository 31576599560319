import {
  faCamera,
  faGavel,
  faHome,
  faList,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Overview',
          visible: true,
        },
      ],
    },
    {
      key: 'section-one',
      title: 'Section',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faHome,
          key: 'campaigns',
          title: 'Campaigns',
          visible: true,
        },
        {
          icon: faList,
          key: 'nested',
          title: 'Nested Items',
          visible: true,
          items: [
            {
              href: '#/',
              icon: faGavel,
              key: 'nested-one',
              title: 'Nested One',
              visible: true,
            },
            {
              href: '#/',
              icon: faCamera,
              key: 'nested-two',
              title: 'Nested Two',
              visible: true,
            },
          ],
        },
      ],
    },
  ],
})

export default generateNavigationItems
