import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
 Box, ListItem, Tag, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'

const defaultState = {
  showOptions: false,
}

const CopyComment = (props) => {
  const { comment } = props

  const [state, setState] = useSetState(defaultState)
  const { showOptions } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showOptions })

  useEffect(() => {
    if (isClickedOutside && showOptions){
      setState({ showOptions: !showOptions })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        marginBottom: 'medium',
      }}
      forceBottomBorder
      flexDirection="column"
      disableHover
    >
      <Box flexDirection="row" alignItems="center" marginBottom="medium" className={custom.root} ref={contentEl}>
        <UserProfileImage imageUrl={comment.userImage} />
        <Box flexDirection="column" paddingLeft="large">
          <Text fontSize="small" fontWeight="500">
            {comment.user}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {comment.role}
          </Text>
        </Box>
        <FontAwesomeIcon icon={faEllipsisH} onClick={() => setState({ showOptions: !showOptions })} />
        {showOptions && (
          <Box className={custom.popup} width="100px" right="0">
            <Box flexDirection="column">
              <Text fontSize="small">Edit</Text>
              <Text fontSize="small">Resolve</Text>
            </Box>
          </Box>
        )}
      </Box>
      <Box flexDirection="row" alignItems="center" marginBottom="medium">
        <Tag boxProps={{ fontSize: 'xxsmall' }}>QC</Tag>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {comment.commentDate}
        </Text>
      </Box>
      <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium">
        {comment.message}
      </Text>
    </ListItem>
  )
}

CopyComment.propTypes = {
  comment: PropTypes.object,
}

export default CopyComment
