import React from 'react'
import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import Default from '.'
import Dashboard from './packs/Dashboard'

const DefaultRoutes = () => (
  <Router>
    <Default>
      <Routes>
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </Default>
  </Router>
)

export default DefaultRoutes
