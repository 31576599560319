/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import {
  Box, Button, IconWithMenu, Link, ListItem,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'

import useUploadResource from '@hooks/useUploadResource'
import useUploadAsset from '@hooks/useUploadAsset'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleUp, faCloudDownload, faCloudUpload, faPlus,
} from '@fortawesome/pro-light-svg-icons'

import { getEntityByName } from '@functions/getEntityByName'

const defaultState = {
  assignedToJob: false,
  visibleList: [],
}

const deleteSelectedResources = (resources, selectedIds, deleteFn) => {
  selectedIds.forEach((id) => {
    const resource = Object.values(resources).find(x => x.id === id)
    if (resource){
      deleteFn(resource).then(({ success, errors }) => {
        if (!success) toast.warning(errors[0])
      })
    }
  })
}

const ServiceGroupControls = (props) => {
  const { activeTabBarItemKey, selectedIds } = props
  const [state, setState] = useSetState(defaultState)
  const { assignedToJob, visibleList } = state

  const {
    callbacks: { assignServiceJobStatus },
    serviceJob,
   } = useContext(PageContext)

  const useUploadResourcePayload = useUploadResource()
  const {
    callbacks: {
      launchResourceUploadModal,
      deleteResource: deleteFn,
    },
  } = useUploadResourcePayload

  const useUploadAssetPayload = useUploadAsset()
  const {
    callbacks: {
      launchAssetUploadModal,
    },
  } = useUploadAssetPayload

  const { currentUser } = useCurrentUser()
  const {
    callbacks: { assignServiceJobStatus: createFn },
    filteredServiceJobUsers,
  } = useServiceJob({ id: serviceJob.id })

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources, serviceJobStatuses } = entities

  const sendSelected = (status) => {
    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: status.id,
      workflowComment: 'test',
      assets: selectedIds,
    }

    const getStatusDisplay = () => {
      switch (status.name){
        case 'ContentInQC':
          return 'QC'
        case 'ContentInProduction':
          return 'editor'
        case 'ContentSentToClient':
          return 'client'
        case 'ContentSentForVerification':
          return 'verification'
        default: return 'status'
      }
    }

    const statusName = getStatusDisplay()

    swal.fire({
      title: `Send to ${statusName[0].toUpperCase() + statusName.substring(1)}`,
      html: `This action will deliver the asset to ${statusName}.`
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        assignServiceJobStatus(serviceJobParam, createFn)
      }
    })
  }

  const deleteResources = () => {
    swal.fire({
      title: 'Delete Selected Resources',
      html: `This action will delete the ${selectedIds.length} resources you have selected`
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        deleteSelectedResources(resources, selectedIds, deleteFn)
      }
    })
  }

  const createAsset = (event, callback) => {
    const assetsWithModals = [1, 2]
    if (assetsWithModals.includes(serviceJob.serviceGroupId)){
      event.preventDefault()
      callback()
    }
  }

  useEffect(() => {
    setState({ assignedToJob: filteredServiceJobUsers?.find(x => x.user.id === currentUser.id) })
  }, [serviceJob, filteredServiceJobUsers])

  useEffect(() => {
    const list = []
    const adminRoles = ['ProductionManager'] // ToDo: add as property in userRole

    if (assignedToJob || adminRoles.includes(currentUser.userRole.name)){
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'ContentInQC')?.id)){
        list.push('qc')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'ContentInProduction')?.id)){
        list.push('editor')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'ContentSentToClient')?.id)){
        list.push('client')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'ContentSentForVerification')?.id)){
        list.push('verification')
      }
    }

    setState({ visibleList: list })
  }, [assignedToJob, serviceJobStatuses])

  return (
    <ListItem
      boxProps={{
        borderTop: '1px solid',
        padding: 'large',
        lineHeight: '1.3',
        alignItems: 'center',
      }}
      flexDirection="row"
      style={{ cursor: 'pointer' }}
      disableHover
    >
      {activeTabBarItemKey !== 'resources' && (
      <>
        <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
          <Link href={`#/service-jobs/${serviceJob.id}/assets`} disabledUnderline onClick={event => createAsset(event, launchAssetUploadModal)}>
            <Button
              size="medium"
              buttonStyle="secondary"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              Create Asset
            </Button>
          </Link>
        </Box>
        <Box flexShrink="0" alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
          <Button
            size="medium"
            buttonStyle="secondary"
            icon={<FontAwesomeIcon icon={faCloudDownload} />}
          >
            Download Assets
          </Button>
        </Box>
        <Box flexShrink="0" alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
          <IconWithMenu
            icon={(
              <Button
                size="medium"
                buttonStyle="ghostEdit"
                disabled={selectedIds.length < 1 || visibleList.length < 1}
                icon={<FontAwesomeIcon icon={faAngleUp} />}
                iconPosition="right"
              >
                Send
              </Button>
            )}
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <IconWithMenu.Menu
              closeOnClick
              listStyle={{ right: 0, left: '-55px', top: `-${(48 - visibleList.length) * visibleList.length}px` }}
              nibStyle={{ top: `${(33 + visibleList.length) * visibleList.length }px`, transform: 'rotate(225deg)' }}
            >
              {visibleList.includes('qc') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'ContentInQC'))}>
                  Send to QC
                </Link>
              )}
              {visibleList.includes('editor') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'ContentInProduction'))}>
                  Send to Editor
                </Link>
              )}
              {visibleList.includes('client') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'ContentSentToClient'))}>
                  Send to Client
                </Link>
              )}
              {visibleList.includes('verification') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'ContentSentForVerification'))}>
                  Send to Verification
                </Link>
              )}
            </IconWithMenu.Menu>
          </IconWithMenu>
        </Box>
      </>
      )}
      {activeTabBarItemKey === 'resources' && (
      <>
        <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
          <Button
            size="medium"
            buttonStyle="secondary"
            icon={<FontAwesomeIcon icon={faCloudDownload} />}
          >
            Download Resources
          </Button>
        </Box>
        <Box flexShrink="0" alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
          <Button
            size="medium"
            buttonStyle="secondary"
            icon={<FontAwesomeIcon icon={faCloudUpload} />}
            onClick={launchResourceUploadModal}
          >
            Upload Resources
          </Button>
        </Box>
        <Box flexShrink="0" alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
          <Button
            size="medium"
            buttonStyle="ghostDestroy"
            disabled={selectedIds.length < 1}
            onClick={() => deleteResources()}
          >
            Delete Resources
          </Button>
        </Box>
      </>
      )}
    </ListItem>
  )
}

ServiceGroupControls.propTypes = {
  activeTabBarItemKey: PropTypes.string,
  selectedIds: PropTypes.array,
}

export default ServiceGroupControls
