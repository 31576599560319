import React from 'react'
import PropTypes from 'prop-types'
import { HashRouter as Router } from 'react-router-dom'

import useValidShareLink from '@hooks/useValidShareLink'

import Loading from './components/Loading'
import InvalidLink from './components/InvalidLink'
import ClientRejection from './packs/ClientRejection'
import FlexibleContact from './packs/FlexibleContact'

const Share = (props) => {
  const { token } = props

  const {
    shareLink, shareType, isValid, tokenChecked, tokenKey,
  } = useValidShareLink(token)

  const getExpiredLinkContent = (type) => {
    switch (type){
      case 'Invite':
        return 'The contents of this page will be available at 6PM, one day before your appointment.'
      case 'Rejection':
        return 'You are seeing this page because the proof is either approved, done, deleted or still in production. Please contact Support if you need more information.'
      default:
        return 'Page is not available.'
    }
  }

  if (!tokenChecked){
    return <Loading />
  }

  if (tokenChecked && !isValid){
    return (
      <InvalidLink
        heading="Link has expired or is invalid"
        content={getExpiredLinkContent(shareType)}
      />
    )
  }

  if (shareType === 'Invite'){
    return (
      <Router>
        <FlexibleContact details={JSON.parse(shareLink)} />
      </Router>
    )
  }

  if (shareType === 'Rejection'){
    return (
      <Router>
        <ClientRejection details={JSON.parse(shareLink)} tokenKey={tokenKey} />
      </Router>
    )
  }

  return null
}

Share.propTypes = {
  token: PropTypes.string.isRequired,
}

export default Share
