import {
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Dashboard',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
