import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { useUserForm } from '@hooks/useUser'

import custom from '@styles/custom.module.scss'

const updateUserDetails = (entityState, props, state, updateFn, createFn) => {
  const { callbacks: { assignServicesToUser, updateUser } } = props
  const { selectedServices, userRoleId } = state

  entityState.services = selectedServices
  entityState.userRoleId = userRoleId

  updateUser(entityState, updateFn)

  const assignServicesToUserEntity = {
    id: entityState.id,
    services: selectedServices,
  }
  assignServicesToUser(assignServicesToUserEntity, createFn)
}

const defaultState = {
  serviceState: [],
  selectedServices: [],
  userRoleId: 1,
}

const EditUserModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { serviceState, selectedServices, userRoleId } = state

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const userPayload = digObject(modalData, modalKey, {})
  const {
    user,
    callbacks: {
      assignServicesToUser: createFn,
      updateUser: updateFn,
    },
  } = userPayload

  const userFormPayload = useUserForm(user)

  const { updating } = useSelector(reduxState => reduxState.users)

  const {
    entityState,
    errors,
    handlers,
    saveEnabled,
  } = userFormPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { userRoles, services } = entities

  useEffect(() => {
    let checklist = []
    entityState.services.map((service) => {
      checklist = [...checklist, service.id]
    })

    setState({
      selectedServices: checklist,
      userRoleId: entityState.userRoleId,
    })
  }, [entityState])

  useEffect(() => {
    setState({
      serviceState: Object.values(services).map(service => ({
        ...service,
        isChecked: selectedServices.includes(service.id),
      })),
    })
  }, [services, selectedServices])

  const updateUserRole = (selectedUserRoleId) => {
    setState({ userRoleId: selectedUserRoleId })
  }

  const updateSelectedServices = (checked, serviceId) => {
    if (checked){
      setState({ selectedServices: [...selectedServices, serviceId] })
    } else {
      setState({ selectedServices: selectedServices.filter(i => i !== serviceId) })
    }
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Edit User" />
      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField label="User Role">
            <select
              name="userRole"
              value={userRoleId}
              {...handlers}
              data-validate-field-on="change"
              onChange={e => updateUserRole(e.target.value)}
            >
              {Object.values(userRoles).map(userRole => (
                <option key={userRole.id} value={userRole.id}>
                  {userRole.description}
                </option>
              ))}
            </select>
          </FormField>
          <Box flexDirection="column">
            <Text color="bodyFontLightColor" fontSize="small" marginTop="large">
              Service Types
            </Text>
            <Box
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              lineHeight="1.3"
              marginTop="medium"
            >
              {serviceState.map(service => (
                <Box key={service.id} flexDirection="row" paddingBottom="medium" alignItems="center">
                  <input
                    type="checkbox"
                    className={custom.checkbox}
                    checked={service.isChecked}
                    onChange={e => updateSelectedServices(e.target.checked, service.id)}
                  />
                  <Text fontSize="small" marginLeft="medium">{service.name}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateUserDetails(entityState, props, state, updateFn, createFn)}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditUserModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditUserModal.defaultProps = {
  modalKey: 'EditUserModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
