import React from 'react'
import PropTypes from 'prop-types'

import { Box, Checkbox, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'
import GridView from './GridView'

const Gallery = (props) => {
  const {
    callbacks: {
      toggleSelectAllContent,
      toggleMultipleSelection,
      toggleUpdateSelectedIds,
    }, contents, multipleSelection, selectAll, selectedIds, showControls, showDetails, type,
  } = props

  return (
    <Box flexDirection="column" padding="large" className={custom.scroll}>
      {showControls && contents.length > 0 && (
        <Box flexDirection="row" padding="medium">
          <Box
            alignItems="center"
            flexShrink="0"
            justifyContent="flexEnd"
            marginLeft="auto"
            onClick={() => toggleMultipleSelection()}
            style={{ cursor: 'pointer' }}
            width="auto"

          >
            <Checkbox checked={multipleSelection} marginRight="medium" />
            <Text color="blue" fontSize="small">
              Select Multiple {type[0].toUpperCase()}{type.substring(1)}
            </Text>
          </Box>
          {multipleSelection && (
          <Box
            alignItems="center"
            flexShrink="0"
            justifyContent="flexEnd"
            marginLeft="large"
            onClick={() => toggleSelectAllContent()}
            style={{ cursor: 'pointer' }}
            width="auto"
          >
            <Checkbox checked={selectAll} marginRight="medium" />
            <Text color="blue" fontSize="small">
              Select All
            </Text>
          </Box>
          )}
        </Box>
      )}

      {contents.length > 0 && (
        <GridView
          callbacks={{ toggleUpdateSelectedIds }}
          contents={contents}
          selectedIds={selectedIds}
          multipleSelection={multipleSelection}
          showDetails={showDetails}
        />
      )}

      {!contents.length && (
        <Box
          alignItems="center"
          color="bodyFontColor"
          display="grid"
          height={[150, 250]}
          justifyContent="center"
        >
          <Text fontSize="small" fontWeight="600">
            No {type === 'proofs' ? 'assets' : type || 'images'} uploaded yet.
          </Text>
        </Box>
      )}
    </Box>
  )
}

Gallery.propTypes = {
  callbacks: PropTypes.object,
  contents: PropTypes.array,
  multipleSelection: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectedIds: PropTypes.array,
  showControls: PropTypes.bool,
  showDetails: PropTypes.bool,
  type: PropTypes.string,
}

export default Gallery
