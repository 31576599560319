import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const Result = (props) => {
  const { icon, result: { href, priority, subtitle, title } } = props

  return (
    <li>
      <a href={href}>
        <Box
          alignItems="center"
          color="blue"
          fontSize="medium"
          justifyContent="center"
          marginRight="large"
          width={20}
        >
          <FontAwesomeIcon icon={icon || faHome} />
        </Box>

        <Box
          alignItems="flexStart"
          flexDirection="column"
          justifyContent="center"
          opacity={priority === 0 ? '0.5' : '1'}
        >
          <Text color="bodyFontColor" fontSize="small">
            {title}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {subtitle}
          </Text>
        </Box>
      </a>
    </li>
  )
}

Result.propTypes = {
  icon: PropTypes.object,
  result: PropTypes.shape({
    id: PropTypes.number.isRequired,
    priority: PropTypes.number,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Result
