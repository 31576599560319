import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Button, Box, FormField, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'

const defaultState = {
  clientState: [],
  showFilter: false
}

const ClientFilter = (props) => {
  const { setJobState, jobState } = props
  const { clientFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { clientState, showFilter } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { clients } = entities

  const options = new URLSearchParams([
    ["includes", "clientType"]
  ])

  useReduxAction('clients', 'loadClients', options)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    setState({ clientState: groupClientByClientType(clients, 'clientType', 'name') }) 
  }, [clients, clientFilter])

  const groupClientByClientType = (array, key, item) => {
    return Object.values(array).reduce((result, currentValue) => {
      (result[currentValue[key][item]] = result[currentValue[key][item]] || []).push({
        ...currentValue,
        isChecked: clientFilter.includes(currentValue.id)
      });
      return result;
    }, {})
  }

  const updateClientState = (checked, clientId) => {
    if (checked)
      setJobState({ clientFilter: [...clientFilter, clientId] })
    else {
      setJobState({ clientFilter: clientFilter.filter(i => i !== clientId) })
    }
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? Object.values(clients) : clientState[type]
    var checklist = []

    if (checked) {
      filterList.map(user => {
        checklist = [...checklist, ...clientFilter, user.id] 
      })     
    }
    else {
      checklist = [...clientFilter]
      filterList.map(user => {
        checklist = checklist.filter(i => i === user.id)
      })
    } 

    setJobState({ clientFilter: [...new Set(checklist)] })
  }

  if (Object.keys(clientState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: "large" }}>
      <Box className={custom.root} ref={contentEl}>
        <Box flexDirection="column">
          <Button
            buttonStyle="secondary"
            size="medium"
            height="40px"
            onClick={() => setState({ showFilter: !showFilter })}
            className={custom.btnFilter}
          >
            Clients
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        </Box>

        {showFilter &&
          <Box className={custom.popup}>
            <Box flexDirection="column">
              <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, 'All')} />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(clientState).map((type) => (
                <Box flexDirection="row" paddingBottom="small" alignItems="center">
                  <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, type)} />
                  <Text fontSize="small" marginLeft="medium">Select All {type}</Text>
                </Box>
              ))}
              {Object.keys(clientState).map((clients) => {
                return (
                  <>
                    <Text fontSize="medium" fontWeight="medium" paddingBottom="medium" paddingTop="large">{clients}</Text>
                    {clientState[clients].map((client) => {
                      return (
                        <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center">
                          <input
                            className={custom.checkbox}
                            onChange={e => updateClientState(e.target.checked, client.id)}
                            type="checkbox"
                            checked={client.isChecked}
                          />
                          <Text fontSize="small" marginLeft="medium">{client.name}</Text>
                        </Box>
                      )
                    })}
                  </>
                )
              })}
            </Box>
          </Box>
        }
      </Box>
    </FormField>
  );
}


export default ClientFilter