import React, { useEffect, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'

import useCurrentUser from '@hooks/useCurrentUser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faUser } from '@fortawesome/pro-light-svg-icons'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import {
 Box, LoadingBubbles, Text, UserProfileImage,
} from '@campaignhub/suit-theme'
import DropdownLink from '../DropdownLink'

import styles from './styles.module.scss'

const defaultState = {
  showMenu: false,
}

const LoggedInUser = (props) => {
  const {
    children,
    imageUrl,
    loading,
  } = props

  const { logout } = useAuth0()
  const [state, setState] = useSetState(defaultState)
  const { showMenu } = state

  const { currentUser } = useCurrentUser()

  const { userRole: { description } } = currentUser
  const { firstName, lastName } = currentUser
  const fullName = `${firstName} ${lastName}`

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showMenu })

  useEffect(() => {
    if (showMenu && isClickedOutside){
      setState({ showMenu: !showMenu })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <Box alignItems="center" className={styles.root} px="large" ref={contentEl}>
      {loading && <LoadingBubbles />}

      {!loading && (
        <>
          <Box onClick={() => setState({ showMenu: !showMenu })}>
            <Box
              display={['none', 'flex']}
              alignItems="flex-end"
              flexDirection="column"
              justifyContent="center"
              marginRight="medium"
              textAlign="right"
            >
              <Text fontSize="small" marginBottom="small" maxWidth={[0, 115, 175, 250]} variant="ellipsis">
                {fullName}
              </Text>
              <Text color="bodyFontLightColor" fontSize="xsmall" maxWidth={[0, 115, 175, 250]} variant="ellipsis">
                {description}
              </Text>
            </Box>

            <Box alignItems="center" width={30}>
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faUser} />
              </UserProfileImage>
            </Box>
          </Box>

          {showMenu && (
            <Box
              backgroundColor="white"
              className={styles.userMenu}
              color="bodyFontLightColor"
              flexDirection="column"
              fontSize="small"
              padding="medium"
              width={['100%', 300]}
            >
              {children}

              <DropdownLink icon={faSignOut} onClick={() => logout({ returnTo: window.location.origin })} text="Sign Out" />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

LoggedInUser.propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  loading: PropTypes.bool,
}

export default LoggedInUser
