import {
  faCamera,
  faCog,
  faGavel,
  faGlobe,
  faLifeRing,
  faLink,
  faList,
  faTachometer,
  faUser
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Overview',
          visible: true,
        },
      ],
    },
    {
      key: 'admin',
      title: 'Admin',
      visible: true,
      items: [
        {
          icon: faCog,
          key: 'system-manager',
          title: 'System Manager',
          visible: true,
          items: [
            {
              href: '#/systemManager/users',
              icon: faUser,
              key: 'users',
              title: 'Users',
              visible: true,
            },
            {
              href: '#/systemManager/areas',
              icon: faGlobe,
              key: 'area',
              title: 'Area',
              visible: true,
            },
            {
              href: '#/systemManager/segment',
              icon: faLink,
              key: 'segments',
              title: 'Segments',
              visible: true,
            },
          ],
        },
        {
          icon: faList,
          key: 'nested-two',
          title: 'Nested Items',
          visible: true,
          items: [
            {
              href: '#/',
              icon: faGavel,
              key: 'nested-one',
              title: 'Nested One',
              visible: true,
            },
            {
              href: '#/',
              icon: faCamera,
              key: 'nested-two',
              title: 'Nested Two',
              visible: true,
            },
          ],
        },
        {
          icon: faList,
          key: 'nested-three',
          title: 'Nested Items',
          visible: true,
          items: [
            {
              href: '#/',
              icon: faGavel,
              key: 'nested-one',
              title: 'Nested One',
              visible: true,
            },
            {
              href: '#/',
              icon: faCamera,
              key: 'nested-two',
              title: 'Nested Two',
              visible: true,
            },
          ],
        },
      ],
    },
    {
      key: 'support',
      title: 'Support',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faLifeRing,
          key: 'training',
          title: 'Training',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
