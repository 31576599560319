export function getRequestOptions(type, serviceJobOptions){
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        'resource.description': '',
        'resource.sort': 0,
        'resource.tags': {},
        ...serviceJobOptions,
      }
    case 'ASSETS':
      return {
        description: 'new upload',
        ...serviceJobOptions,
      }
    default:
      return null
  }
}

export function getUppyOptions(type, allowedFileTypes, serviceJobOptions, setState, callbacks){
  const requestOptions = getRequestOptions(type, serviceJobOptions)
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        allowedFileTypes,
        requestOptions,
        maxTotalFileSize: 500000000,
        callbacks,
        fieldName: 'FormFile',
        endpoint: 'v3/resources/',
        loadSearchParams: [
          ['includes', 'file'],
        ],
      }
    case 'ASSETS':
      return {
        allowedFileTypes,
        requestOptions,
        maxTotalFileSize: 0,
        callbacks,
        fieldName: 'FormFile',
        endpoint: 'v3/assets/',
        loadSearchParams: [
          ['serviceJobs', requestOptions.serviceJobId],
          ['includes', 'comments'],
          ['includes', 'file'],
        ],
      }
    default:
      return {
        fieldName: '',
        endpoint: '',
      }
  }
}

export function getComponentCallbacks(component, setState){
  const componentsCallbacks = {
    UPPY: {
      addRecentlyUploadedBatch: list => setState({ lastUploadedBatch: list }),
    },
    RECENTLYUPLOADEDLIST: {
      removeRecentlyDeleted: id => setState({ lastDeletedId: id }),
    },
  }

  return componentsCallbacks[component.toUpperCase()] || {}
}
