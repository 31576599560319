import useUserRoles from '@hooks/useUserRoles'
import useServiceGroups from '@hooks/useServiceGroups'

const Admin = (props) => {
  const { children } = props

  useUserRoles({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })

  return children
}

export default Admin
