import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'
import { toast } from 'react-toastify'

import {
  Box, Button, FormField,
} from '@campaignhub/suit-theme'

import useServiceJobDetailsV2 from '@hooks/useServiceJobDetailsV2'

const assignServiceJobDetail = (serviceJobDetail, createFn, setState) => {
  createFn(serviceJobDetail).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])

    return setState({ creating: false })
  })
}

const defaultState = {
  creating: false,
  noteOnChange: '',
}

const Note = (props) => {
  const { bookingState, note, serviceJobId } = props

  const [state, setState] = useSetState(defaultState)
  const { creating, noteOnChange } = state

  const serviceJobDetailPayload = useServiceJobDetailsV2()
  const {
    callbacks: { assignServiceJobDetail: createFn },
  } = serviceJobDetailPayload

  useEffect(() => {
    setState({ noteOnChange: note })
  }, [])

  const saveNote = () => {
    const serviceJobDetail = {
      serviceJobId,
      fieldName: 'SupplierNote',
      value: noteOnChange,
    }

    setState({ creating: true })
    assignServiceJobDetail(serviceJobDetail, createFn, setState)
    bookingState({ bookingNote: noteOnChange })
  }

  return (
    <Box flexDirection="column" variant="white">
      <FormField padding="medium" style={{ borderRadius: 0 }}>
        <textarea
          onChange={e => setState({ noteOnChange: e.target.value })}
          placeholder="Type something..."
          value={noteOnChange}
          style={{
            border: 'none', fontSize: '13px', height: 80, resize: 'none', width: '100%',
          }}
        />
      </FormField>
      <Box
        alignItems="end"
        flexDirection="row"
        padding="medium"
        borderTop="1px solid"
        borderColor="lineColor"
      >
        <Button
          buttonStyle="secondaryUtility"
          marginLeft="auto"
          onClick={() => bookingState({ showNote: false })}
          size="medium"
          width="auto"
        >
          Cancel
        </Button>
        <Button
          loading={creating}
          buttonStyle="primaryCreate"
          marginLeft="medium"
          onClick={saveNote}
          size="medium"
          width="auto"
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

Note.propTypes = {
  bookingState: PropTypes.func,
  note: PropTypes.string,
  serviceJobId: PropTypes.number.isRequired,
}

export default Note
