import { useContext } from 'react'
import { useSelector } from "react-redux"

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/user'

import * as userActions from '@redux/modules/user'

import PageContext from '@contexts/pageContext'

const assignServicesToUser = (userParams, dispatch, requestOptions) => {
  const { assignServicesToUser: createFn } = userActions
  return dispatch(createFn(userParams, requestOptions))
}

const updateUser = (userParams, dispatch, requestOptions) => {
  const { updateUser: updateFn } = userActions
  return dispatch(updateFn(userParams, requestOptions))
}

const launchEditUserModal = (user, dispatch, showEditUserModal) => new Promise((resolve, reject) => {
  if (showEditUserModal) {
    const payload = {
      callbacks: {
        assignServicesToUser: (userParams, requestOptions) => assignServicesToUser(userParams, dispatch, requestOptions),
        updateUser: (userParams, requestOptions) => updateUser(userParams, dispatch, requestOptions),
      },
      user
    }

    showEditUserModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditUserModal not defined in PageContext callbacks'))
})

export function useUserForm(user = {}) {
  const userForm = useForm(defaultState, { entity: user, requiredFields }, [user.id])

  return {
    ...userForm,
  }
}

const useUser = (initUser = {}) => {
  const { entity: user } = useLatestEntity(initUser, 'users')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditUserModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.users)

  return {
    user,
    callbacks: {
      launchEditUserModal: () => launchEditUserModal(user, dispatch, showEditUserModal),
      assignServicesToUser: (userParams, requestOptions) => updateUser(userParams, dispatch, requestOptions),
      updateUser: (userParams, requestOptions) => updateUser(userParams, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useUser