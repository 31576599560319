import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'

import Overview from './screens/Overview'
import CampaignBooking from './screens/CampaignBooking'
import CalendarRedirect from './screens/CalendarRedirect'

const BookingsRoutes = (props) => {
  const { showProofingTab } = props

  return (
    <Routes>
      <Route path="/" element={<Overview showProofingTab={showProofingTab} />} />
      <Route path="/campaigns/:id/bookings" element={<CampaignBooking />} />
      <Route path="/calendar/:platform/:id" element={<CalendarRedirect />} />
    </Routes>
  )
}

BookingsRoutes.propTypes = {
  showProofingTab: PropTypes.bool,
}

export default BookingsRoutes
