import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Columns, Text, LoadingBubbles,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import useCurrentUser from '@hooks/useCurrentUser'
import useNumericParams from '@hooks/useNumericParams'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJobs from '@hooks/useServiceJobs'

import custom from '@styles/custom.module.scss'
import ImageSlider from './components/ImageSlider'
import RejectApprove from './components/RejectApprove'
import CopyViewer from './components/CopyViewer'

const RefreshAssets = (props) => {
  const { serviceJob } = props

  const assetIncludes = ['comments']
  useReduxAction('assets', 'loadAssets', {
    serviceJobs: serviceJob.id,
    includes: assetIncludes.join(','),
  }, [serviceJob])

  return null
}

const defaultState = {
  currentAssets: [],
  selectedAsset: null,
  selectedServiceJob: null,
  refresh: false,
}

const ProofingPage = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    currentAssets, selectedAsset, selectedServiceJob, refresh,
  } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets } = entities

  const { id: campaignId } = useNumericParams()

  const { currentUser } = useCurrentUser()

  const includes = ['campaign', 'details', 'status']
  const statuses = [150, 160, 170]
  useReduxAction('serviceJobs', 'loadServiceJobs', {
    campaigns: campaignId,
    includes: includes.join(','),
    serviceJobStatuses: statuses.join(','),
  }, [campaignId])

  const {
    campaignServiceJobs,
    loading,
  } = useServiceJobs({ campaignId })

  const setSelectedServiceJob = (job) => {
    setState({
      selectedServiceJob: job,
      refresh: true,
    })
  }

  const setSelectedAsset = (asset) => {
    setState({ selectedAsset: asset })
  }

  const updateCurrentAssets = (updatedAssets, selected) => {
    setState({
      currentAssets: updatedAssets,
      selectedAsset: updatedAssets.find(x => x.id === selected.id),
    })
  }

  const pageContext = {
    callbacks: {
      toggleUpdateCurrentAssets: updatedAssets => updateCurrentAssets(updatedAssets, selectedAsset),
      toggleSelectedAsset: asset => setSelectedAsset(asset),
      toggleSelectedServiceJob: job => setSelectedServiceJob(job),
    },
    campaignId,
    currentAssets,
    currentUser,
    selectedAsset,
    selectedServiceJob,
  }

  useEffect(() => {
    const tempAssets = Object.values(assets).filter(x => x.serviceJobId === selectedServiceJob?.id)
    setState({ currentAssets: tempAssets })

    if (tempAssets.length) setSelectedAsset(tempAssets[0])
  }, [assets, selectedServiceJob])

  return (
    <PageContext.Provider value={pageContext}>
      <Box display="flex" flexDirection="column" minWidth="100%" backgroundColor="white">
        <Columns flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Box flexDirection="column" padding="large" paddingRight={[16, 16, 0]} className={custom.scroll}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                marginRight="auto"
                onClick={() => { window.location.hash = '/overviews/proofing' }}
                style={{ cursor: 'pointer' }}
                width="auto"
                paddingBottom="medium"
              >
                <Box paddingRight="medium" marginRight="auto" justifyContent="flexEnd" width="auto">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Box>
                <Text fontSize="xsmall" color="bodyFontLightColor">
                  Go Back
                </Text>
              </Box>
              {!loading && [1, 2].includes(selectedServiceJob?.serviceGroupId) && (
                <ImageSlider />
              )}
              {!loading && selectedServiceJob?.serviceGroupId === 3 && (
                <CopyViewer />
              )}
              {!loading && ![1, 2, 3].includes(selectedServiceJob?.serviceGroupId) && (
                <Box
                  border="1px solid"
                  borderColor="lineColor"
                  borderRadius={5}
                  flexDirection="column"
                  height={[430, '80vh']}
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontSize="xsmall" color="bodyFontLightColor">
                    Coming Soon
                  </Text>
                </Box>
              )}
              {loading && (
                <Box
                  border="1px solid"
                  borderColor="lineColor"
                  borderRadius={5}
                  flexDirection="column"
                  height={[430, '80vh']}
                  width="100%"
                  justifyContent="center"
                >
                  <LoadingBubbles color="rgba(0, 0, 0, 0.15)" />
                </Box>
              )}
            </Box>
          </Columns.Main>
          <Columns.Sidebar>
            <Box
              borderRadius="0 !important"
              className={custom.scroll}
              flexDirection="column"
              height="100vh"
              lineHeight="1.3"
              variant="white"
            >
              <RejectApprove
                serviceJobs={campaignServiceJobs}
                serviceJobsLoading={loading}
              />
            </Box>
          </Columns.Sidebar>
          {refresh ? <RefreshAssets serviceJob={selectedServiceJob} /> : null}
        </Columns>
      </Box>

    </PageContext.Provider>
  )
}

export default ProofingPage
