import deepmerge from 'deepmerge'

const DELETE_ENTITY = 'contenthouse/entity/DELETE_ENTITY'
const REPLACE_ENTITY = 'contenthouse/entity/REPLACE_ENTITY'
const UPDATE_ENTITY = 'contenthouse/entity/UPDATE_ENTITY'

const initialState = {
  _updates: {},
  assetCommentTypes: {},
  assetComments: {},
  assets: {},
  campaigns: {},
  clients: {},
  filters: {},
  images: {},
  resources: {},
  serviceGroups: {},
  serviceGroupsV2: {},
  serviceJobNotes: {},
  serviceJobStatuses: {},
  serviceJobUserRoles: {},
  serviceJobUsers: {},
  serviceJobs: {},
  services: {},
  statistics: {},
  timetackers: {},
  userRoles: {},
  users: {},
  workflows: {},
}

export function updateEntities(payload){
  return { type: UPDATE_ENTITY, ...payload.entities }
}

export function replaceEntity(payload){
  return { type: REPLACE_ENTITY, ...payload.entities }
}

export function deleteEntity(payload){
  return { type: DELETE_ENTITY, ...payload.entities }
}

function cleanKeys(keys = [], removeKeys = []){
  removeKeys.forEach((removeKey) => {
    const index = keys.indexOf(removeKey)
    keys.splice(index, 1)
  })

  return keys
}

// Reducers
function addEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  // Don't merge arrays
  const dontMerge = (_, source) => source
  const mergeOptions = { arrayMerge: dontMerge }

  keys.forEach((key) => {
    newState[key] = deepmerge(newState[key], action[key], mergeOptions)
  })
  return newState
}

function replaceEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to replace
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        newState[key][itemKey] = action[key][itemKey]
      })
    }
  })

  return newState
}

function removeEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to remove
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        delete newState[key][itemKey]
      })
    }
  })

  return newState
}

export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_ENTITY:
      return addEntities(state, action)
    case REPLACE_ENTITY:
      return replaceEntities(state, action)
    case DELETE_ENTITY:
      return removeEntities(state, action)
    default:
      return state
  }
}
