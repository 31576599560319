const state = {
  id: null,
  serviceJobId: null,
  user: {
    id: null
  },
  serviceJobUserRole: {
    id: null
  },
  userId: null,
  serviceJobUserRoleId: null
}

export const requiredFields = []

export default state
