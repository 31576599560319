import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
const asset = new schema.Entity('assets')
const assetComment = new schema.Entity('assetComments')
const assetCommentType = new schema.Entity('assetCommentTypes')
const campaign = new schema.Entity('campaigns')
const client = new schema.Entity('clients')
const clientType = new schema.Entity('clientTypes')
const filter = new schema.Entity('filters')
const image = new schema.Entity('images')
const resource = new schema.Entity('resources')
const service = new schema.Entity('services')
const serviceGroup = new schema.Entity('serviceGroups')
const serviceGroupV2 = new schema.Entity('serviceGroupsV2')
const serviceJob = new schema.Entity('serviceJobs')
const serviceJobNote = new schema.Entity('serviceJobNotes')
const serviceJobStatus = new schema.Entity('serviceJobStatuses')
const serviceJobUser = new schema.Entity('serviceJobUsers')
const serviceJobUserRole = new schema.Entity('serviceJobUserRoles')
const shareLink = new schema.Entity('shareLinks')
const statistic = new schema.Entity('statistics')
const timetracker = new schema.Entity('timetrackers')
const user = new schema.Entity('users')
const userRole = new schema.Entity('userRoles')
const workflow = new schema.Entity('workflows')

asset.define({
  comments: [assetComment],
})

serviceGroup.define({
  services: [service],
})

serviceGroupV2.define({
  services: [service],
})

// Export Schemas
export const Schemas = {
  ASSET: asset,
  ASSET_ARRAY: [asset],
  ASSET_COMMENT: assetComment,
  ASSET_COMMENT_ARRAY: [assetComment],
  ASSET_COMMENT_TYPE: assetCommentType,
  ASSET_COMMENT_TYPE_ARRAY: [assetCommentType],
  CAMPAIGN: campaign,
  CAMPAIGN_ARRAY: [campaign],
  CLIENT: client,
  CLIENT_ARRAY: [client],
  CLIENT_TYPE: clientType,
  CLIENT_TYPE_ARRAY: [clientType],
  FILTER: filter,
  FILTER_ARRAY: [filter],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  RESOURCE: resource,
  RESOURCE_ARRAY: [resource],
  SERVICE: service,
  SERVICE_ARRAY: [service],
  SERVICE_GROUP: serviceGroup,
  SERVICE_GROUP_ARRAY: [serviceGroup],
  SERVICE_GROUPV2: serviceGroupV2,
  SERVICE_GROUPV2_ARRAY: [serviceGroupV2],
  SERVICE_JOB: serviceJob,
  SERVICE_JOB_ARRAY: [serviceJob],
  SERVICE_JOB_NOTE: serviceJobNote,
  SERVICE_JOB_NOTE_ARRAY: [serviceJobNote],
  SERVICE_JOB_STATUS: serviceJobStatus,
  SERVICE_JOB_STATUS_ARRAY: [serviceJobStatus],
  SERVICE_JOB_USER: serviceJobUser,
  SERVICE_JOB_USER_ARRAY: [serviceJobUser],
  SERVICE_JOB_USER_ROLE: serviceJobUserRole,
  SERVICE_JOB_USER_ROLE_ARRAY: [serviceJobUserRole],
  SHARE_LINK: shareLink,
  SHARE_LINK_ARRAY: [shareLink],
  STATISTIC: statistic,
  STATISTIC_ARRAY: [statistic],
  TIMETRACKER: timetracker,
  TIMETRACKER_ARRAY: [timetracker],
  USER: user,
  USER_ARRAY: [user],
  USER_ROLE: userRole,
  USER_ROLE_ARRAY: [userRole],
  WORKFLOW: workflow,
}

export default Schemas
