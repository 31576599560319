import React, { useEffect, useRef } from 'react'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { Button, Box, FormField, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import custom from '@styles/custom.module.scss'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { dateToFilter } from '@functions/dateToFilter'
import { filterToDate } from '@functions/filterToDate'

const defaultState = {
  dateEnd: new Date(),
  dateStart: new Date(),
  showFilter: false
}

const DateFilter = (props) => {
  const { setJobState, jobState } = props
  const { dateFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { dateEnd, dateStart, showFilter } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter) {
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    setState({
      dateStart: filterToDate(dateFilter.filterStart, 'start'),
      dateEnd: filterToDate(dateFilter.filterEnd, 'end')
    })
  }, [dateFilter])

  const setCustomDateFilter = (startDate, endDate) => {
    setJobState({
      dateFilter: {
        filterStart: dateToFilter(startDate, 'start'),
        filterEnd: dateToFilter(endDate, 'end')
      }
    })
  }

  const setDateFilter = (startDate, endDate) => {
    setJobState({
      dateFilter: {
        filterStart: startDate,
        filterEnd: endDate
      }
    })
  }

  return (
    <FormField direction="column" boxProps={{ paddingBottom: "large" }}>
      <Box className={custom.root} ref={contentEl}>
        <Box flexDirection="column">
          <Button
            buttonStyle="secondary"
            size="medium"
            height="40px"
            onClick={() => setState({ showFilter: !showFilter })}
            className={custom.btnFilter}
          >
            Date Range
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        </Box>

        {showFilter &&
          <Box className={custom.popup} >
            <Box flexDirection="column">
              <Box flexDirection="row" marginBottom="medium">
                <Box flexDirection="column" marginRight="medium">
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">From</Text>
                  <DatePicker selected={dateStart} onChange={date => setCustomDateFilter(date, dateEnd)} dateFormat="MM.dd.yyy" maxDate={dateEnd} />
                </Box>
                <Box flexDirection="column" marginRight="medium">
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">To</Text>
                  <DatePicker selected={dateEnd} onChange={date => setCustomDateFilter(dateStart, date)} dateFormat="MM.dd.yyy" minDate={dateStart} />
                </Box>
              </Box>
              <Button
                buttonStyle="secondary"
                size="medium"
                height="40px"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('0d', '1d')}
              >
                Today
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                height="40px"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('-1d', '0d')}
              >
                Yesterday
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                height="40px"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('0m', '1m')}
              >
                This Month
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                height="40px"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('-1m', '0m')}
              >
                Past Month
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </FormField>
  );
}

export default DateFilter