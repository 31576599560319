import { useCallback } from 'react'
import { DateTime } from 'luxon'

import useCurrentUser from '@hooks/useCurrentUser'

const useSlot = (bookableSlots, campaignServiceJobs, duskTimes) => {
  const { currentUser: { timeZone } } = useCurrentUser()

  const renderJob = useCallback((gridSlot, serviceGroupId) => campaignServiceJobs
    .find(x => DateTime.fromISO(x.booking?.bookingStart, { zone: timeZone })
    .equals(gridSlot) && x.serviceGroupId === serviceGroupId && x.bookingType !== 'pending'), [])

  const getHighlightingJob = useCallback(serviceGroupId => campaignServiceJobs.find(x => x.serviceGroupId === serviceGroupId && x.highlighting), [])

  const top = useCallback(gridSlot => [0, 30].includes(gridSlot.c.minute), [])

  const duskTime = useCallback((gridSlot) => {
    let dusk = gridSlot.set({ hour: 17, minute: 0 })
    if (duskTimes?.length){
      duskTimes.forEach((x) => {
        if (gridSlot.set({ month: x.startMonth, day: x.startDay }) <= gridSlot
          && gridSlot <= gridSlot.set({ month: x.endMonth, day: x.endDay })){
          dusk = gridSlot.set({ hour: DateTime.fromISO(x.time).c.hour, minute: (Math.floor(DateTime.fromISO(x.time).c.minute / 15) * 15) % 60 })
        }
      })
    }
    return dusk
  }, [duskTimes])

  const available = useCallback((gridSlot, serviceGroupId) => {
    const gridStart = gridSlot.toISO({ suppressMilliseconds: true })
    const gridEnd = gridSlot.plus({ minute: 15 }).toISO({ suppressMilliseconds: true })

    return bookableSlots.some(x => x.serviceGroupId === serviceGroupId && x.start <= gridStart && x.end >= gridEnd)
  }, [bookableSlots])

  return {
    callbacks: {
      available: (gridSlot, serviceGroupId) => available(gridSlot, serviceGroupId),
      duskTime: gridSlot => duskTime(gridSlot),
      getHighlightingJob: serviceGroupId => getHighlightingJob(serviceGroupId),
      getRenderJob: (gridSlot, serviceGroupId) => renderJob(gridSlot, serviceGroupId),
      top: gridSlot => top(gridSlot),
    },
  }
}

export default useSlot
