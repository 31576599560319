/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
export const handleError = (axiosError) => {
  console.log('Raw Error', axiosError.response)
  let badRequest = axiosError.response?.data?.errors?.BadRequest
  badRequest = badRequest?.some(x => x.includes('Provider Already Booked'))
    ? ['One or more of your requested bookings have already been filled. Please review and resubmit your request.']
    : badRequest
  return badRequest || ['Something went wrong']
}
