import React from 'react'

import {
  Box,
  TabBar,
  Table,
  Text,
  UserProfileImage
} from '@campaignhub/suit-theme'

import useUsers from '@hooks/useUsers'

import Status from './components/Status'

const UserList = () => {
  const { users } = useUsers()

  return (
    <>
      <TabBar
        items={[
          {
            key: 'all',
            title: 'All',
          },
          {
            key: 'studio',
            title: 'Studio',
          },
          {
            key: 'onsite',
            title: 'Onsite',
          },
          {
            key: 'backOffice',
            title: 'Back Office',
          }
        ]}
        selectedItemKey='all'
        invertColors={true}
      />
      <Table
        columns={[
          {
            dataKey: 'checkbox',
            title: ''
          },
          {
            dataKey: '',
            render: function noRefCheck() {
              return (
                <UserProfileImage imageUrl="https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80" />
              )
            },
            title: ''
          },
          {
            dataKey: 'firstName',
            render: function noRefCheck(firstName, item) {
              return (
                <Text>{firstName} {item.lastName}</Text>
              )
            },
            title: 'Name'
          },
          {
            dataKey: 'email',
            title: 'User Email'
          },
          {
            dataKey: 'userRole',
            render: function noRefCheck(userRole) {
              return (
                <Text>{userRole.description}</Text>
              )
            },
            title: 'Position'
          },
          {
            dataKey: 'employment',
            title: 'Employment'
          },
          {
            dataKey: 'services',
            render: function noRefCheck(services) {
              return (
                <Box
                  flexDirection="column"
                  textAlign="right"
                  lineHeight="1.3"
                  alignItems="center"
                >
                  {services && services.map(service => (
                    <Text marginRight="small">{service.name}</Text>
                  ))}
                </Box>
              )
            },
            title: 'Services'
          },
          {
            dataKey: 'status',
            render: function noRefCheck(id, item) {
              item.userRoleId = item.userRole.id
              return (
                <Status user={item} />
              )
            },
            title: 'Status'
          }
        ]}
        data={Object.values(users)}
        scroll={1000}
      />
    </>
  )
}

export default UserList