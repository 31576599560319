import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTimes, faUser } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Checkbox, Image, Link, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

const defaultState = { selectedProvider: false }

const Provider = (props) => {
  const {
    callbacks: { toggleItem },
    provider: {
      firstName, lastName, imageUrl, profile, userName,
    },
    selected,
    serviceGroupId,
  } = props
  const [state, setState] = useSetState(defaultState)
  const { showProviderDesc } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroupsV2 } = entities

  return (
    <Box
      flexDirection="column"
      borderBottom="1px dashed"
      borderColor="lineColor"
      marginBottom="large"
    >
      <Box flexDirection="row" marginBottom="large" alignItems="center" justifyContent="center">
        <Box flexDirection="column" justifyContent="flex-start" width="auto">
          <Checkbox checked={selected} onClick={() => toggleItem(userName)} />
        </Box>
        <Box flexDirection="column" marginRight="medium" width="auto">
          <UserProfileImage imageUrl={imageUrl}>
            <FontAwesomeIcon icon={faUser} />
          </UserProfileImage>
        </Box>
        <Box flexDirection="column" flexShrink={0} marginRight="auto" width="auto">
          <Text paddingBottom="small" fontSize="small">
            {firstName} {lastName}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {serviceGroupsV2[serviceGroupId].name}
          </Text>
        </Box>
        {(profile || imageUrl) && (
          <Box
            alignItems="center"
            color="bodyFontLightColor"
            justifyContent="flex-end"
            marginRight="medium"
            onClick={() => setState({ showProviderDesc: !showProviderDesc })}
            style={{ cursor: 'pointer' }}
            width="auto"
          >
            <FontAwesomeIcon icon={showProviderDesc ? faTimes : faInfoCircle} size="sm" />
          </Box>
        )}
      </Box>
      {showProviderDesc && (
      <>
        {imageUrl && (
          <Box flexDirection="column" marginBottom="large">
            <Link href={imageUrl} target="_blank">
              <Image backgroundPosition="center top" width="100%" height={182} url={imageUrl} />
            </Link>
          </Box>
        )}
        <Box flexDirection="column" marginBottom="large">
          <Text color="bodyFontLightColor" fontSize="small">
            {profile}
          </Text>
        </Box>
      </>
      )}
    </Box>
  )
}

Provider.propTypes = {
  callbacks: PropTypes.object,
  provider: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imageUrl: PropTypes.string,
    profile: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  serviceGroupId: PropTypes.number,
}

export default Provider
