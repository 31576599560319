import React from 'react'
import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

import { Box, Button, Text } from '@campaignhub/suit-theme'

import CustomView from './components/CustomView'
import ServiceGroup from './components/ServiceGroup'
import ServiceJobStatuses from './components/ServiceJobStatuses'

const Filters = (props) => {
  const { setJobState, jobState } = props
  const { clientFilter, dateFilter, serviceFilter, statusFilter, userFilter } = jobState

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const options = new URLSearchParams([])

  options.append("filterStart", dateFilter.filterStart)
  options.append("filterEnd", dateFilter.filterEnd)
  clientFilter.map(clientFilter => {
    options.append("clients", clientFilter)
  })
  userFilter.map(userFilter => {
    options.append("users", userFilter)
  })

  useReduxAction('serviceJobs', 'loadServiceJobCounts', options, [clientFilter, dateFilter, userFilter])

  return (
    <Box
      backgroundColor="white"
      flexDirection="column"
      height="auto"
      width="280px"
      borderRight="1px solid"
      borderColor="lineColor"
    >
      <Box
        borderBottom="1px solid"
        borderColor="lineColor"
        flexDirection="column"
        marginBottom="large"
        padding="large"
      >
        <Text fontSize="small">Filters</Text>
      </Box>
      <Box flexDirection="column" flexShrink="0" paddingX="large">
        <CustomView setJobState={setJobState} />
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="medium"
            fontAlign="center"
            marginBottom="medium"
          >
            Service Types
          </Text>
        </Box>
        {Object.values(serviceGroups).map((serviceGroup) => (
          <ServiceGroup key={serviceGroup.id} serviceGroup={serviceGroup} setJobState={setJobState} serviceFilter={serviceFilter} />
        ))}
        <ServiceJobStatuses setJobState={setJobState} statusFilter={statusFilter} />
        <Button buttonStyle="ghostUtility" size="small" display="none" onClick={() => setJobState({ serviceFilter: [], statusFilter: [] })}>
          Reset Filters
        </Button>
      </Box>
    </Box>
  )
}

export default Filters