import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/serviceJobUserRole/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/serviceJobUserRole/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/serviceJobUserRole/FETCH_FAILURE'

const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}
export function loadServiceJobUserRoles(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/service-job-user-roles', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB_USER_ROLE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors,
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}
