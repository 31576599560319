import React, { Fragment, useMemo } from 'react'

import {
  Box, Image, NavigationLinkGroup, NestedNavigation, Text,
} from '@campaignhub/suit-theme'

import generateNavigationItems from './utils/generateNavigationItems'

import PlaceholderImageUrl from './assets/placeholder.svg'

const Navigation = () => {
  const navigationItems = useMemo(() => {
    const items = generateNavigationItems()
    return items
  }, [])

  return (
    <NestedNavigation>
      <Image placeholderUrl={PlaceholderImageUrl} width="100%" height={190} />

      <Box flexDirection="column" flexShrink="0" paddingX="large">
        {/* Address */}
        <Box
          borderBottom="1px solid"
          borderColor="lineColor"
          flexDirection="column"
          marginBottom="large"
          paddingY="large"
        >
          <Text fontSize="small">Campaign Address</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            Suburb Name
          </Text>
        </Box>
        {/* End Address */}

        {/* Nav */}
        {navigationItems
          && navigationItems.sections.map(section => (
            <Fragment key={section.key}>
              {section.items
                && section.items.map(item => (
                  <NavigationLinkGroup key={item.key} link={item} secondaryNav sidebarMaximized />
                ))}
            </Fragment>
          ))}
        {/* End Nav */}
      </Box>
    </NestedNavigation>
  )
}

export default Navigation
