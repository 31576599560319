import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import {
  Box, Button, FormField, Text, Image, Link, ListItem,
} from '@campaignhub/suit-theme'

import * as shareLinkActions from '@redux/modules/shareLink'
import ContentHouseLogo from '@components/assets/ch-logo.png'
import custom from '@styles/custom.module.scss'

const defaultState = {
  comment: '',
  commentError: '',
  commenter: 'Client Comment',
  comments: [],
  loading: false,
}

const ClientRejection = (props) => {
  const { details, tokenKey } = props

  const [state, setState] = useSetState(defaultState)
  const {
    comment, commentError, commenter, comments, loading,
  } = state

  const dispatch = useThunkDispatch()

  const addRequestedChanges = async () => {
    setState({ loading: true })
    if (comment.trim() === ''){
      setState({
        commentError: comment.trim() === '' ? 'Required' : '',
        loading: false,
      })
      return
    }

    const data = {
      assetComment: comment,
      assetCommenter: commenter,
      assetCommentTypeId: 3,
      assets: details.Assets.map(asset => asset.Id),
      serviceJobStatusId: details.Status,
      workflowComment: 'Requested Changes from Share Link',
    }

    const { createShareLinkWorkflowChanges } = shareLinkActions

    try {
      const { data: updatedComments } = await dispatch(createShareLinkWorkflowChanges(tokenKey, data))

      if (updatedComments){
        const newAssetComment = {
          Comment: comment,
          CreatedAt: DateTime.now().toISO({ zone: 'Australia/Sydney' }),
        }

        swal.fire({
          icon: 'success',
          title: 'Your request is recorded.',
          text: 'We will send a revised proof in a few.',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          setState({
            comment: '',
            commentError: '',
            comments: [...comments, newAssetComment],
          })
        })
      }
    } catch {
      toast.warning('Something went wrong.')
    }
    setState({ loading: false })
  }

  useEffect(() => {
    const tempComments = []
    details.Assets.map(x => x.AssetComments.map(y => tempComments.push(y)))

    setState({ comments: tempComments })
  }, [details])

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      padding="large"
      width="100%"
    >
      <Box
        flexDirection="column"
        backgroundColor="white"
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        padding="xlarge"
        lineHeight="1.3"
        maxWidth={800}
        marginTop={[100, 60]}
      >
        <Image
          boxProps={{ marginBottom: 'medium' }}
          backgroundPosition="left top"
          width="100%"
          backgroundSize="contain"
          url={ContentHouseLogo}
          height={40}
        />
        <Box flexDirection="column" padding="large" className={custom.scroll}>
          <Text fontSize="small" fontWeight="bold" marginBottom="small">
            Important Note:
          </Text>
          <Text fontSize="small" marginBottom="medium">
            Your comment will automatically be delivered to the production team so that they can immediately work on your changes.
          </Text>
          <FormField padding="medium" style={{ borderRadius: 0, marginBottom: 'medium' }} errorMessage={commentError}>
            <textarea
              onChange={(e) => { setState({ comment: e.target.value }) }}
              placeholder="Type something..."
              style={{
                fontSize: '13px', height: 80, resize: 'none', width: '100%',
              }}
              value={comment}
            />
          </FormField>
          <Box flexDirection="column" marginTop="medium" alignItems="flex-end">
            <Button
              buttonStyle="primaryCreate"
              size="medium"
              width="auto"
              loading={loading}
              onClick={() => addRequestedChanges()}
            >
              Request for Changes
            </Button>
          </Box>
          {comments.length > 0 && (
            <>
              <Text fontSize="small" fontWeight="bold" marginBottom="medium" marginTop="xlarge">
                Requested Changes:
              </Text>
              <Box flexDirection="column" border="1px solid" borderColor="lineColor" borderRadius={5} maxHeight="40vh" overflowY="auto">
                {comments.map(x => (
                  <ListItem
                    boxProps={{
                      padding: 'large',
                      borderBottom: '1px dashed',
                    }}
                    flexDirection="column"
                    disableHover
                  >
                    <Text fontSize="small">{x.Comment}</Text>
                    <Text fontSize="xsmall" color="bodyFontLightColor">
                      {DateTime.fromISO(x.CreatedAt, { zone: 'Australia/Sydney' }).toFormat('LLLL dd, y hh:mm a')}
                    </Text>
                  </ListItem>
                  ))}
              </Box>
            </>
          )}

        </Box>
        <Box flexDirection="row">
          <Text fontSize="xsmall">
            <Link href="https://contenthouse.io" textProps={{ color: 'bodyFontColor' }}>contenthouse.io</Link>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

ClientRejection.propTypes = {
  details: PropTypes.array.isRequired,
  tokenKey: PropTypes.string.isRequired,
}

export default ClientRejection
