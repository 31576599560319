import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import { useSelector } from "react-redux"

import * as workflowActions from '@redux/modules/workflow'

const loadWorkflow = (workflowParams, dispatch) => {
  const { loadWorkflow: loadFn } = workflowActions

  const requestOptions = new URLSearchParams([
    ["includes", "serviceJobStatuses"]
  ])

  return dispatch(loadFn(workflowParams, requestOptions))
}

const useWorkflow = (initWorkflow = {}) => {
  const { entity: workflow } = useLatestEntity(initWorkflow, 'workflows')

  const dispatch = useThunkDispatch()

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.workflows)

  return {
    callbacks: {
      loadWorkflow: (workflowParams) => loadWorkflow(workflowParams, dispatch),
    },
    creating,
    deleting,
    loading,
    updating,
    workflow
  }
}

export default useWorkflow