/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import useCurrentDate from '@hooks/useCurrentDate'
import useCurrentUser from '@hooks/useCurrentUser'
import useNumericParams from '@hooks/useNumericParams'
import useServiceJobsV2 from '@hooks/useServiceJobsV2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock, faStickyNote, faTimesCircle,
} from '@fortawesome/pro-light-svg-icons'

import styles from './styles.module.scss'

const updateServiceJob = (serviceJob, updateFn) => {
  updateFn(serviceJob)
}

const deleteBooking = (booking, bookingsPayload, deleteFn, getCalendarDates, highlighting, serviceJob, updateFn) => {
  deleteFn(booking, bookingsPayload).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])

    if (success){
      const job = serviceJob
      job.bookingType = 'pending'
      job.booking = null

      updateServiceJob(job, updateFn)
      getCalendarDates({})
    }
  })
}

const Schedule = (props) => {
  const {
    bookingState, getCalendarDates, serviceJob, showNote, updateHighlighting,
  } = props
  const {
    booking, bookingType, details, serviceGroupId,
  } = serviceJob
  const { currentUser: { timeZone } } = useCurrentUser()
  const { firstCalendarDay } = useCurrentDate()

  const bookingDate = DateTime.fromISO(booking?.bookingStart, { zone: timeZone })
  const canCancel = bookingDate.startOf('day') >= firstCalendarDay()

  const { id: campaignId } = useNumericParams()

  const {
    campaignServiceJobs,
    callbacks: {
      deleteBooking: deleteFn,
      updateServiceJob: updateFn,
    },
  } = useServiceJobsV2({ campaignId })

  const highlighting = campaignServiceJobs.find(x => x.serviceGroupId === serviceGroupId && x.highlighting)

  function removeSlot(){
    const standByBookingWarning = 'This action will cancel the standby appointment.<br/>Do you wish to proceed?<br/><br/>'
    const bookingWarning = 'By cancelling this booking, you acknowledge that you may not be able to secure a future booking within your desired timeframe.<br/><br/>'
      + 'Frequent cancellations will result in reduced booking availability for future appointments.'
      + 'Before rebooking, please ensure you have checked vendor and agent availability, and that the property is photo-ready.'
      + 'To ensure we provide the best service for our customers, cancellation reports are being monitored for frequent rebooking.<br/><br/>'
    const style = 'margin-bottom:10px;width:300px;font-size:18px;font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen , Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
    const cancellationElements = `<select id="cancelOptions" style='${style}'>
                                    <option value='0'>Select Reason for Cancellation</option>
                                    <option value='1'>The property is not ready</option>
                                    <option value='2'>The vendor asked to cancel</option>
                                    <option value='3'>The agent had a change of plans</option>
                                    <option value='4'>Weather not ideal</option>
                                    <option value='5'>Others: Please specify</option>
                                  </select><br/>
                                  <input type="text" id='otherReason' style='${style}'></input>`
    const cancellationForm = swal.mixin({
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
      showCancelButton: true,
      html: `${bookingType === 'standby' ? standByBookingWarning
            : bookingWarning}${cancellationElements}`,
      didOpen: () => {
        const options = document.getElementById('cancelOptions')
        const otherReason = document.getElementById('otherReason')
        const confirmBtn = document.getElementsByClassName('swal2-confirm swal2-styled')

        confirmBtn[0].setAttribute('disabled', 'disabled')
        otherReason.style.display = 'none'

        options.addEventListener('change', (e) => {
          if (e.target.value === '5'){
            otherReason.style.display = null
            if (otherReason.value.length >= 3){
              confirmBtn[0].removeAttribute('disabled')
            } else {
              confirmBtn[0].setAttribute('disabled', 'disabled')
            }
          } else if (e.target.value === '0'){
            confirmBtn[0].setAttribute('disabled', 'disabled')
            otherReason.style.display = 'none'
          } else {
            otherReason.style.display = 'none'
            confirmBtn[0].removeAttribute('disabled')
          }
        })

        otherReason.addEventListener('input', (e) => {
          if (e.target.value.length >= 3){
            confirmBtn[0].removeAttribute('disabled')
          } else {
            confirmBtn[0].setAttribute('disabled', 'disabled')
          }
        })
      },
    })

    cancellationForm.fire({
      title: `Cancel ${bookingType === 'standby' ? 'Standby' : ''} Booking`,
      icon: 'warning',
    }).then(({ value }) => {
      if (value){
        const options = document.getElementById('cancelOptions')
        const otherReason = document.getElementById('otherReason')
        const bookingsPayload = { cancellationType: options.value, cancellationReason: otherReason.value }
        deleteBooking(booking, bookingsPayload, deleteFn, getCalendarDates, highlighting, serviceJob, updateFn)
      }
    })
  }

  return (
    <Box
      flexDirection="row"
      lineHeight="1.3"
      marginBottom="large"
    >
      <Box
        alignItems="center"
        color="bodyFontLightColor"
        justifyContent="center"
        marginRight="large"
        width="auto"
      >
        <FontAwesomeIcon icon={faClock} />
      </Box>
      <Box flexDirection="column">
        {serviceJob.bookingType === 'pending' && (
          <Link
            className={serviceJob.highlighting ? styles.selected : styles.pending}
            disabled={serviceJob.highlighting}
            disabledUnderline
            onClick={() => updateHighlighting()}
            textProps={{
              fontSize: 'xsmall',
            }}
          >
            Book on Calendar
          </Link>
        )}
        <Text fontSize="small" marginBottom="small">
          {booking ? bookingDate.toFormat('cccc LLLL dd, y') : ''}
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {booking ? bookingDate.toFormat('hh:mma').toLowerCase() : ''}
          {` (${details?.find(x => x.entityFieldType.name === 'BookingDuration').value || 0} minutes)`}
        </Text>
      </Box>
      {details?.some(x => x.entityFieldType.name === 'SupplierNote') && (
        <Box
          alignItems="center"
          color="blue"
          justifyContent="center"
          marginRight="medium"
          onClick={() => bookingState({ showNote: !showNote })}
          style={{ cursor: 'pointer' }}
          width="auto"
        >
          <FontAwesomeIcon icon={faStickyNote} />
        </Box>
      )}
      {['booked', 'standby'].includes(bookingType) && canCancel && (
        <Box
          alignItems="center"
          color="red"
          justifyContent="center"
          onClick={() => removeSlot()}
          style={{ cursor: 'pointer' }}
          width="auto"
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </Box>
      )}
    </Box>
  )
}

Schedule.propTypes = {
  bookingState: PropTypes.func,
  getCalendarDates: PropTypes.func,
  serviceJob: PropTypes.object.isRequired,
  showNote: PropTypes.bool,
  updateHighlighting: PropTypes.func,
}

export default Schedule
