import React from 'react'
import PropTypes from 'prop-types'
import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import BookingCampaignSearch from '@components/BookingCampaignSearch'
import LoggedInUser from '@components/TopBar/components/LoggedInUser'

import MainNavigation from '@sections/Client/components/MainNavigation'

import ProofingRoutes from './routes'

const ProofingOverview = (props) => {
  const { showProofingTab } = props
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<BookingCampaignSearch />}
      />

      <MainNavigation showProofingTab={showProofingTab} />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <ProofingRoutes />
      </MainContent>
    </>
  )
}

ProofingOverview.propTypes = {
  showProofingTab: PropTypes.bool,
}

export default ProofingOverview
