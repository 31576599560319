import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MainNavigation as BaseNavigation } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import systemBrandPayload from '@components/systemBrands/default'
import generateMainNavigationItems from '@sections/Production/utils/generateMainNavigationItems'

const MainNavigation = (props) => {
  const { minimized, nestedNavigation } = props

  const navigationItems = useMemo(() => generateMainNavigationItems(), [])

  const { callbacks: { toggleNavigation }, showNavigation } = useMainNavigation()

  return (
    <BaseNavigation
      callbacks={{ toggleSidebar: toggleNavigation }}
      minimized={minimized}
      navigationItems={navigationItems}
      nestedNavigation={nestedNavigation}
      nestedNavigationWidth={240}
      showNavigation={showNavigation}
      systemBrandPayload={systemBrandPayload}
    />
  )
}

MainNavigation.propTypes = {
  minimized: PropTypes.bool,
  nestedNavigation: PropTypes.node,
}

export default MainNavigation
