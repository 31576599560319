import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Box,
  Button,
  Columns,
  DashboardModule,
  ModuleBlankState,
  PageHeader,
} from '@campaignhub/suit-theme'

import TasksImageUrl from './assets/tasks.svg'

const Overview = () => {
  const { logout } = useAuth0()

  return (
    <>
      <PageHeader
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title="Default Dashboard"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns
          boxProps={{ marginTop: 'large' }}
          flexDirection={['column', 'column', 'row']}
        >
          <Columns.Main>
            <DashboardModule title="No role assigned">
              <ModuleBlankState imageUrl={TasksImageUrl}>
                <ModuleBlankState.Paragraph>
                  Please contact your administrator to assign your role.
                </ModuleBlankState.Paragraph>
              </ModuleBlankState>
            </DashboardModule>
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Button buttonStyle="secondary" onClick={() => logout({ returnTo: window.location.origin })} size="medium">
                  Log Out
                </Button>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Overview
