import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, ListItem, LoadingBubbles, SelectBox, Text,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useServiceJob from '@hooks/useServiceJob'

import { getEntityByName } from '@functions/getEntityByName'

import ClientComments from './components/ClientComments'
import ProofingNotes from './components/ProofingNotes'

const assignServiceJobStatus = (serviceJobParam, createFn, currentJobAssets, toggleUpdateCurrentAssets) => {
  createFn(serviceJobParam, {}, false).then(({ errors, success }) => {
    if (!success && errors){
      return toast.warning(errors[0])
    }

    let tempAssets = currentJobAssets
    if (serviceJobParam.serviceJobStatusId === 170){
      tempAssets = tempAssets.map(x => ({ ...x, serviceJobStatusId: 170 }))
    }

    return toggleUpdateCurrentAssets(tempAssets)
  })
}

const defaultState = {
  selectedJobId: null,
}

const RejectApprove = (props) => {
  const { serviceJobs, serviceJobsLoading } = props

  const {
    callbacks, currentAssets, selectedServiceJob,
  } = useContext(PageContext)

  const { toggleUpdateCurrentAssets, toggleSelectedServiceJob } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { selectedJobId } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const {
    callbacks: {
      assignServiceJobStatus: createFn,
    },
    updating,
    loading,
  } = useServiceJob({ id: selectedServiceJob?.id })

  useEffect(() => {
    setState({ selectedJobId: serviceJobs.length > 0 ? serviceJobs[0].id : '' })
  }, [serviceJobs])

  useEffect(() => {
    const job = serviceJobs.find(x => x.id === selectedJobId)
    if (job) toggleSelectedServiceJob(job)
  }, [selectedJobId])

  const updateStatusModal = (statusId) => {
    const assetIds = currentAssets.map(x => x.id) || []
    const serviceJobParam = {
      id: selectedServiceJob.id,
      serviceJobStatusId: statusId,
      workflowComment: 'Approved All from CH',
      assets: assetIds,
    }

    swal.fire({
      title: 'Approve All',
      html: 'This action will approve all assets and will complete the job.'
      + '</br>Do you still wish to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        assignServiceJobStatus(serviceJobParam, createFn, currentAssets, toggleUpdateCurrentAssets)
      }
    })
  }

  return (
    <Box flexDirection="column" alignItems="center" width="100%" overflowY="auto" maxHeight="95vh">
      <ListItem
        boxProps={{ borderBottom: '1px solid', padding: 'large' }}
        flexDirection="column"
        disableHover
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="small">
          Item
        </Text>
        {!serviceJobsLoading && (
          <SelectBox
            items={serviceJobs.map(x => ({ key: x.id, title: x.name }))}
            selectedItemKey={selectedJobId}
            callbacks={{ selectItem: ({ key }) => setState({ selectedJobId: key }) }}
          />
        )}
        {serviceJobsLoading && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />}
      </ListItem>
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        disableHover
      >
        <ClientComments />
        <ProofingNotes />
      </ListItem>
      <ListItem
        boxProps={{ borderBottom: '1px solid', padding: 'large' }}
        flexDirection="column"
        disableHover
      >
        <Button
          buttonStyle="ghostCreate"
          loading={updating || loading || serviceJobsLoading}
          onClick={() => updateStatusModal(getEntityByName(serviceJobStatuses, 'Approved')?.id)}
          size="medium"
          width="100%"
        >
          Approve All
        </Button>
      </ListItem>
    </Box>
  )
}

RejectApprove.propTypes = {
  serviceJobs: PropTypes.array.isRequired,
  serviceJobsLoading: PropTypes.bool,
}

export default RejectApprove
