/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import PageContext from '@contexts/pageContext'
import { useSetState } from '@campaignhub/react-hooks'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, Text,
} from '@campaignhub/suit-theme'

import useServiceJobNotes from '@hooks/useServiceJobNotes'

const createServiceJobNote = (serviceJobNote, createFn, setState) => {
  createFn(serviceJobNote).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])

    return setState({ saving: false })
  })
}

const updateServiceJobNote = (serviceJobNote, updateFn, setState) => {
  updateFn(serviceJobNote).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])

    return setState({ saving: false })
  })
}

const defaultState = {
  saving: false,
  contentOnChange: '',
}

const Note = (props) => {
  const { serviceJobNoteTypeId } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange, saving } = state
  const { serviceJobId, currentUser } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobNotes } = entities

  const serviceJobNote = Object.values(serviceJobNotes).find(x => x.serviceJobId === serviceJobId && x.serviceJobNoteType.id === serviceJobNoteTypeId)

  const serviceJobNotePayload = useServiceJobNotes()
  const {
    callbacks: {
      createServiceJobNote: createFn,
      updateServiceJobNote: updateFn,
     },
  } = serviceJobNotePayload

  useEffect(() => {
    setState({ contentOnChange: serviceJobNote?.content })
  }, [serviceJobNote])

  const saveNote = () => {
    setState({ saving: true })
    const newNote = {
      content: contentOnChange,
      serviceJobId,
      userId: currentUser.id,
      serviceJobNoteTypeId,
    }

    if (!serviceJobNote){
      createServiceJobNote(newNote, createFn, setState)
    } else {
      newNote.id = serviceJobNote.id
      updateServiceJobNote(newNote, updateFn, setState)
    }
  }

  return (
    <Box flexDirection="column">
      <Text fontSize="small" paddingBottom="small">
        EDITOR&apos;S NOTE
      </Text>
      <FormField>
        <textarea
          onChange={e => setState({ contentOnChange: e.target.value })}
          placeholder="Type something..."
          value={contentOnChange}
          style={{ height: '90px' }}
          placeholdertext="Enter message here..."
        />
        <Button
          buttonStyle="secondaryCreate"
          height={37}
          loading={saving}
          marginLeft="auto"
          marginTop="small"
          onClick={saveNote}
          size="medium"
          width={68}
        >
          Save
        </Button>
      </FormField>
    </Box>
  )
}

Note.propTypes = {
  serviceJobNoteTypeId: PropTypes.number.isRequired,
}

export default Note
