/* eslint-disable space-before-blocks */
import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const CREATE_REQUEST = 'contenthouse/campaign/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/campaign/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/campaign/CREATE_FAILURE'

const FETCH_REQUEST = 'contenthouse/campaign/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/campaign/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/campaign/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/campaign/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/campaign/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/campaign/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function loadCampaignV2(campaignId, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.campaigns.loadedIds]

    if (campaignId && !loadedIds.includes(campaignId)) {
      loadedIds.push(campaignId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/v2/campaigns/${campaignId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateCampaignV2(campaign) {
  return (dispatch) => {
    dispatch(updateRequest())

    const promise = new Promise((resolve) => {
      const normalizedJson = normalize(campaign, Schemas.CAMPAIGN)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())

      return resolve({ success: true, result: campaign })
    })

    return promise
  }
}

export function assignAdditionalInviteesV2(campaignInvitee, options = {}) {
  const config = {
    method: 'POST',
    data: campaignInvitee,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v2/invitees/additional', options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function assignInviteesV2(campaignInvitee, options = {}) {
  const config = {
    method: 'POST',
    data: campaignInvitee,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v2/invitees/save', options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
