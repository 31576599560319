import React from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, Text, Image, Link,
} from '@campaignhub/suit-theme'

import ContentHouseLogo from '@components/assets/ch-logo.png'

const FlexibleContact = (props) => {
  const { details } = props

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      padding="large"
      width="100%"
    >
      <Box
        flexDirection="column"
        backgroundColor="white"
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        padding="xlarge"
        lineHeight="1.3"
        maxWidth={800}
        marginTop={[100, 60]}
      >
        <Image
          boxProps={{ marginBottom: 'medium' }}
          backgroundPosition="left top"
          width="100%"
          backgroundSize="contain"
          url={ContentHouseLogo}
          height={40}
        />
        <Box flexDirection="column" padding="large">
          {details.map(appointment => (
            <Box key={appointment.jobName} flexDirection="column" paddingBottom="xlarge">
              <Text fontSize="small" fontWeight="bold" marginBottom="small">
                {appointment.JobName || '-'}
              </Text>
              <Text fontSize="small">
                Appointment Date and Time: {DateTime.fromISO(appointment.DateService, { zone: 'Australia/Sydney' }).toFormat('LLLL dd, y hh:mm a')}
              </Text>
              <Text fontSize="small">
                Service Provider: {appointment.ProviderName || '-'}
              </Text>
              <Text fontSize="small">
                Service Contact Number:&nbsp;
                {appointment.ProviderContactNumber ? (
                  <Link
                    href={`tel:${appointment.ProviderContactNumber}`}
                    textProps={{ color: 'bodyFontColor' }}
                  >
                    {appointment.ProviderContactNumber || '-'}
                  </Link>
                ) : '-'}
              </Text>
            </Box>
        ))}
        </Box>
        <Box flexDirection="row">
          <Text fontSize="xsmall">
            <Link href="https://contenthouse.io" textProps={{ color: 'bodyFontColor' }}>contenthouse.io</Link>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

FlexibleContact.propTypes = {
  details: PropTypes.array,
}

export default FlexibleContact
