import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import * as serviceJobActions from '@redux/modules/serviceJob'

const watchEntityKeys = ['serviceJobUsers']

const assignServiceJobStatus = (serviceJobParam, dispatch, requestOptions, refresh = true) => {
  const { assignServiceJobStatus: createFn } = serviceJobActions

  const includes = ['service', 'campaign', 'client', 'details', 'histories', 'users']
  const serviceJobOptions = {
    includes: includes.join(','),
  }
  return dispatch(createFn(serviceJobParam, requestOptions, serviceJobOptions, refresh))
}

const useServiceJob = (initServiceJob = {}) => {
  const { entity: serviceJob } = useLatestEntity(initServiceJob, 'serviceJobs')

  const {
    updatedEntities: { serviceJobUsers: serviceJobUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.serviceJobs)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobUsers } = entities

  const filteredServiceJobUsers = useMemo(() => {
    const filtered = Object.values(serviceJobUsers).filter(x => x.serviceJobId === serviceJob.id)

    return filtered
  }, [serviceJobUsersUpdatedAt, serviceJob.id])

  return {
    callbacks: {
      assignServiceJobStatus: (serviceJobParam, requestOptions, refresh) => assignServiceJobStatus(serviceJobParam, dispatch, requestOptions, refresh),
    },
    creating,
    deleting,
    filteredServiceJobUsers,
    loading,
    serviceJob,
    updating,
  }
}

export default useServiceJob
