import {
  faCalendar, faFileCheck, faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = (props) => {
  const { showProofingTab } = props

  return {
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            href: '#/',
            icon: faTachometer,
            key: 'dashboard',
            title: 'Dashboard',
            visible: true,
          },
        ],
      },
      {
        key: 'overviews',
        title: 'Overviews',
        visible: true,
        items: [
          {
            href: '#/overviews/bookings',
            icon: faCalendar,
            key: 'bookings',
            title: 'Bookings',
            visible: true,
          },
          {
            ...showProofingTab && {
              href: '#/overviews/proofing',
              icon: faFileCheck,
              key: 'proofing',
              title: 'Proofing',
              visible: true,
            },
          },
        ],
      },
    ],
  }
}

export default generateMainNavigationItems
