import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { getAccessToken } from '@functions/accessToken'
import { baseURL } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    requestOptions = {},
    maxTotalFileSize,
    callbacks,
    fieldName,
    endpoint,
    loadSearchParams,
  } = options

  const uploadedList = []
  const { addRecentlyUploadedBatch, loadFn } = callbacks

  const onComplete = async () => {
    let counter = uploadedList.length
    const newList = []

    function updateList(){
      counter -= 1
      if (counter === 0){
        uploadedList.splice(0, uploadedList.length)
        addRecentlyUploadedBatch(newList)
      }
    }

    async function addToNewList(id, callback){
      const promise = await loadFn(id, new URLSearchParams(loadSearchParams))
      newList.push(promise.result)
      callback()
    }

    await uploadedList.forEach(async (id) => {
      addToNewList(id, updateList)
    })
  }

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: true,
    method: 'post',
    restrictions: {
      allowedFileTypes,
      maxTotalFileSize,
    },
    allowMultipleUploadBatches: true,
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/${endpoint}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      formData: true,
      fieldName,
      timeout: 0,
      getResponseError(){
        toast.warning('Failed to upload')
      },
    })
    .on('restriction-failed', (file, error) => {
      toast.warning(`${error}.  File: ${file.name}`)
    })
    .on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        ...requestOptions,
      })
    })
    .on('upload-success', (file, response) => {
      uploadedList.push(response.body)
    })
    .on('upload-error', (file) => {
      toast.warning(`Failed to upload ${file.name}`)
    })
    .on('complete', async () => {
        await onComplete()
        uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions']
  const optionKeys = Object.keys(options)

  return requiredOptions.every(key => optionKeys.includes(key))
}

function useUppyUpload(options = {}){
  // eslint-disable-next-line consistent-return
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUpload
