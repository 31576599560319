import { useParams } from 'react-router-dom'

function useNumericParams(){
  const params = useParams()

  const numericParams = Object.keys(params).reduce((acc, paramKey) => {
    const value = params[paramKey] ? Number(params[paramKey]) : null

    if (value && !Number.isNaN(value)){
      acc[paramKey] = value
    }

    return acc
  }, {})

  return numericParams
}

export default useNumericParams
