import React from 'react'
import Carousel from 'react-elastic-carousel'
import PropTypes from 'prop-types'

import { Box, Image } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

const ImageSlider = (props) => {
  const { assetSetState, contents, selectedAssetId } = props

  return (
    <Box flexDirection="column">
      <Carousel
        breakPoints={[
          { width: 1, itemsToShow: 3 },
          { width: 550, itemsToShow: 5, itemsToScroll: 5 },
          { width: 850, itemsToShow: 8, itemsToScroll: 8 },
          { width: 1000, itemsToShow: 10, itemsToScroll: 10 },
        ]}
        pagination={false}
        renderArrow={({ type, onClick, isEdge }) => (
          <Box
            alignItems="center"
            color="bodyFontColor"
            height={80}
            onClick={onClick}
            opacity={isEdge ? 0.5 : 1}
            pointerEvents={isEdge ? 'none' : 'auto'}
            styles={{
              cursor: isEdge ? 'none' : 'pointer',
            }}
            width={20}
          >
            <FontAwesomeIcon icon={type === 'PREV' ? faChevronLeft : faChevronRight} />
          </Box>
        )}
        showEmptySlots
      >
        {contents.map(content => (
          <Box key={content.id} onClick={() => assetSetState({ selectedAsset: content })}>
            <Image
              boxProps={{
                backgroundColor: 'hoverLightGrey',
                border: '2px solid',
                borderColor: selectedAssetId === content.id ? 'blue' : 'white',
              }}
              height={80}
              url={content.previewUrl}
              width={110}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

ImageSlider.propTypes = {
  assetSetState: PropTypes.func,
  contents: PropTypes.array,
  selectedAssetId: PropTypes.number,
}

export default ImageSlider
