import {
  faLifeRing,
  faList,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Dashboard',
          visible: true,
        },
      ],
    },
    {
      key: 'overviews',
      title: 'Overviews',
      visible: true,
      items: [
        {
          href: '#/overviews/service-jobs',
          icon: faList,
          key: 'jobs',
          title: 'Jobs',
          visible: true,
        },
      ],
    },
    {
      key: 'support',
      title: 'Support',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faLifeRing,
          key: 'training',
          title: 'Training',
          visible: false,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
