import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/bookingsCampaignSearch/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/bookingsCampaignSearch/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/bookingsCampaignSearch/FETCH_FAILURE'

// Initial State
const initialState = {
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadBookingCampaignSearch(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v2/campaigns/bookings-overview', options)
      .then(({ data }) => {
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
