import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import {
 Box, Button, Columns, PageHeader,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft, faCalendar, faFileCheck,
} from '@fortawesome/pro-light-svg-icons'

import CampaignBookings from './components/CampaignBookings'

const defaultState = {
  tabBarItems: [{
    href: '#/overviews/bookings',
    icon: faCalendar,
    key: 'bookings',
    title: 'BOOKINGS',
  }],
}
const Overview = (props) => {
  const { showProofingTab } = props

  const [state, setState] = useSetState(defaultState)
  const { tabBarItems } = state

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'redirect',
  ])

  const goBack = () => {
    if (redirect){
      window.location.href = redirect
      return
    }

    window.location.hash = ''
  }

  useEffect(() => {
    const proofingTab = {
      href: '#/overviews/proofing',
      icon: faFileCheck,
      key: 'proofing',
      title: 'PROOFING',
    }

    if (showProofingTab){
      setState({ tabBarItems: [...tabBarItems, proofingTab] })
    }
  }, [showProofingTab])

  return (
    <>
      <PageHeader
        actionContent={redirect ? (
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
            onClick={() => goBack()}
            size="medium"
          >
            Back
          </Button>
        ) : null}
        activeTabBarItemKey="bookings"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={tabBarItems}
        title="Overview"
        invertColors
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <CampaignBookings />
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

Overview.propTypes = {
  showProofingTab: PropTypes.bool,
}

export default Overview
