import { digObject } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as bookingCampaignSearchActions from '@redux/modules/bookingCampaignSearch'

const generateUrl = (entity) => {
  const { campaignId } = entity || {}

  return `#/campaigns/${campaignId}/bookings/`
}

const mapResults = (result, generateUrlFn) => {
  const presentResults = []
  const emptyResults = []

  if (result){
    if (result.length){
      presentResults.push({
        items: result.map(booking => ({
          href: generateUrlFn(booking),
          id: booking.campaignId,
          key: booking.campaignId,
          title: booking.name,
          subtitle: null,
        })),
        title: 'Bookings',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Bookings found',
          priority: 0,
        }],
        title: 'Bookings',
      })
    }
  }

  return { groups: [...presentResults, ...emptyResults] }
}

const bookingCampaignSearch = (options, dispatch) => {
  const string = digObject(options, 'string', '')

  if (string.length > 2){
    const { loadBookingCampaignSearch: loadBookingCampaignSearchFn } = bookingCampaignSearchActions

    const params = { search: string }

    return dispatch(loadBookingCampaignSearchFn(params))
  }
  return Promise.resolve({ success: false })
}

const useBookingCampaignSearch = () => {
  const generateUrlFn = entity => generateUrl(entity)

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      bookingCampaignSearch: options => bookingCampaignSearch(options, dispatch),
      mapResults: data => mapResults(data, generateUrlFn),
    },
  }
}

export default useBookingCampaignSearch
