import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useUsers = () => {
  const entities = useSelector(reduxState => reduxState.entities)
  const { users } = entities

  const options = new URLSearchParams([
    ['includes', 'userRole'],
  ])

  useReduxAction('users', 'loadUsers', options, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  return {
    users,
  }
}

export default useUsers
