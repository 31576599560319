const state = {
  id: null,
  name: '',
  entityType: 'serviceJob',
  filterStart: '',
  filterEnd: '',
  services: [],
  serviceJobStatuses: [],
  clients: [],
  users: []
}

export const requiredFields = [
  { key: 'name' }
]

export default state
