import React, { useContext } from 'react'

import PageContext from '@contexts/pageContext'
import useReduxAction from '@hooks/useReduxAction'
import serviceJobNoteTypes from '@functions/serviceJobNoteTypes'

import { Box, ListItem } from '@campaignhub/suit-theme'

// import Checklist from './components/Checklist'
import Note from './components/Note'

const EditorTab = () => {
  const { serviceJobId, currentUser } = useContext(PageContext)

  useReduxAction('serviceJobNotes', 'loadServiceJobNotes', new URLSearchParams([
    ['serviceJobs', serviceJobId],
    ['serviceJobNoteTypes', serviceJobNoteTypes.studio.id],
    ['users', currentUser.id],
    ['includes', 'serviceJobNoteType'],
    ['includes', 'user'],
  ]), [serviceJobId])

  return (
    <Box flexDirection="column" alignItems="center" width="100%">
      {/* <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        disableHover
        style={{ cursor: 'pointer' }}
      >
        <Checklist />
      </ListItem> */}
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        disableHover
        style={{ cursor: 'pointer' }}
      >
        <Note serviceJobNoteTypeId={serviceJobNoteTypes.studio.id} />
      </ListItem>
    </Box>
  )
}

export default EditorTab
