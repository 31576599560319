import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Button, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useCurrentDate from '@hooks/useCurrentDate'
import useCampaignV2 from '@hooks/useCampaignV2'
import useNumericParams from '@hooks/useNumericParams'
import useServiceJobsV2 from '@hooks/useServiceJobsV2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

const updateServiceJob = (serviceJob, updateFn) => {
  updateFn(serviceJob)
}

const createBookings = (payload, createFn, getCalendarDates, setState, updateFn) => {
  const bookings = payload.map(({ bookingType, ...booking }) => booking)
  setState({ creating: true })

  createFn(bookings).then(({ success, errors }) => {
    if (!success) errors.map(error => toast.warning(error))

    if (success){
      bookings.map((booking) => {
        const b = booking
        b.bookingType = 'booked'
        updateServiceJob(b, updateFn)
        return b
      })
      getCalendarDates({})
    }

    setState({ creating: false })
  })
}

const defaultState = {
  creating: false,
}

const ConfirmBooking = () => {
  const { callbacks: { toggleGetCalendarDates }, campaignServiceJobs } = useContext(PageContext)

  const [state, setState] = useSetState(defaultState)
  const { creating } = state

  const { id: campaignId } = useNumericParams()

  const entityReducer = useSelector(reduxState => reduxState.availableSlot)
  const { result: availableSlots } = entityReducer

  const { callbacks: { validateBookings } } = useCurrentDate()

  const {
    callbacks: {
      createBookings: createFn,
      updateServiceJob: updateFn,
    },
  } = useServiceJobsV2({ campaignId })

  const { campaign: { area, property } } = useCampaignV2({ id: campaignId })

  const bookingPayload = campaignServiceJobs.filter(x => ['selectedBook', 'selectedStandby'].includes(x.bookingType)).map(x => ({
    id: x.id,
    serviceJobId: x.id,
    areaId: area.id,
    bookingType: x.bookingType,
    bookingStart: x.booking?.bookingStart,
    bookingEnd: x.booking?.bookingEnd,
    travelTime: property.defaultTravelTime,
    user: ({
      username: x.bookingType !== 'selectedStandby' ? x.selectedUsers?.length ? x.selectedUsers[0]?.username
      : availableSlots.find(y => y.serviceId === x.serviceId
        && y.user.employment === 'Locked_In_Contract'
        && y.start <= x.booking?.bookingStart && y.end >= x.booking?.bookingEnd)
      ? availableSlots.find(y => y.serviceId === x.serviceId
        && y.user.employment === 'Locked_In_Contract'
        && y.start <= x.booking?.bookingStart && y.end >= x.booking?.bookingEnd)?.user.username
      : availableSlots.find(y => y.serviceId === x.serviceId
        && y.user.employment === 'Full_Time'
        && y.start <= x.booking?.bookingStart && y.end >= x.booking?.bookingEnd)
      ? availableSlots.find(y => y.serviceId === x.serviceId
        && y.user.employment === 'Full_Time'
        && y.start <= x.booking?.bookingStart && y.end >= x.booking?.bookingEnd)?.user.username
      : availableSlots.find(y => y.serviceId === x.serviceId
        && y.user.employment === 'Contract'
        && y.start <= x.booking?.bookingStart && y.end >= x.booking?.bookingEnd)?.user.username
      : '',
    }),
    selectedUser: ({
      username: x.selectedUsers?.length ? x.selectedUsers[0]?.username : null,
    }),
  }))

  function confirmPrompt(){
    const valid = validateBookings(bookingPayload.map(x => x.bookingStart))
    swal.fire({
      title: valid ? 'Confirm Booking' : 'Unavailable Booking',
      html: valid ? 'Clicking this button will confirm your booking. Only confirm if you guarantee:</br></br>'
      + '<li>Vendor Availability</li>'
      + '<li>Agent Availability</li>'
      + '<li>The property is photo-ready</br></br>'
      + 'Future cancellations may result in less booking availability. Do you wish to proceed?'
      : 'One or more of your bookings are no longer available, please refresh this page and try again.',
      confirmButtonColor: '#3fc3ee',
      confirmButtonText: 'Yes',
      icon: valid ? 'info' : 'warning',
      showCancelButton: true,
      showClass: 'slide-from-top',
      showConfirmButton: valid,
    }).then(({ value }) => {
      if (value){
        createBookings(bookingPayload, createFn, toggleGetCalendarDates, setState, updateFn)
      }
    })
  }

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      marginBottom="large"
      padding="large"
      width="100%"
    >
      <Box flexDirection="row" alignItems="center">
        <Text color="bodyFontLightColor" fontSize="small" paddingRight="medium">
          Confirm a time and provider for each service
        </Text>
        <Box alignItems="center" flexShrink="0" justifyContent="flexEnd" marginLeft="auto" width="auto">
          <Button
            buttonStyle="primaryEdit"
            disabled={!campaignServiceJobs?.filter(x => ['selectedBook', 'selectedStandby'].includes(x.bookingType)).length}
            icon={<FontAwesomeIcon icon={faCheck} />}
            onClick={() => confirmPrompt()}
            loading={creating}
            size="medium"
            title={!campaignServiceJobs?.filter(x => ['selectedBook', 'selectedStandby'].includes(x.bookingType)).length ? 'Nothing to confirm' : ''}
          >
            Confirm Booking
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmBooking
