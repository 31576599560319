import React, { useRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'

import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  border, color, layout, space, typography
} from 'styled-system'

import useInfiniteJobScroll from '@hooks/useInfiniteJobScroll'
import {
  Box,
  LoadingBubbles,
  TabBar,
  Text
} from '@campaignhub/suit-theme'

import Cell from './components/Cell'
import generateServiceJobColumn from './utils/generateServiceJobColumns'
import { getEntityByName } from '@functions/getEntityByName'

const StyledTable = styled.table`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${typography}

  tr:last-child td {
    border-bottom: none;
  }
`

const getColumnValue = (column, item) => {
  const { dataKey, render } = column
  const columnValue = item[dataKey]

  if (render) {
    return render(columnValue, item)
  }

  return columnValue
}

const defaultState = {
  pageNumber: 1,
  serviceJobColumns: []
}

const Services = (props) => {
  const { setJobState, jobState } = props
  const { activeTabBarItemKey, clientFilter, dateFilter, serviceFilter, statusFilter, userFilter, userToAssign } = jobState

  const [state, setState] = useSetState(defaultState)
  const { pageNumber, serviceJobColumns } = state

  const {
    jobs,
    hasMore,
    loading,
    error,
    totalJobs
  } = useInfiniteJobScroll(pageNumber, jobState, setJobState)

  useEffect(() => {
    setState({ serviceJobColumns: generateServiceJobColumn(activeTabBarItemKey) })
  }, [activeTabBarItemKey])

  useEffect(() => {
    setJobState({ setTotal: totalJobs })
  }, [totalJobs])

  useEffect(() => {
    setState({ pageNumber: 1, jobAssigned: null, userToAssign: null })
  }, [activeTabBarItemKey, clientFilter, dateFilter, serviceFilter, statusFilter, userFilter])

  const observer = useRef()
  const lastBookElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) {
      observer.current.disconnect()
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setState({ pageNumber: pageNumber + 1 })
      }
    })

    if (node) {
      observer.current.observe(node)
    }
  }, [loading, hasMore])

  function setActiveTabBarItemKey(key, filterStatuses, takeStatuses, takeRole) {
    setState({ pageNumber: 1 })
    setJobState({
      activeTabBarItemKey: {
        key: key,
        filterStatuses: filterStatuses,
        takeStatuses: takeStatuses,
        takeRole: takeRole
      }
    })
  }

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const qcTakeStatuses = [
    getEntityByName(serviceJobStatuses, "ContentAwaitingQC")?.id
  ]
  const qcFilterStatuses = [...qcTakeStatuses, getEntityByName(serviceJobStatuses, "ContentInQC")?.id];

  const rejectionTakeStatuses = [
    getEntityByName(serviceJobStatuses, "ContentSentForVerification")?.id,
    getEntityByName(serviceJobStatuses, "ContentRejectToStudio")?.id,
    getEntityByName(serviceJobStatuses, "ContentInRejection")?.id,
    getEntityByName(serviceJobStatuses, "ContentChangeRequest")?.id
  ]
  const rejectionFilterStatuses = [...rejectionTakeStatuses, getEntityByName(serviceJobStatuses, "ContentInRejection")?.id];

  return (
    <>
      <TabBar
        items={[
          {
            key: 'jobs',
            title: 'Jobs',
            onClick: () => setActiveTabBarItemKey('jobs', [], [])
          },
          {
            key: 'qc',
            title: 'QC',
            onClick: () => setActiveTabBarItemKey('qc', qcFilterStatuses, qcTakeStatuses, 2)
          },
          {
            key: 'rejection',
            title: 'Rejection',
            onClick: () => setActiveTabBarItemKey('rejection', rejectionFilterStatuses, rejectionTakeStatuses, 3)
          }
        ]}
        selectedItemKey={activeTabBarItemKey.key}
        invertColors={true}
      />
      <Box
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        display="block"
        overflow="auto hidden"
        textAlign="right"
      >
        <StyledTable
          backgroundColor="white"
          borderCollapse="separate"
          borderRadius={5}
          fontSize="small"
          minWidth="100%"
          width={1000}
          tableLayout="fixed"
        >
          <thead>
            <tr>
              {serviceJobColumns.map((column) => {
                const { cellProps, dataKey, title } = column

                return (
                  <Cell key={dataKey} variant="header" {...cellProps} style={{verticalAlign: 'middle'}}>
                    {title}
                  </Cell>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {jobs.map((job, index) => {
              if (jobs.length === index + 1)
                return <tr key={job.id} ref={lastBookElementRef}>
                  {serviceJobColumns.map((column) => {
                    const { cellProps } = column

                    return (
                      <Cell variant="body" key={column.dataKey} {...cellProps} style={{verticalAlign: 'middle'}}>
                        {getColumnValue(column, job)}
                      </Cell>
                    )
                  })}
                </tr>
              else
                return <tr key={job.id}>
                  {serviceJobColumns.map((column) => {
                    return (
                      <Cell variant="body" key={column.dataKey} style={{verticalAlign: 'middle'}}>
                        {getColumnValue(column, job)}
                      </Cell>
                    )
                  })}
                </tr>
            })}
          </tbody>
        </StyledTable>
        {jobs.length === 0 && !loading &&
          <Box flexDirection="column" padding="large" alignItems="center" justifyContent="center">
            <Text fontSize="small">No result found</Text>
          </Box>
        }
        {loading &&
          <Box flexDirection="column" padding="large" alignItems="center" justifyContent="center">
            <LoadingBubbles color="rgba(0, 0, 0, 0.15)" />
          </Box>
        }
      </Box>
    </>
  )
}

Services.propTypes = {
  setTotalJobs: PropTypes.func,
  serviceFilter: PropTypes.array,
  statusFilter: PropTypes.array
}

export default Services
