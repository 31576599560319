import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject, toggleArray } from '@campaignhub/javascript-utils'
import useCurrentUser from '@hooks/useCurrentUser'

import {
  Box, Button, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft } from '@fortawesome/pro-light-svg-icons'

import Provider from './components/Provider'

const defaultState = {
  availableProviders: [],
  currentlySelectedIds: [],
}

const SelectProviderModal = (props) => {
  const {
    availableSlots, bookableSlots, callbacks, callbacks: { closeModal }, filterSlots, modalKey, showModal,
  } = props
  const { currentUser: { timeZone } } = useCurrentUser()

  const [state, setState] = useSetState(defaultState)
  const { currentlySelectedIds, availableProviders } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const {
    serviceJob,
    serviceJob: {
      booking, bookingType, name, serviceId, serviceGroupId, selectedUsers,
    },
  } = digObject(modalData, modalKey, {})

  const providers = [...new Set(availableSlots
    .filter(x => x.serviceId === serviceId)
    .map(x => JSON.stringify(x.user)))]
    .map(x => JSON.parse(x)).sort((a, b) => a.firstName.localeCompare(b.firstName))

  const selectProvider = (provider) => {
    const selectedJob = serviceJob
    if (!selectedJob.selectedUsers?.length){
      selectedJob.selectedUsers = [provider]
    } else {
      selectedJob.selectedUsers = selectedJob.selectedUsers[0] !== provider ? [provider] : null
    }

    setState({
      currentlySelectedIds: toggleArray((currentlySelectedIds.includes(provider.username)) ? currentlySelectedIds : [], provider.username),
      serviceJob: selectedJob,
    })
  }

  const bookProviders = () => {
    filterSlots(bookableSlots)
    closeModal()
  }

  useEffect(() => {
    setState({
      currentlySelectedIds: Array.isArray(selectedUsers) ? selectedUsers.map(x => x.username) : [],
    })
  }, [selectedUsers])

  useEffect(() => {
    if (['selectedBook', 'selectedStandby'].includes(bookingType)){
      const available = availableSlots.filter(x => x.start <= booking.bookingStart && x.end >= booking.bookingEnd && x.serviceId === serviceId)
      const providersAvailable = [...new Set(available.map(x => JSON.stringify(x.user)))].map(x => JSON.parse(x)).sort((a, b) => a.firstName.localeCompare(b.firstName))

      setState({ availableProviders: providersAvailable })
    } else {
      setState({ availableProviders: [...providers] })
    }
  }, [availableSlots, bookingType])

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Choose" titleSecondLine="Provider" />
      <SidebarModal.Content>
        <Box flexDirection="column" lineHeight="1.3">
          <Text color="bodyFontLightColor" fontSize="small" marginBottom="large">
            Booking {name} for
          </Text>
          <Box flexDirection="row" marginBottom="large">
            <Box
              alignItems="center"
              color="bodyFontLightColor"
              justifyContent="flex-start"
              marginRight="large"
              style={{ cursor: 'pointer' }}
              width="auto"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="sm" />
            </Box>
            {booking && (
              <Box flexDirection="column">
                <Text fontSize="small" marginBottom="small">
                  {DateTime.fromISO(booking.bookingStart, { zone: timeZone }).toFormat('cccc LLLL dd, y')}
                </Text>
                <Text color="bodyFontLightColor" fontSize="xsmall">
                  at {DateTime.fromISO(booking.bookingStart, { zone: timeZone }).toFormat('hh:mma').toLowerCase()}
                </Text>
              </Box>
            )}
          </Box>
          <Box flexDirection="column" fontSize="small" marginY="large">
            <Text>Available Providers</Text>
          </Box>
          {availableProviders?.map((provider) => {
            const selected = currentlySelectedIds.includes(provider.username)
            return (
              <Provider
                callbacks={{ toggleItem: () => selectProvider(provider) }}
                provider={provider}
                selected={selected}
                serviceGroupId={serviceGroupId}
              />
            )
          })}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryEdit"
          size="medium"
          icon={<FontAwesomeIcon icon={faCheck} />}
          onClick={bookProviders}
        >
          Book Provider
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

SelectProviderModal.propTypes = {
  availableSlots: PropTypes.array,
  bookableSlots: PropTypes.array,
  callbacks: PropTypes.object.isRequired,
  filterSlots: PropTypes.func,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

SelectProviderModal.defaultProps = {
  modalKey: 'SelectProviderModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectProviderModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
