import * as assetCommentTypes from './assetCommentType'
import * as assetComments from './assetComment'
import * as assets from './asset'
import * as availableSlot from './availableSlot'
import * as bookingOverview from './bookingOverview'
import * as campaigns from './campaign'
import * as clientTypes from './clientType'
import * as clients from './client'
import * as currentUser from './currentUser'
import * as currentUserV2 from './currentUserV2'
import * as entities from '../entities'
import * as filters from './filter'
import * as navigation from './navigation'
import * as proofingOverview from './proofingOverview'
import * as realhubCampaigns from './realhubCampaign'
import * as resources from './resource'
import * as serviceGroups from './serviceGroup'
import * as serviceGroupsV2 from './serviceGroupV2'
import * as serviceJobDetails from './serviceJobDetail'
import * as serviceJobNotes from './serviceJobNote'
import * as serviceJobStatuses from './serviceJobStatus'
import * as serviceJobUserRoles from './serviceJobUserRole'
import * as serviceJobUsers from './serviceJobUser'
import * as serviceJobs from './serviceJob'
import * as statistics from './statistic'
import * as timetrackers from './timetracker'
import * as userRoles from './userRole'
import * as users from './user'
import * as workflows from './workflow'

const modules = {
  assetCommentTypes,
  assetComments,
  assets,
  availableSlot,
  bookingOverview,
  campaigns,
  clientTypes,
  clients,
  currentUser,
  currentUserV2,
  entities,
  filters,
  navigation,
  proofingOverview,
  realhubCampaigns,
  resources,
  serviceGroups,
  serviceGroupsV2,
  serviceJobDetails,
  serviceJobNotes,
  serviceJobStatuses,
  serviceJobUserRoles,
  serviceJobUsers,
  serviceJobs,
  statistics,
  timetrackers,
  userRoles,
  users,
  workflows,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
