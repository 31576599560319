/* eslint-disable max-len */
import React, { useContext } from 'react'
import ImageMarker from 'react-image-marker'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import { Box, Text } from '@campaignhub/suit-theme'

import useUsers from '@hooks/useUsers'
import CustomMarker from './components/CustomMarker'
import ImageSlider from './components/ImageSlider'

import styles from './styles.module.scss'

const AssetMarker = (props) => {
  const { componentProps, callbacks } = props
  const {
    contents, markers, showAllMarkers, setState,
  } = componentProps

  const { assignedUserRole, selectedAsset, currentUser } = useContext(PageContext)
  const { users } = useUsers()

  return (
    <>
      <Box alignItems="center" flexDirection="row" marginBottom="large">
        <Text fontSize="medium" fontWeight="500">
          Assets
        </Text>
      </Box>
      <Box flexDirection="column" alignItems="center">
        <Box
          alignItems="center"
          justifyContent="center"
          marginBottom="large"
          objectFit="contain"
          style={{ cursor: 'pointer' }}
          width="100%"
          height={[400, 650]}
        >
          <ImageMarker
            markers={showAllMarkers ? markers : markers.filter(x => x.status === null || !x.status)}
            markerComponent={markerProps => CustomMarker({
              markerProps, markers, markerSetState: setState, callbacks,
            })}
            onAddMarker={(marker) => {
              if (['ProductionManager'].includes(users[currentUser.id]?.userRole.name) || assignedUserRole?.serviceJobUserRole.name === 'QualityControl'){
                setState({ markers: [...markers, marker] })
              }
            }}
            src={selectedAsset?.previewUrl}
            extraClass={styles.markerImg}
          />
        </Box>
        <ImageSlider assetSetState={setState} contents={contents} selectedAssetId={selectedAsset?.id} />
      </Box>
    </>
  )
}

AssetMarker.propTypes = {
  componentProps: PropTypes.object,
  callbacks: PropTypes.object,
}

export default AssetMarker
