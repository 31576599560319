import React from 'react'

import { DashboardModule, Table } from '@campaignhub/suit-theme'

const ActivityLogs = () => (
  <DashboardModule title="Activity Logs">
    <Table
      columns={[
        {
          dataKey: 'username',
          title: 'Name',
        },
        {
          dataKey: 'details',
          title: 'Details',
        },
        {
          dataKey: 'dateAction',
          title: 'Date',
        },
      ]}
      data={[
        {
          username: 'Marie Cooper',
          details: 'Uploaded in JtDesignerTodo - Image View',
          dateAction: '12:00 PM, Oct 14 2020',
        },
        {
          username: 'Jeff Davies',
          details: 'Uploaded in JtDesignerTodo - Image View',
          dateAction: '12:00 PM, Oct 14 2020',
        },
        {
          username: 'Edison Sampson',
          details: 'Uploaded in JtDesignerTodo - Image View',
          dateAction: '12:00 PM, Oct 14 2020',
        },
      ]}
    />
  </DashboardModule>
  )

export default ActivityLogs
