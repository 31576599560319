import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import Carousel from 'react-elastic-carousel'

import {
  Box, Image, Link, Text,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { faCheck, faCommentLines, faPencil } from '@fortawesome/pro-light-svg-icons'

import { getEntityByName } from '@functions/getEntityByName'
import Icons from '@components/Icons'

const ImageSlider = () => {
  const {
    callbacks, currentAssets, selectedAsset, selectedServiceJob,
  } = useContext(PageContext)
  const { toggleSelectedAsset } = callbacks

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    assetComments, assetCommentTypes, serviceGroups, serviceJobStatuses,
  } = entities

  return (
    <Box
      borderRadius={5}
      flexDirection="column"
      height={['auto', '80vh']}
      width="100%"
      paddingBottom="large"
    >
      <Link href={`${selectedAsset?.assetUrl}?view=true`} target="_blank">
        <Image
          boxProps={{
          backgroundColor: 'hoverLightGrey',
          borderRadius: '5px',
          backgroundSize: 'contain',
          marginBottom: 'medium',
        }}
          height={[420, '71vh']}
          url={selectedAsset?.file?.previewGeneratedAt ? selectedAsset?.assetUrl : ''}
        >
          {!selectedAsset?.file?.previewGeneratedAt && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
            >
              <Icons name={serviceGroups[selectedServiceJob.serviceGroupId]?.name} size={25} width="100%" />
            </Box>
        )}
        </Image>
      </Link>
      <Box flexDirection="row" justifyContent="center" marginBottom="medium">
        <Text fontSize="small" fontWeight="500">
          {selectedAsset?.file?.originalName || ''}
        </Text>
      </Box>
      <Carousel
        breakPoints={[
          { width: 1, itemsToShow: 3 },
          { width: 550, itemsToShow: 5, itemsToScroll: 5 },
          { width: 850, itemsToShow: 8, itemsToScroll: 8 },
          { width: 1000, itemsToShow: 10, itemsToScroll: 10 },
        ]}
        pagination={false}
        renderArrow={({ type, onClick, isEdge }) => (
          <Box
            alignItems="center"
            color="bodyFontColor"
            height={80}
            onClick={onClick}
            opacity={isEdge ? 0.5 : 1}
            pointerEvents={isEdge ? 'none' : 'auto'}
            styles={{
              cursor: isEdge ? 'none' : 'pointer',
              padding: 'large',
            }}
            width={20}
          >
            <FontAwesomeIcon icon={type === 'PREV' ? faChevronLeft : faChevronRight} />
          </Box>
        )}
        showEmptySlots
      >
        {Object.values(currentAssets).map(content => (
          <Box
            key={content.id}
            onClick={() => toggleSelectedAsset(content)}
            title={serviceJobStatuses[content?.serviceJobStatusId]?.description}
          >
            <Image
              boxProps={{
                backgroundColor: 'hoverLightGrey',
                border: '2px solid',
                borderColor: selectedAsset?.id === content.id ? 'blue' : 'lineColor',
                borderRadius: '5px',
              }}
              height={80}
              url={content.file?.previewGeneratedAt ? content.previewUrl : ''}
              width={110}
            >
              {!content.file.previewGeneratedAt ? (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  position="absolute"
                  width="auto"
                >
                  <Icons name={serviceGroups[selectedServiceJob.serviceGroupId]?.name} size={25} width="100%" />
                </Box>
              ) : null}
              {[160, 170].includes(content.serviceJobStatusId) || (content.serviceJobStatusId === 150
                && (Object.values(assetComments).find(x => x.assetId === content.id
                && x.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ProofingNote')?.id))) ? (
                  <Box height={76} position="relative">
                    <Box
                      backgroundColor={selectedAsset?.id === content.id ? 'blue' : 'lineColor'}
                      borderTopLeftRadius="8px"
                      bottom={0}
                      color={selectedAsset?.id === content.id ? 'white' : 'bodyFontColor'}
                      padding="5px"
                      position="absolute"
                      right={0}
                      width="auto"
                    >
                      {content.serviceJobStatusId === 150 && <FontAwesomeIcon icon={faCommentLines} />}
                      {content.serviceJobStatusId === 160 && <FontAwesomeIcon icon={faPencil} />}
                      {content.serviceJobStatusId === 170 && <FontAwesomeIcon icon={faCheck} />}
                    </Box>
                  </Box>
              ) : null}
            </Image>
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

export default ImageSlider
