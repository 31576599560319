const serviceJobNoteTypes = {
  studio: {
     id: 1,
     name: 'Studio',
     description: 'Studio',
    },
  qc: {
    id: 2,
    name: 'QC',
    description: 'QC',
  },
}

export default serviceJobNoteTypes
