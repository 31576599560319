import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AssetScreen from './screens/AssetScreen'
import ServiceJob from './screens/ServiceJob'
import ServiceJobs from './screens/ServiceJobs'

const DashboardRoutes = () => (
  <Routes>
    <Route path="/" element={<ServiceJobs />} />
    <Route path="/overviews/service-jobs" element={<ServiceJobs />} />
    <Route path="/service-jobs/:id" element={<ServiceJob />} />
    <Route path="/service-jobs/:id/assets" element={<AssetScreen />} />
  </Routes>
)

export default DashboardRoutes
