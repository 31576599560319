import { useContext } from 'react'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/campaign'

import * as campaignActions from '@redux/modules/campaign'

import PageContext from '@contexts/pageContext'

const assignAdditionalInvitees = (campaignInvitee, dispatch, requestOptions) => {
  const { assignAdditionalInviteesV2: createFn } = campaignActions

  return dispatch(createFn(campaignInvitee, requestOptions))
}

const assignInvitees = (campaignInvitee, dispatch, requestOptions) => {
  const { assignInviteesV2: createFn } = campaignActions

  return dispatch(createFn(campaignInvitee, requestOptions))
}

const updateCampaign = (campaignParams, dispatch, requestOptions) => {
  const { updateCampaignV2: updateFn } = campaignActions
  return dispatch(updateFn(campaignParams, requestOptions))
}

export function useCampaignFormV2(campaign){
  const campaignFormV2 = useForm(defaultState, { entity: campaign, requiredFields }, [campaign.id])

  return {
    ...campaignFormV2,
  }
}

const launchCampaignInviteesModal = (campaign, dispatch, showCampaignInviteesModal) => new Promise((resolve, reject) => {
  if (showCampaignInviteesModal){
    const payload = {
      callbacks: {
        assignAdditionalInvitees: (campaignInvitee, requestOptions) => assignAdditionalInvitees(campaignInvitee, dispatch, requestOptions),
        assignInvitees: (campaignInvitee, requestOptions) => assignInvitees(campaignInvitee, dispatch, requestOptions),
        updateCampaign: (campaignParams, requestOptions) => updateCampaign(campaignParams, dispatch, requestOptions),
      },
      campaign,
    }

    showCampaignInviteesModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCampaignInviteesModal not defined in PageContext callbacks'))
})

const useInviteesV2 = (initCampaign = {}) => {
  const { entity: campaign } = useLatestEntity(initCampaign, 'campaigns')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showCampaignInviteesModal } = callbacks || {}

  return {
    callbacks: {
      assignAdditionalInvitees: (campaignInvitee, requestOptions) => assignAdditionalInvitees(campaignInvitee, dispatch, requestOptions),
      assignInvitees: (campaignInvitee, requestOptions) => assignInvitees(campaignInvitee, dispatch, requestOptions),
      launchCampaignInviteesModal: () => launchCampaignInviteesModal(campaign, dispatch, showCampaignInviteesModal),
      updateCampaign: (campaignParams, requestOptions) => updateCampaign(campaignParams, dispatch, requestOptions),
    },
  }
}

export default useInviteesV2
