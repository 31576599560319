import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useCurrentUser = () => {
  const includes = ['userRole', 'userRoleModules', 'serviceJobStatuses', 'services']
  useReduxAction('currentUser', 'loadCurrentUser', {
    includes: includes.join(','),
  }, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.currentUser)
  const {
    loaded,
    loading,
    result: currentUser,
  } = entityReducer

  return {
    currentUser,
    loaded,
    loading,
  }
}

export default useCurrentUser
