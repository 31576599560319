import React from 'react'

import {
  Box,
  DashboardModule,
  ListItem,
  Text,
  UserProfileImage,
} from '@campaignhub/suit-theme'

const StudioFeedback = () => {
  return (
    <DashboardModule
      title="Studio Feedback"
    >
      <ListItem
        boxProps={{
          borderBottom: "1px solid",
          padding: "large",
          lineHeight: '1.3'
        }}
        flexDirection="column"
        style={{ cursor: "pointer" }}
      >
        <Text fontSize="xsmall">
          Joan Viscaya, Quality Controller
        </Text>
        <Box flexDirection="row" marginTop="large">
          <UserProfileImage imageUrl="https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80" />
          <Box flexDirection="column" paddingLeft="large">
            <Text color="bodyFontLightColor" fontSize="xsmall">
              Hey guys thanks for sending that through - just some overall feedback on this job from Provider A and B. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et augue dolor. Fusce quis luctus nisl, eget pellentesque elit. Donec sagittis vel lacus non pellentesque. Curabitur rutrum, est eget commodo luctus, enim dui faucibus elit, in efficitur purus dui ut lectus. Proin eu augue mattis, egestas eros a, maximus lacus. Ut fringilla egestas risus nec lacinia.
            </Text>
          </Box>
        </Box>
      </ListItem>
    </DashboardModule>
  )
}

export default StudioFeedback