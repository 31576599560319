import React from 'react'

import {
  Box, Image, LoadingBubbles,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from '@components/assets/logo-mark.svg'

const Loading = () => (
  <Box
    alignItems="center"
    backgroundColor="whiteGrey"
    height="100vh"
    justifyContent="center"
    padding={['large', 'xxlarge']}
    width="100%"
  >
    <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
      <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />
      <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />
    </Box>
  </Box>
)

export default Loading
