import api from '@functions/api'

const config = {
  method: 'POST',
}

export function ClockIn() {
  const promise = api('/v1/users/current/clock-in', {}, config)
    .then(({ data }) => {
      return { success: true, result: data }
    })
    .catch(({ response }) => {
      return { success: false, result: response?.stae?.errors }
    })

  return promise
}

export function ClockOut() {
  const promise = api('/v1/users/current/clock-out', {}, config)
    .then(({ data }) => {
      return { success: true, result: data }
    })
    .catch((data) => {
      return { success: false, result: data }
    })

  return promise
}

export function StartBreak() {
  const promise = api('/v1/users/current/break-in', {}, config)
    .then(({ data }) => {
      return { success: true, result: data }
    })
    .catch(({ errors }) => {
      return { success: false, result: errors }
    })

  return promise
}

export function EndBreak() {
  const promise = api('/v1/users/current/break-out', {}, config)
    .then(({ data }) => {
      return { success: true, result: data }
    })
    .catch(({ errors }) => {
      return { success: false, result: errors }
    })
  return promise
}