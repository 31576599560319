import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, IconWithMenu, Link, Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import calculateContentOffset from '@components/utils/calculateContentOffset'

const ToolBar = (props) => {
  const {
    showAllMarkers, showControls, showSaveCopy, showSend, showMarkerToggle,
    callbacks: { toggleShowAllMarkers },
  } = props

  const contentOffset = calculateContentOffset(0, {})

  return (
    <Box
      bottom={0}
      display="flex"
      flexDirection="row"
      padding="medium"
      position="fixed"
      variant="white"
      zIndex="3"
      width={`calc(100% - ${contentOffset || 0}px)`}
    >
      {showControls && (
      <>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ZoomIn" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ZoomOut" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ViewFullScreen" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ExitFullScreen" />} />
        </Box>
      </>
      )}
      {showSaveCopy && (
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium">
            Save Copy
          </Button>
        </Box>
      )}
      {showMarkerToggle && (
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Toggle
            onClick={() => toggleShowAllMarkers()}
            toggleOffText="Show All"
            toggleOn={showAllMarkers}
            toggleOnText="Show Unresolved Only"
          />
        </Box>
      )}
      {showSend && (
        <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
          <IconWithMenu
            icon={(
              <Button
                size="medium"
                buttonStyle="ghostEdit"
                icon={<FontAwesomeIcon icon={faAngleUp} />}
                iconPosition="right"
              >
                Send
              </Button>
              )}
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <IconWithMenu.Menu
              closeOnClick
              listStyle={{ right: 0, left: '-55px', top: '-170px' }}
              nibStyle={{ top: '148px', transform: 'rotate(225deg)' }}
            >
              <Link>Send to QC</Link>
              <Link>Send to Editor</Link>
              <Link>Send to Client</Link>
              <Link>Send to Verification</Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        </Box>
      )}
    </Box>
  )
}

ToolBar.propTypes = {
  callbacks: PropTypes.object,
  showAllMarkers: PropTypes.bool,
  showControls: PropTypes.bool,
  showMarkerToggle: PropTypes.bool,
  showSaveCopy: PropTypes.bool,
  showSend: PropTypes.bool,
}

export default ToolBar
