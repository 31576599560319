import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as serviceJobDetailActions from '@redux/modules/serviceJobDetail'

const assignServiceJobDetail = (serviceJobDetails, dispatch, requestOptions) => {
  const { assignServiceJobDetailV2: createFn } = serviceJobDetailActions

  return dispatch(createFn(serviceJobDetails, requestOptions))
}

const useServiceJobDetailsV2 = () => {
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      assignServiceJobDetail: (serviceJobDetails, requestOptions) => assignServiceJobDetail(serviceJobDetails, dispatch, requestOptions),
    },
  }
}

export default useServiceJobDetailsV2
