import React, { useContext } from 'react'
import PageContext from '@contexts/pageContext'

import {
  Box, Button, FormField, Tag, Text,
} from '@campaignhub/suit-theme'

import AssetCommentGroup from './components/AssetCommentGroup'

const QCTab = () => {
  const { groupedAssetComments } = useContext(PageContext)

  return (
    <Box flexDirection="column" alignItems="center" width="100%">
      <AssetCommentGroup groupName="Unresolved" comments={groupedAssetComments?.null || []} />
      <AssetCommentGroup groupName="Revised" comments={groupedAssetComments?.Revised || []} />
      <AssetCommentGroup groupName="Resolved" comments={groupedAssetComments?.Resolved || []} />
      <Box flexDirection="column" padding="large">
        <Text fontSize="small" paddingBottom="small">
          Tags
        </Text>
        <FormField boxProps={{ marginBottom: 'large' }} direction="column">
          <Box>
            <input type="text" />
            <Button
              buttonStyle="secondaryCreate"
              marginLeft="small"
              size="small"
              width="auto"
            >
              Add
            </Button>
          </Box>
          <Box marginTop="medium">
            <Tag boxProps={{ fontSize: 'xsmall' }}>
              living room
            </Tag>
            <Tag boxProps={{ fontSize: 'xsmall' }}>
              balcony
            </Tag>
          </Box>
        </FormField>
      </Box>
    </Box>
  )
}

export default QCTab
