export function getShareLinkTokenFromUrl(pathname){
  if (!pathname) return null

  // Example
  // app.contenthouse.io/share/H9yNyGAaCmAHT14tk9Ks
  // app.contenthouse.io/share/v1/H9yNyGAaCmAHT14tk9Ks
  const matches = pathname.match(/\/share\/(\S+)/)
  if (!matches) return null

  return matches[1]
}

export default getShareLinkTokenFromUrl
