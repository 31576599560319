/* eslint-disable no-return-assign */
import React, { useContext, useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

const getDetailSort = (value) => {
  switch (value){
    case 'Bed': return 1
    case 'Study': return 2
    case 'Bath': return 3
    case 'Car': return 4
    default: return 5
  }
}

const defaultState = {
  details: '',
}

const CopyViewer = () => {
  const { currentAssets, selectedServiceJob } = useContext(PageContext)

  const [state, setState] = useSetState(defaultState)
  const { details } = state

  useEffect(() => {
    const jobDetails = selectedServiceJob?.details?.filter(x => (
      ['Bath', 'Bed', 'Car', 'Study'].includes(x.entityFieldType.name)
    )).filter(y => JSON.parse(y.value).value > 0).map(detail => ({
      name: detail.entityFieldType.name,
      pluralName: detail.entityFieldType.name === 'Study' ? 'Studies' : `${detail.entityFieldType.name }s`,
      sort: getDetailSort(detail.entityFieldType.name),
      value: JSON.parse(detail.value).value,
    })) || []

    let temp = ''
    jobDetails.sort((a, b) => (a.sort > b.sort ? 1 : -1)).map((detail, index) => (
      temp = `${temp}${detail.value > 1 ? `${detail.value } ` : ''}`
      + `${detail.value > 1 ? detail.pluralName : detail.name}`
      + `${index !== jobDetails.length - 1 ? ' / ' : ''}`
      ))

    setState({ details: temp })
  }, [selectedServiceJob])

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      height={[430, '80vh']}
      padding="xlarge"
      width="100%"
      overflow="auto"
    >
      <Box flexDirection="column" marginBottom="large">
        <Text fontSize="small" fontWeight="bold" marginBottom="small">
          <u>{selectedServiceJob.campaign.name}</u>
        </Text>
        {details !== '' ? (
          <Text fontSize="small">
            <u>{details}</u>
          </Text>
        ) : null}
      </Box>
      {currentAssets.filter(x => x.copy !== null).filter(x => x.copy.copyDetails.find(y => y.value !== ''))
      .sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1))
      .map(({ copy }) => (
        <Box flexDirection="column" marginBottom="large">
          <Text fontSize="small" marginY="large">
            <u>{copy.copyTemplate.name}</u>
          </Text>
          {copy.copyDetails.sort((a, b) => (a.copyTemplateField.sort > b.copyTemplateField.sort ? 1 : -1)).map((copyDetail, index) => (
            <>
              {copyDetail.value !== '' ? (
                <Box
                  flexDirection="row"
                  marginBottom={copyDetail.copyTemplateField.name.match('Heading') || copy.copyDetails.length - 1 === index ? 'large' : 'small'}
                >
                  {copyDetail.copyTemplateField.bulletPoint ? (
                    <Text fontSize="small" paddingX="medium">&#8226;</Text>
                  ) : null}
                  <Text
                    fontSize="small"
                    fontWeight={copyDetail.copyTemplateField.name.match('Heading') ? 'bold' : 'normal'}
                    lineHeight="1.5"
                  >
                    {copyDetail.value}
                  </Text>
                </Box>
              ) : null}
            </>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default CopyViewer
