import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import bookingTypes from '@functions/bookingTypes'

import Details from './components/Details'

const Group = (props) => {
  const {
    callbacks: { toggleItem }, order, selected, serviceJobs, setStateCampaignBooking,
  } = props

  serviceJobs.sort((a, b) => bookingTypes.find(x => x.name === a.bookingType)?.sort - bookingTypes.find(x => x.name === b.bookingType)?.sort || a.order.id - b.order.id)

  const hasBooking = serviceJobs.some(x => x.booking)

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      disableHover
      flexDirection="column"
    >
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        onClick={() => (selected ? null : toggleItem())}
        style={{ cursor: selected ? 'default' : 'pointer' }}
      >
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          marginRight="large"
          height="18px"
          width="18px"
        >
          <Box
            alignItems="center"
            backgroundColor={hasBooking ? 'green' : 'lightGrey'}
            borderRadius="1rem"
            justifyContent="center"
            padding="3px"
            position="absolute"
            width="auto"
            minWidth="16px"
            minHeight="16px"
          >
            <FontAwesomeIcon color="white" icon={faCheck} size="xs" />
          </Box>
        </Box>
        <Box flexDirection="column" marginRight="medium">
          <Text fontSize="xsmall">{order?.name || 'Placed Orders'}</Text>
        </Box>
        <Box
          color="bodyFontLightColor"
          justifyContent="flex-end"
          marginLeft="medium"
          width="auto"
          marginRight="auto"
        >
          <FontAwesomeIcon icon={selected ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {selected && (
        serviceJobs.map((serviceJob, index) => (
          <ListItem
            boxProps={{
              borderBottom: bookingTypes.find(x => x.name === serviceJobs[index].bookingType)?.sort
                !== bookingTypes.find(x => x.name === serviceJobs[index + 1]?.bookingType)?.sort ? '1px solid' : 'none',
              paddingTop: 'large',
            }}
            disableHover
            flexDirection="column"
          >
            <Details
              serviceJob={serviceJob}
              setStateCampaignBooking={setStateCampaignBooking}
            />
          </ListItem>
        ))
      )}
    </ListItem>
  )
}

Group.propTypes = {
  callbacks: PropTypes.object,
  order: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  serviceJobs: PropTypes.array,
  setStateCampaignBooking: PropTypes.func,
}

export default Group
