import React from 'react'

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'

import { TopBar } from '@campaignhub/suit-theme'

import useBookingCampaignSearch from './useBookingCampaignSearch'

const BookingCampaignSearch = () => {
  const {
    callbacks: {
      bookingCampaignSearch,
      mapResults,
    },
  } = useBookingCampaignSearch()

  return (
    <TopBar.Search
      callbacks={{ search: bookingCampaignSearch }}
      mapResults={data => mapResults(data)}
      renderResult={entity => <TopBar.Result key={entity.id} icon={faCalendarAlt} result={entity} />}
    />
  )
}

BookingCampaignSearch.propTypes = {}

export default BookingCampaignSearch
