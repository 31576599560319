import React, { useContext } from 'react'
import { DateTime } from 'luxon'

import {
  Box, DashboardModule, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

const monthDayDate = (month, day) => (DateTime.fromObject({ month, day }).isValid ? DateTime.fromObject({ month, day }) : DateTime.fromObject({ month }).endOf('month'))

const DuskTimes = () => {
  const { calendarDates, campaign: { duskTimes } } = useContext(PageContext)

  const duskSchedule = duskTimes?.filter(x => (monthDayDate(x.startMonth, x.startDay) > calendarDates[0]
    && monthDayDate(x.startMonth, x.startDay) < calendarDates[calendarDates.length - 1].endOf('day'))
    || (monthDayDate(x.endMonth, x.endDay) > calendarDates[0]
    && monthDayDate(x.endMonth, x.endDay) < calendarDates[calendarDates.length - 1].endOf('day'))
    || (monthDayDate(x.startMonth, x.startDay) < calendarDates[0]
    && monthDayDate(x.endMonth, x.endDay) > calendarDates[calendarDates.length - 1].endOf('day')))

  if (!duskSchedule?.length) return null

  return (
    <DashboardModule title="Dusk Times">
      <Box flexDirection="column" paddingX="large" marginTop="large">
        {duskSchedule.map(x => (
          <Text fontSize="small" marginBottom="large">
            {`${monthDayDate(x.startMonth, x.startDay).toFormat('LLL d')} - ${monthDayDate(x.endMonth, x.endDay).toFormat('LLL d')} 
              starts at ${DateTime.fromISO(x.time).toFormat('h:mm a')}`}
          </Text>
        ))}
      </Box>
    </DashboardModule>
  )
}

export default DuskTimes
