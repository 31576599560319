import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'

import {
 Box, Checkbox, Text,
} from '@campaignhub/suit-theme'

import { faSlidersH } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PageContext from '@contexts/pageContext'

import custom from '@styles/custom.module.scss'
import ListView from './components/ListView'

const defaultState = {
  selectAll: false,
}

const Resources = (props) => {
  const { callbacks: { toggleUpdateSelectedIds }, selectedIds } = props

  const [state, setState] = useSetState(defaultState)
  const { selectAll } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources } = entities

  const { serviceJob } = useContext(PageContext)

  const contents = Object.values(resources).filter(resource => parseInt(resource.serviceJobId, 10) === serviceJob.id)

  useEffect(() => {
    toggleUpdateSelectedIds(selectAll ? Object.values(resources).map(content => content.id) : [])
  }, [selectAll])

  useEffect(() => {
    const newSelectedIds = []
    selectedIds.forEach((id) => {
      const resource = Object.values(resources).find(r => r.id === id)
      if (resource){
        newSelectedIds.push(id)
      }
    })
    if (newSelectedIds.length !== selectedIds.length){
      if (selectAll){
        setState({ selectAll: false })
      } else {
        toggleUpdateSelectedIds(newSelectedIds)
      }
    }
  }, [resources])

  return (
    <>
      {Object.values(resources).length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="lineColor"
            flexDirection="row"
            padding="large"
          >
            <Checkbox checked={selectAll} onClick={() => setState({ selectAll: !selectAll })} />
            <Box flexDirection="row" color="blue">
              <FontAwesomeIcon icon={faSlidersH} />
              <Text paddingX="medium" color="blue">Filter</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
          </Box>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            <Text fontSize="small" fontWeight={600} marginBottom="large">Provider Uploads</Text>
            <ListView
              callbacks={{ toggleUpdateSelectedIds }}
              contents={contents}
              selectedIds={selectedIds}
            />
          </Box>
        </Box>
      )}

      {!Object.values(resources).length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No resources uploaded yet.
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

Resources.propTypes = {
  callbacks: PropTypes.object,
  selectedIds: PropTypes.array,
}

export default Resources
