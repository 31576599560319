import React, { useContext } from 'react'
import PageContext from '@contexts/pageContext'

import {
  Box, Grid, Heading, Link, Text,
} from '@campaignhub/suit-theme'

import { faBath, faBed, faCar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const JobHeader = () => {
  const { serviceJob } = useContext(PageContext)

  return (
    <Box
      variant="white"
      borderBottom="1px solid"
      borderColor="lineColor"
      flexDirection="column"
      padding="large"
      position="fixed"
      px="large"
      lineHeight="1.5"
      style={{
        top: 50,
        right: 0,
        zIndex: 10,
        left: 200,
      }}
      height={[112, 115]}
    >
      <Box flexDirection="column">
        <Heading weight="normal"><Text>{serviceJob.campaign?.name}</Text></Heading>
        <Box flexDirection="row">
          <Box flexShrink="0" flexDirection="column" width="auto" paddingRight="medium">
            <Text>Maroochydore, QLD 4558</Text>
          </Box>
          <Box fontSize="xsmall" alignItems="center" marginLeft="auto">
            <Link>View Map</Link>
          </Box>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box flexShrink="0" flexDirection="column" width="auto" paddingRight="medium">
            <Text>1,500 sq.m. |</Text>
          </Box>
          <Grid
            gridGap="medium"
            gridAutoRows="1fr"
            gridTemplateColumns="repeat(auto-fill, minmax(10px, 40px))"
          >
            <Box color="bodyFontLightColor" alignItems="center">
              <FontAwesomeIcon icon={faBed} />
              <Text marginLeft="medium">5</Text>
            </Box>
            <Box color="bodyFontLightColor" alignItems="center">
              <FontAwesomeIcon icon={faBath} />
              <Text marginLeft="medium">3</Text>
            </Box>
            <Box color="bodyFontLightColor" alignItems="center">
              <FontAwesomeIcon icon={faCar} />
              <Text marginLeft="medium">2</Text>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default JobHeader
