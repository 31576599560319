import React from 'react'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, TabBar } from '@campaignhub/suit-theme'

import {
  faCalendar, faLayerGroup, faTasks,
} from '@fortawesome/pro-light-svg-icons'

import ServiceGroupContent from './components/ServiceGroupContent'

const defaultState = {
  activeTabBarItemKey: 'assets',
  selectedIds: [],
}

const AssetsAndResources = () => {
  const [state, setState] = useSetState(defaultState)
  const { activeTabBarItemKey, selectedIds } = state

  const updateSelectedIds = (updatedIds) => {
    setState({ selectedIds: updatedIds })
  }

  return (
    <Box flexDirection="column">
      <TabBar
        items={[
          {
            icon: faCalendar,
            key: 'assets',
            title: 'Assets',
            onClick: () => setState({ activeTabBarItemKey: 'assets' }),
          },
          {
            icon: faTasks,
            key: 'proofs',
            title: 'Proofs',
            onClick: () => setState({ activeTabBarItemKey: 'proofs' }),
          },
          {
            icon: faLayerGroup,
            key: 'resources',
            title: 'Resources',
            onClick: () => setState({ activeTabBarItemKey: 'resources' }),
          },
        ]}
        selectedItemKey={activeTabBarItemKey}
        invertColors
      />
      <Box flexDirection="column" marginBottom="large" variant="white">
        <ServiceGroupContent
          activeTabBarItemKey={activeTabBarItemKey}
          callbacks={{ toggleUpdateSelectedIds: updatedIds => updateSelectedIds(updatedIds) }}
          selectedIds={selectedIds}
        />
      </Box>
    </Box>
  )
}

export default AssetsAndResources
