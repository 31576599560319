import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useSlot from '../../../../hooks/useSlot'
import Slot from './components/Slot'

const CalendarGrid = (props) => {
  const { calendarServiceGroups, timeSlots } = props

  const {
    bookableSlots, calendarDates, calendarSettings: { slotLength }, campaign: { duskTimes }, campaignServiceJobs,
  } = useContext(PageContext)

  const {
    callbacks, callbacks: { available, top, duskTime },
  } = useSlot(bookableSlots, campaignServiceJobs, duskTimes)

  const gridSlots = timeSlots.flatMap(t => (calendarDates.map(d => d.set({ hour: t.c.hour, minute: t.c.minute }))))

  return (
    <Grid
      gridArea="main"
      gridColumnGap="large"
      gridTemplateColumns={`repeat(${calendarDates.length}, minmax(${calendarServiceGroups.length > 3 ? calendarServiceGroups.length * 50 : 180}px, 1fr))`}
      position="relative"
    >
      {gridSlots.map(gridSlot => (
        <Grid gridTemplateColumns={`repeat(${calendarServiceGroups.length}, 1fr)`}>
          {calendarServiceGroups?.map((serviceGroup) => {
            const slotPayload = {
              available: available(gridSlot, serviceGroup.id),
              callbacks,
              duskTime: duskTime(gridSlot),
              gridSlot,
              serviceGroup,
              slotLength,
              top: top(gridSlot),
            }
            return (
              <Slot slotPayload={slotPayload} />
            )
          })}
        </Grid>
      ))}
    </Grid>
  )
}

CalendarGrid.propTypes = {
  calendarServiceGroups: PropTypes.array,
  timeSlots: PropTypes.array.isRequired,
}

export default React.memo(CalendarGrid)
