import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/asset/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/asset/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/asset/FETCH_FAILURE'

const DELETE_REQUEST = 'contenthouse/asset/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/asset/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/asset/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAssets(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().assets.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('v1/assets', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadAsset(id, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/v1/assets/${id}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function deleteAsset(data){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v1/assets/${data.id}`, {}, config)
      .then(() => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((error) => {
        const errors = handleError(error)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
