import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'
import { Grid } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle, faClock, faPlusCircle, faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons'

const defaultState = {
  bookJobs: [],
}

const Slot = (props) => {
  const {
    slotPayload: {
      available,
      callbacks: {
        getHighlightingJob, getRenderJob,
      },
      duskTime,
      gridSlot,
      serviceGroup,
      slotLength,
      top,
    },
  } = props

  const {
    bookableSlots, callbacks: { toggleFilterSlots }, campaignServiceJobs,
  } = useContext(PageContext)

  const highlightingJob = getHighlightingJob(serviceGroup.id)
  const renderJob = getRenderJob(gridSlot, serviceGroup.id)

  const [state, setState] = useSetState(defaultState)
  const { bookJobs } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroupsV2, services } = entities

  useEffect(() => {
    setState({
      bookJobs: [...campaignServiceJobs],
    })
  }, [campaignServiceJobs, highlightingJob])

  const selected = ['selectedBook', 'selectedStandby']
  const booked = ['booked', 'selectedBook']
  const standby = ['standby', 'selectedStandby']

  const highlight = () => {
    const bookingDuration = parseInt(highlightingJob.details?.find(x => x.entityFieldType.name === 'BookingDuration').value, 10)
    const isDusk = Object.values(services).filter(x => x.id === highlightingJob.serviceId)[0].dusk

    if ([...booked, ...standby].includes(highlightingJob.bookingType)) return
    if (isDusk && gridSlot.c.hour < 17) return
    if (isDusk && !gridSlot.equals(duskTime)) return
    if (!isDusk && (gridSlot.plus({ minute: bookingDuration }) > gridSlot.set({ hour: 17, minute: 0 }))) return
    if (bookJobs.find(x => x.booking?.bookingStart === gridSlot.toISO({ suppressMilliseconds: true }) && x.serviceGroupId === highlightingJob.serviceGroupId)) return

    highlightingJob.bookingType = 'highlighted'
    highlightingJob.booking = {
      bookingStart: gridSlot.toISO(),
      bookingEnd: gridSlot.plus({ minute: bookingDuration || 0 }).toISO(),
      duration: bookingDuration || 0,
    }

    setState({ bookJobs })
  }

  const removeHighlighted = () => {
    const cleanJobs = bookJobs.map((bookJob) => {
      const job = bookJob
      if (job.bookingType === 'highlighted'){
        job.bookingType = 'pending'
        job.booking = null
      }
      return job
    })

    setState({ bookJobs: [...cleanJobs] })
  }

  function slotsOverlaps(dateStartA, dateEndA, dateStartB, dateEndB){
    if (dateStartA <= dateStartB && dateStartB <= dateEndA) return true
    if (dateStartA <= dateEndB && dateEndB <= dateEndA) return true
    if (dateStartB < dateStartA && dateEndA < dateEndB) return true
    return false
  }

  const select = (job) => {
    const selectedJob = job
    const nextJob = campaignServiceJobs.find(x => x.serviceGroupId === selectedJob.serviceGroupId
      && ((x.order.status === 10 && selectedJob.order.status === 10) || x.order.id === selectedJob.order.id)
      && x.bookingType === 'pending' && !x.highlighting)
    const duration = parseInt(selectedJob.details?.find(x => x.entityFieldType.name === 'BookingDuration').value, 10) || 0
    const bookingStart = gridSlot.toISO({ suppressMilliseconds: true })
    const bookingEnd = gridSlot.plus({ minute: duration }).toISO({ suppressMilliseconds: true })

    const alerts = []
    const duplicates = campaignServiceJobs.filter(x => x.booking
      && x.order.id !== selectedJob.order.id
      && selectedJob.order.status !== 10
      && x.order.status !== 10
      && x.serviceId === selectedJob.serviceId)
    const videoJobs = campaignServiceJobs.filter(x => x.bookingType !== 'pending' && x.serviceGroupId === 2)

    if (duplicates?.length){
      alerts.push({
        title: 'Duplicate Booking',
        html: `
          <p>You already have a booking for this service on a separate quote.</p>
          <p>Please cancel the duplicate if you wish to book this one.</p>
        `,
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Okay',
        cancelButtonColor: 'red',
        confirmButtonColor: '#e2001a',
        showClass: 'slide-from-top',
      })
    }

    if (!bookableSlots.some(x => x.serviceId === job.serviceId && x.start <= bookingStart && x.end >= bookingEnd)){
      const promptMessage = () => {
        switch (serviceGroupsV2[selectedJob.serviceGroupId]?.name){
          case 'Video Prestige':
            return `
              <p>Selecting this slot will send a booking request to our support team.
              Video prestige appointments are manually booked by our support team to ensure that all add ons and requirements are properly assigned.
              All efforts will be made to accommodate your preferred schedule, but not always guaranteed.</p><br/>
              <p>Please note that dusk shoots will be booked straight after the day video appointment.
              The team will reach out to you regarding availability.</p><br/>
              <p>Do you still wish to proceed?</p>
            `
          default:
            return 'This is a standby booking which is not guaranteed. Press yes to proceed or cancel to choose again to guarantee your preferred provider or time.'
        }
      }

      alerts.push({
        title: ['Video Prestige'].includes(serviceGroupsV2[selectedJob.serviceGroupId]?.name)
          ? `${serviceGroupsV2[selectedJob.serviceGroupId]?.name} Booking Request` : 'Standby Booking',
        html: selectedJob.selectedUsers ? 'The preferred provider is not available on the timeslot that you selected. Would you like to choose another provider instead?'
          : promptMessage(),
        icon: 'warning',
        showCancelButton: true,
        preConfirm: () => {
          if (selectedJob.selectedUsers) alerts.pop()
          return [
            'confirmedStandBy',
            (selectedJob.selectedUsers ? 'providerConflict' : 'noConflict'),
          ]
        },
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e2001a',
        showClass: 'slide-from-top',
      })
    }

    if (videoJobs.length){
      let conflictWithVideo
      if (selectedJob.serviceGroupId !== 2){
        conflictWithVideo = videoJobs.some(x => (x.booking ? slotsOverlaps(x.booking.bookingStart, x.booking.bookingEnd, bookingStart, bookingEnd) : false))
      } else {
        conflictWithVideo = campaignServiceJobs.some(x => (x.booking && x.serviceGroupId !== 2
          ? slotsOverlaps(x.booking.bookingStart, x.booking.bookingEnd, bookingStart, bookingEnd) : false))
      }

      if (conflictWithVideo){
        alerts.push({
          title: 'Video appointment detected at the same time as other services',
          html: `
            <p>We can see that you're trying to book a video appointment and another service at the same time. 
            We recommend staggering the bookings and assigning the video at a different time to ensure the videographer has uninterrupted access to the property.</p><br/>
            <p>Please click Cancel to change or OK to proceed.</p>
          `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Okay',
          cancelButtonText: 'Cancel',
          confirmButtonColor: '#e2001a',
          showClass: 'slide-from-top',
        })
      }
    }

    swal.queue(alerts).then(({ value }) => {
      if (value){
        if (selectedJob.selectedUsers && value.some(x => (x.length ? x.some(y => y === 'providerConflict') : false))){
          selectedJob.selectedUsers = null
        } else {
          selectedJob.booking = { bookingStart, bookingEnd, duration }
          selectedJob.bookingType = value.some(x => (x.length ? x.some(y => y === 'confirmedStandBy') : false)) ? 'selectedStandby' : 'selectedBook'

          if (nextJob){
            selectedJob.highlighting = false
            nextJob.highlighting = true
          }
        }

        setState({ bookJobs })
        toggleFilterSlots(bookableSlots)
      }
    })

    if (!alerts.length){
      selectedJob.booking = { bookingStart, bookingEnd, duration }
      selectedJob.bookingType = 'selectedBook'

      if (serviceGroupsV2[selectedJob.serviceGroupId]?.name === 'Drone'){
        swal.fire({
          title: `Thanks for booking your drone job with Content House.`,
          html: `Our team will be checking the flight permissions around this property. If there are any issues and we need to make changes to this
          flight, we will be in touch with you as soon as possible.`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Okay',
          confirmButtonColor: '#282B42',
          showClass: 'slide-from-top',
        })
      }

      if (nextJob){
        selectedJob.highlighting = false
        nextJob.highlighting = true
      }

      setState({ bookJobs })
      toggleFilterSlots(bookableSlots)
    }
  }

  const deselect = (job) => {
    if (selected.includes(job.bookingType)){
      const selectedJob = job

      selectedJob.booking = null
      selectedJob.bookingType = 'pending'

      setState({ bookJobs })
      toggleFilterSlots(bookableSlots)
    }
  }

  return (
    <Grid
      background={available ? 'none' : '#ECECEC'}
      onMouseEnter={highlightingJob ? () => highlight() : undefined}
      onMouseLeave={highlightingJob ? () => removeHighlighted() : undefined}
      position="relative"
      style={{
        boxSizing: 'border-box',
        boxShadow: `0 ${top ? '-' : ''}.4px 0 .5px #ECECEC`,
        ...gridSlot.equals(duskTime) && { borderTop: '1px orange dashed' },
      }}
    >
      {renderJob && (
        <Grid
          background={
            booked.includes(renderJob.bookingType) ? 'rgba(67, 172, 106, 0.3)'
              : standby.includes(renderJob.bookingType) && !['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) ? 'rgba(255, 102, 0, 0.3)'
              : standby.includes(renderJob.bookingType) && ['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) ? 'rgba(251, 109, 112, 0.3)'
              : 'rgba(2, 183, 226, 0.15)'
          }
          border={selected.includes(renderJob.bookingType) ? '2px dashed' : '2px solid'}
          borderColor={
            booked.includes(renderJob.bookingType) ? 'green'
              : standby.includes(renderJob.bookingType) && !['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) ? 'orange'
              : standby.includes(renderJob.bookingType) && ['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) ? 'rgba(251, 109, 112)'
              : 'blue'
          }
          borderRadius="5px"
          height={`${(((parseInt(renderJob.details?.find(x => x.entityFieldType.name === 'BookingDuration').value, 10) || 0) / 15) * (slotLength / 2)).toString()}px`}
          justifySelf="center"
          justifyContent="end"
          position="absolute"
          width="100%"
          zIndex={3}
        >
          <Grid
            alignItems="center"
            height={20}
            justifyContent="center"
            margin="auto"
            marginRight="medium"
            marginTop="medium"
            onClick={() => (renderJob.bookingType === 'highlighted' ? select(renderJob) : deselect(renderJob))}
            style={{ cursor: 'pointer' }}
            width={20}
          >
            {renderJob.bookingType === 'highlighted' && <FontAwesomeIcon icon={faPlusCircle} color="rgba(2,183,226)" />}
            {booked.includes(renderJob.bookingType) && <FontAwesomeIcon icon={faCheckCircle} color="rgba(67,172,106)" />}
            {standby.includes(renderJob.bookingType) && !['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) && (
              <FontAwesomeIcon icon={faClock} color="rgba(255, 102, 0)" />
            )}
            {standby.includes(renderJob.bookingType) && ['Drone', 'Video Prestige'].includes(serviceGroupsV2[renderJob.serviceGroupId]?.name) && (
              <FontAwesomeIcon icon={faQuestionCircle} color="rgba(251, 109, 112)" />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

Slot.propTypes = {
  slotPayload: PropTypes.shape({
    available: PropTypes.bool,
    callbacks: PropTypes.object,
    duskTime: PropTypes.object,
    gridSlot: PropTypes.object,
    serviceGroup: PropTypes.object,
    slotLength: PropTypes.number,
    top: PropTypes.bool,

  }).isRequired,
}

export default Slot
