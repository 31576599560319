import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import useUsers from '@hooks/useUsers'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Button, Box, FormField, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import custom from '@styles/custom.module.scss'

import { getFilteredUserRole } from '@functions/getFilteredUserRole'
import { groupBy } from '@functions/groupBy'
import { getEntityByName } from '@functions/getEntityByName'
import userTypes from '@functions/userTypes'

const defaultState = {
  filteredUsers: [],
  showFilter: false,
  userState: []
}

const StudioUserFilter = (props) => {
  const { setJobState, jobState } = props
  const { userFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { filteredUsers, showFilter, userState } = state

  const { users } = useUsers()

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  const entities = useSelector(reduxState => reduxState.entities)
  const { userRoles } = entities

  useEffect(() => {
    if (isClickedOutside && showFilter) {
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    const filteredUserRole = getFilteredUserRole(users, { user_type: userTypes.find(x => x.name === "Studio").id, contract_base: getEntityByName(userRoles, "ContractualEditor")?.id })

    setState({
      filteredUsers: filteredUserRole.map(user => ({
        ...user,
        isChecked: userFilter.includes(user.id)
      }))
    })
  }, [users, userFilter])

  useEffect(() => {
    setState({ userState: groupBy(filteredUsers, 'type') })
  }, [filteredUsers])

  const updateUserState = (checked, userId) => {
    if (checked)
      setJobState({ userFilter: [...userFilter, userId] })
    else {
      setJobState({ userFilter: userFilter.filter(i => i !== userId) })
    }
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? filteredUsers : userState[type]
    var checklist = []

    if (checked) {
      filterList.map(user => {
        checklist = [...checklist, ...userFilter, user.id]
      })
    }
    else {
      checklist = [...userFilter]
      filterList.map(user => {
        checklist = checklist.filter(i => i === user.id)
      })
    }

    setJobState({ userFilter: [...new Set(checklist)] })
  }

  if (Object.keys(userState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: "large" }}>
      <Box className={custom.root} ref={contentEl}>
        <Box flexDirection="column">
          <Button
            buttonStyle="secondary"
            size="medium"
            height="40px"
            onClick={() => setState({ showFilter: !showFilter })}
            className={custom.btnFilter}
          >
            Studio User
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        </Box>

        {showFilter &&
          <Box className={custom.popup}>
            <Box flexDirection="column">
              <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, 'All')} />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(userState).map((type) => (
                <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                  <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, type)} />
                  <Text fontSize="small" marginLeft="medium">Select All {type}</Text>
                </Box>
              ))}
              {Object.keys(userState).map((users) => {
                return (
                  <>
                    <Text fontSize="medium" fontWeight="medium" paddingBottom="medium" paddingTop="large">{users}</Text>
                    {userState[users].map((user) => {
                      return (
                        <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                          <input
                            className={custom.checkbox}
                            onChange={e => updateUserState(e.target.checked, user.id)}
                            type="checkbox"
                            checked={user.isChecked}
                          />
                          <Text fontSize="small" marginLeft="medium">{user.firstName + ' ' + user.lastName}</Text>
                        </Box>
                      )
                    })}
                  </>
                )
              })}
            </Box>
          </Box>
        }
      </Box>
    </FormField>
  );
}

export default StudioUserFilter