import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import {
  Box, Grid, Image, Link, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'
import { toggleArray } from '@campaignhub/javascript-utils'

import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Icons from '@components/Icons'

const defaultState = {
  currentlySelectedIds: [],
}

const GridView = (props) => {
  const {
    callbacks: { toggleUpdateSelectedIds },
    contents, multipleSelection, selectedIds, showDetails,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { currentlySelectedIds } = state

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobStatuses } = entities

  const selectContent = (content) => {
    setState({
      currentlySelectedIds: toggleArray((selectedIds.includes(content.id)) ? selectedIds
      : selectedIds.filter(id => id !== content.id), content.id),
    })
  }

  useEffect(() => {
    toggleUpdateSelectedIds(currentlySelectedIds)
  }, [currentlySelectedIds])

  return (
    <Grid
      gridAutoRows="1fr"
      gridGap="large"
      gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      maxHeight="572px"
      overflowY="auto"
    >
      {contents.map((content) => {
        const { file } = content
        const selected = selectedIds.includes(content.id)

        return (
          <Box
            border={showDetails ? '1px solid' : 0}
            borderColor="lineColor"
            borderRadius={5}
            flexDirection="column"
            key={content.id}
          >
            <Image
              boxProps={{ backgroundColor: 'hoverLightGrey' }}
              borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
              backgroundPosition="center center"
              height={140}
              url={content.previewUrl}
              width={'100%' || 200}
            >
              <Box
                height="100%"
                position="relative"
                style={{ cursor: multipleSelection ? 'pointer' : '' }}
                onClick={() => (multipleSelection ? selectContent(content) : null)}
              >
                {!content.previewUrl && (
                  <Box
                    alignItems="center"
                    color="bodyFontColor"
                    display="grid"
                    justifyContent="center"
                    width="100%"
                  >
                    <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={50} width="100%" />
                  </Box>
                )}
                {selected && (
                  <Box
                    alignItems="center"
                    backgroundColor="rgba(0, 0, 0, 0.4)"
                    borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
                    color="bodyFontColor"
                    display="grid"
                    justifyContent="center"
                    width="100%"
                  >
                    <Box
                      alignItems="center"
                      borderRadius={20}
                      color="white"
                      fontSize="xsmall"
                      justifyContent="center"
                      width="100%"
                    >
                      <FontAwesomeIcon icon={faCheckCircle} size="3x" />
                    </Box>
                  </Box>
                )}
              </Box>
            </Image>
            {showDetails && (
              <Box flexDirection="column" padding="large">
                <Link href={`#/service-jobs/${serviceJob.id}/assets?assetId=${content.id}`} disabledUnderline>
                  <ListItem
                    boxProps={{ borderBottom: '1px solid' }}
                    flexDirection="column"
                    showAngleIcon
                  >
                    <Text variant="ellipsis" fontSize="medium" fontWeight="600" width={180}>
                      {file.originalName}
                    </Text>
                    <Box flexDirection="row" marginY="large">
                      <Box alignItems="center" justifyContent="flexStart" width="auto" paddingRight="medium">
                        <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} />
                      </Box>
                      <StatusBadge color="orange" text={serviceJobStatuses[content.serviceJobStatusId]?.description} />
                    </Box>
                  </ListItem>
                  <ListItem
                    boxProps={{ paddingTop: 'large' }}
                    disableHover
                    flexDirection="column"
                  >
                    <Text fontSize="small">{content.description}</Text>
                  </ListItem>
                </Link>
              </Box>
            )}
            {!showDetails && (
              <Text fontSize="small" fontWeight={600} paddingTop="medium">
                {content.fileName}
              </Text>
            )}
          </Box>
        )
      })}
    </Grid>
  )
}

GridView.propTypes = {
  callbacks: PropTypes.object,
  contents: PropTypes.array,
  multipleSelection: PropTypes.bool,
  selectedIds: PropTypes.array,
  showDetails: PropTypes.bool,
}

export default GridView
