/* eslint-disable space-before-blocks */
import api from '@functions/api'

import { handleError } from '../utils'

const CREATE_REQUEST = 'contenthouse/serviceJobDetail/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/serviceJobDetail/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/serviceJobDetail/CREATE_FAILURE'

// Initial State
const initialState = {
  result: {},
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function assignServiceJobDetailV2(serviceJobDetail, options) {
  const config = {
    method: 'POST',
    data: serviceJobDetail,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v2/service-job-details', options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type } = action

  switch (type) {
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
