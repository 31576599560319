/* eslint-disable max-len */
import React from 'react'
import { toast } from 'react-toastify'

import PageContext from '@contexts/pageContext'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import { useParams } from 'react-router-dom'

import { Box, Columns, ModalContext } from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useServiceJob from '@hooks/useServiceJob'
import useUploadResource from '@hooks/useUploadResource'
import useUploadAsset from '@hooks/useUploadAsset'

import ChangeJobAssignmentModal from '@modals/ChangeJobAssignmentModal'
import UploadModal from '@modals/UploadModal'
import ActivityLogs from './components/ActivityLogs'
import AppointmentDetails from './components/AppointmentDetails'
import AssetsAndResources from './components/AssetsAndResources'
import AssignedUsers from './components/AssignedUsers'
import AvailableResources from './components/AvailableResources'
import JobDetails from './components/JobDetails'
import JobHeader from './components/JobHeader'
import ListingDetails from './components/ListingDetails'
import ProductDetails from './components/ProductDetails'
import StudioFeedback from './components/StudioFeedback'

const assignServiceJobStatus = (serviceJobParam, createFn, updateWorkflow) => {
  createFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }
    updateWorkflow(serviceJobParam.serviceJobStatusId)
  })
}

const assignServiceJobUser = (serviceJobUserParams, createFn, setState) => {
  createFn(serviceJobUserParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showChangeJobAssignmentModal: false })
  })
}

const updateServiceJobUser = (serviceJobUser, updateFn, setState) => {
  updateFn(serviceJobUser).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showChangeJobAssignmentModal: false })
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    ChangeJobAssignmentModal: {
      closeModal: () => setState({ showChangeJobAssignmentModal: false }),
      assignServiceJobUser: (serviceJobUserParams, createFn) => assignServiceJobUser(serviceJobUserParams, createFn, setState),
      updateServiceJobUser: (serviceJobUser, updateFn) => updateServiceJobUser(serviceJobUser, updateFn, setState),
    },
    ResourceUploadModal: {
      closeModal: () => setState({ showResourceUploadModal: false }),
    },
    AssetUploadModal: {
      closeModal: () => setState({ showAssetUploadModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showChangeJobAssignmentModal: false,
  showResourceUploadModal: false,
  showAssetUploadModal: false,
}

const ServiceJob = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    showChangeJobAssignmentModal,
    showResourceUploadModal,
    workflowUpdated,
    showAssetUploadModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const { id: serviceJobId } = useParams()

  useReduxAction('serviceJobs', 'getServiceJob', new URLSearchParams([
    ['includes', 'service'],
    ['includes', 'campaign'],
    ['includes', 'client'],
    ['includes', 'details'],
    ['includes', 'histories'],
    ['includes', 'users'],
  ]), [serviceJobId], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && !loadedIds.includes(serviceJobId)
    },
  })

  const { serviceJob } = useServiceJob({ id: serviceJobId })
  const modalRequestOptions = { serviceJobId: serviceJob.id }

  const updateWorkflow = (statusId) => {
    serviceJob.serviceJobStatusId = statusId
    setState({ workflowUpdated: !workflowUpdated })
  }

  const useUploadResourcePayload = useUploadResource()
  const {
    callbacks: {
      deleteResource: deleteFnResource,
      loadResource: loadFnResource,
    },
  } = useUploadResourcePayload

  const useUploadAssetPayload = useUploadAsset()
  const {
    callbacks: {
      deleteAsset: deleteFnAsset,
      loadAsset: loadFnAsset,
    },
  } = useUploadAssetPayload

  const pageContext = {
    callbacks: {
      showChangeJobAssignmentModal: (payload) => {
        setModalData('ChangeJobAssignmentModal', payload)
        setState({ showChangeJobAssignmentModal: true })
      },
      showResourceUploadModal: (payload) => {
        setModalData('showResourceUploadModal', payload)
        setState({ showResourceUploadModal: true })
      },
      showAssetUploadModal: (payload) => {
        setModalData('showAssetUploadModal', payload)
        setState({ showAssetUploadModal: true })
      },
      assignServiceJobStatus: (serviceJobUserParams, createFn) => {
        assignServiceJobStatus(serviceJobUserParams, createFn, updateWorkflow)
      },
    },
    serviceJob,
    workflowUpdated,
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <JobHeader />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <AssetsAndResources />
              <AvailableResources />
              <ListingDetails />
              <StudioFeedback />
              <ProductDetails />
              <ActivityLogs />
            </Columns.Main>
            <Columns.Sidebar>
              <JobDetails />
              <AssignedUsers />
              <AppointmentDetails />
            </Columns.Sidebar>
          </Columns>
        </Box>
        <ChangeJobAssignmentModal
          callbacks={callbacks('ChangeJobAssignmentModal', props, setState)}
          showModal={showChangeJobAssignmentModal}
        />
        <UploadModal
          callbacks={{ ...callbacks('ResourceUploadModal', props, setState), deleteFn: deleteFnResource, loadFn: loadFnResource }}
          showModal={showResourceUploadModal}
          title="Upload"
          titleSecondLine="Resources"
          serviceJobOptions={modalRequestOptions}
          type="Resources"
          displayAllowedFileTypes
        />
        <UploadModal
          callbacks={{ ...callbacks('AssetUploadModal', props, setState), deleteFn: deleteFnAsset, loadFn: loadFnAsset }}
          showModal={showAssetUploadModal}
          title="Upload"
          titleSecondLine="Assets"
          serviceJobOptions={modalRequestOptions}
          type="Assets"
          displayAllowedFileTypes
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ServiceJob
