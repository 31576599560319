import React from 'react'

import {
  Box,
  DashboardModule,
  ListItem,
  Text
} from '@campaignhub/suit-theme'

import IconProfile from '@components/IconProfile'

const AppointmentDetails = () => {
  return (
    <DashboardModule
      title="Appointment Details"
    >
      <Box flexDirection="column" lineHeight="1.3">
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large",
            alignItems: "center"
          }}
          flexDirection="row"
          style={{ cursor: "pointer" }}
        >
          <Box
            variant="white"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding="medium"
            width="auto"
          >
            <Text fontSize="large" fontWeight="bold">
              14
            </Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">WED</Text>
          </Box>
          <Box
            flexDirection="column"
            paddingLeft="large"
          >
            <Text fontSize="small">
              Wednesday, 14 October
            </Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              1:00 - 2:30 PM
            </Text>
          </Box>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: "1px solid",
            padding: "large"
          }}
          flexDirection="column"
          style={{ cursor: "pointer" }}
        >
          <Text fontSize="xsmall" marginBottom="large">
            Service User
          </Text>
          <IconProfile
            imageUrl="https://images.unsplash.com/photo-1583511655857-d19b40a7a54e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1349&q=80"
            name="Mike Tyson"
            contactNo="0412 737 5082"
            email="miketyson@contenthouse.com" />
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

export default AppointmentDetails