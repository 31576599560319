import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useReduxAction from '@hooks/useReduxAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import useTimer from '@hooks/useTimer'

import {
  Box,
  Button,
  Link,
  Text,
} from '@campaignhub/suit-theme'
import {
 ClockIn, ClockOut, StartBreak, EndBreak,
} from './api'

import styles from './styles.module.scss'

const defaultState = {
  showTimeTracker: false,
  isUserClockedIn: false,
  isUserInBreak: false,
  isLoading: false,
}

const TimeTracker = () => {
  const { timetrackers } = useSelector(reduxState => reduxState.entities)
  const [state, setState] = useSetState(defaultState)
  const {
 showTimeTracker, isUserClockedIn, isUserInBreak, isLoading,
} = state
  const {
 timer, setTimer, handleStart, handlePause, handleStop, handleResume,
} = useTimer()
  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showTimeTracker })

  const options = new URLSearchParams([
    ['FilterStart', '0d'],
    ['FilterEnd', '1d'],
    ['Includes', 'totalBreakTime'],
  ])

  useReduxAction('timetrackers', 'getUserTime', options, [timetrackers])

  const userClockInAndOut = () => {
    setState({ isLoading: true })
    if (!isUserClockedIn){
      ClockIn().then(() => setState({ isLoading: false, isUserClockedIn: !isUserClockedIn }))
      handleStart()
    } else if (!isUserInBreak && isUserClockedIn){
      ClockOut().then(() => setState({ isLoading: false, isUserClockedIn: !isUserClockedIn }))
      handleStop()
    }
  }

  const userBreak = () => {
    setState({ isLoading: true })
    if (isUserInBreak){
      EndBreak().then(() => setState({ isLoading: false }))
      handleResume()
    } else {
      StartBreak().then(() => setState({ isLoading: false }))
      handlePause()
    }
    setState({ isUserInBreak: !isUserInBreak })
  }

  const formatTime = () => {
    const seconds = `0${timer % 60}`.slice(-2)
    const minutes = `0${Math.floor(timer / 60) % 60}`.slice(-2)
    const hours = `0${Math.floor(timer / 3600)}`.slice(-2)
    return `${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    if (showTimeTracker && isClickedOutside){
      setState({ showTimeTracker: !showTimeTracker })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    if (timetrackers){
      const currentShift = Object.values(timetrackers).pop()
      const currentDate = new Date()
      const clockStart = new Date(currentShift.clockStart)
      const difTimeInSec = Math.floor((currentDate - clockStart) / 1000)
      const totalBreakTime = (currentShift.totalBreakTime ?? 0) * 60
      setState({
        isUserClockedIn: (currentShift.clockEnd === null),
      })
      setTimer(difTimeInSec - totalBreakTime)
      currentShift.clockEnd === null ? handleStart() : setTimer(0)
    }
  }, [timetrackers])

  return (
    <Box className={styles.root} onClick={() => setState({ showTimeTracker: !showTimeTracker })} ref={contentEl}>
      <Box flexDirection="row" alignItems="center">
        <Text>{formatTime()}</Text>
        <Box
          height={30}
          width={30}
          backgroundColor={!isUserClockedIn ? 'green' : 'red'}
          borderRadius="1rem"
          justifyContent="center"
          alignItems="center"
          marginLeft="medium"
        >
          <FontAwesomeIcon icon={!isUserClockedIn ? faPlay : faStop} onClick={userClockInAndOut} disabled={isLoading} color="white" size="sm" />
        </Box>
      </Box>
      {showTimeTracker && (
        <>
          <Box className={styles.menu}>
            {(!isUserClockedIn)
              ? (
                <Box
                  flexDirection="column"
                  borderBottom="1px solid"
                  borderColor="lineColor"
                  alignItems="center"
                  padding="xlarge"
                >
                  <Text fontSize="medium" fontWeight="bold" marginBottom="medium">Welcome back Tom!</Text>
                  <Text fontSize="small" color="bodyFontLightColor" marginBottom="large">Don't forget to track your time.</Text>
                  <Button size="medium" buttonStyle="primaryCreate" onClick={userClockInAndOut} disabled={isLoading}>
                    CLOCK IN
                  </Button>
                </Box>
)
              : (
                <Box
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  borderBottom="1px solid"
                  borderColor="lineColor"
                  padding="xlarge"
                >
                  <Text fontSize="xlarge" fontWeight="bold" marginBottom="medium">
                    {formatTime()}
                  </Text>
                  <Box flexDirection="row">
                    <Button size="small" buttonStyle="primaryDestroy" style={{ marginRight: 4, width: 'calc(50% - 4px)' }} onClick={userClockInAndOut} disabled={isLoading}>
                      CLOCK OUT
                    </Button>
                    <Button size="small" buttonStyle="primaryEdit" marginLeft="medium" style={{ marginLeft: 4, width: 'calc(50% - 4px)' }} onClick={userBreak} disabled={isLoading}>
                      {isUserInBreak ? 'END BREAK' : 'START BREAK'}
                    </Button>
                  </Box>
                </Box>
)}
            <Box flexDirection="column" padding="large" alignItems="center">
              <Link onClick={function noRefCheck(){ }}>
                View History
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default TimeTracker
