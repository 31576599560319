import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'

import { getAccessToken } from '@functions/accessToken'

import Login from '@sections/Auth/Login'
import Share from '@sections/Share'
import AuthorizationController from '../AuthorizationController'
// When logging in the token is not instantly available
// We wait and check 0.1 second later for it to be available

const waitForAccessToken = (setAccessToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token){
      clearInterval(intervalId)
      setAccessToken(token)
    }
  }, 100)
}

const AuthenticationController = (props) => {
  const { shareLinkToken } = props

  const [accessToken, setAccessToken] = useState(null)

  const auth0Payload = useAuth0()
  const { isAuthenticated } = auth0Payload

  useEffect(() => {
    if (!shareLinkToken){
      waitForAccessToken(setAccessToken)
    }
  }, [shareLinkToken])

  if (shareLinkToken){
    return <Share token={shareLinkToken} />
  }

  if (!accessToken || !isAuthenticated){
    return <Login />
  }

  return <AuthorizationController />
}

AuthenticationController.propTypes = {
  shareLinkToken: PropTypes.string,
}

export default AuthenticationController
