import useReduxAction from '@hooks/useReduxAction'
import useClientTypes from '@hooks/useClientTypes'
import useServiceGroups from '@hooks/useServiceGroups'
import useServiceGroupsV2 from '@hooks/useServiceGroupsV2'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'

const Client = (props) => {
  const { children } = props

  useReduxAction('assetCommentTypes', 'loadAssetCommentTypes', {})
  useClientTypes({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })
  useServiceGroupsV2({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })

  return children
}

export default Client
