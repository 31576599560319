/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { DateTime } from 'luxon'

export function getDeadline(dueDate, timeZone){
  const x = DateTime.fromISO(dueDate, { zone: timeZone }).diff(DateTime.now().setZone(timeZone), ['days', 'hours', 'minutes']).toObject()
    if (x.days < 0 || x.hours < 0){
      return 'Overdue'
    }

    const xDays = x.days > 0 ? `${x.days} day${ x.days > 1 ? 's ' : ' '}` : ''
    const xHours = x.hours > 0 ? `${x.hours} hour${ x.hours > 1 ? 's ' : ' '}` : ''
    const xMinutes = x.minutes > 0 ? `${Math.round(x.minutes)} minute${ x.minutes > 1 ? 's ' : ' '}` : ''

    return `Due in ${xDays + xHours + xMinutes}`
}
