import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import { getEntityByName } from '@functions/getEntityByName'

import Gallery from './components/Gallery'

const defaultState = {
  contents: [],
  multipleSelection: false,
  selectAll: false,
}

const Assets = (props) => {
  const { activeTabBarItemKey, callbacks: { toggleUpdateSelectedIds }, selectedIds } = props

  const [state, setState] = useSetState(defaultState)
  const {
    contents, multipleSelection, selectAll,
  } = state

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, serviceJobStatuses } = entities

  const getContents = () => {
    const proofStatuses = [
      getEntityByName(serviceJobStatuses, 'ContentSentToClient')?.id,
      getEntityByName(serviceJobStatuses, 'ContentApproved')?.id,
    ]

    switch (activeTabBarItemKey){
      case 'assets':
        return Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id)
      case 'proofs':
        return Object.values(assets).filter(asset => (
          asset.serviceJobId === serviceJob.id && proofStatuses.includes(asset.serviceJobStatusId)
        ))
      default:
        return []
    }
  }

  useEffect(() => {
    setState({
      contents: getContents(),
      multipleSelection: false,
      selectAll: false,
    })
  }, [activeTabBarItemKey, assets])

  const selectMultiple = () => {
    setState({ multipleSelection: !multipleSelection })
  }

  const selectAllContent = () => {
    setState({ selectAll: !selectAll })
  }

  useEffect(() => {
    toggleUpdateSelectedIds(selectAll ? Object.values(assets).map(content => content.id) : [])
  }, [selectAll])

  useEffect(() => {
    setState({ selectAll: !multipleSelection ? false : selectAll })
    toggleUpdateSelectedIds(!multipleSelection ? [] : selectedIds)
  }, [multipleSelection])

  return (
    <Gallery
      callbacks={{
        toggleMultipleSelection: () => selectMultiple(),
        toggleSelectAllContent: () => selectAllContent(),
        toggleUpdateSelectedIds,
      }}
      contents={contents}
      multipleSelection={multipleSelection}
      selectAll={selectAll}
      selectedIds={selectedIds}
      showControls={activeTabBarItemKey === 'assets'}
      showDetails
      type={activeTabBarItemKey}
    />
  )
}

Assets.propTypes = {
  activeTabBarItemKey: PropTypes.string,
  callbacks: PropTypes.object,
  selectedIds: PropTypes.array,
}

export default Assets
